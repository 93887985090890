const initialState = { 
    arr : 0
}
  
  const notificationRef = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_NOTIFICATION_REFRESH':
        return { ...state, arr: action.data };

      default:
        return state;
    }
  }

  export default notificationRef;

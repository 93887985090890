import React from "react";
import { Modal, Button } from "react-bootstrap";
import "./DeleteConfirmation.css";
import DeleteIcon from "../../../images/delete-red.svg";
import HideIcon from "../../../images/Hide.svg";

const DeleteConfirmation = ({
  showModal,
  hideModal,
  confirmModal,
  id,
  type,
  message,
  reject
}) => {
 
  return (
    <Modal
      show={showModal}
      onHide={hideModal}
      className={`delete_modal_main`}
      centered
    >
      <Modal.Body>
        <div className="text-center">
          {type === 'scoreDelete' && (
            <img src={HideIcon} className="delete_pp_icon" alt="icon" />
          )}
          {type !== 'scoreDelete' && (
            <img src={DeleteIcon} className="delete_pp_icon" alt="icon" />
          )}
          {type === 'scoreDelete' && (

            <h4 className="center_title">
              {message}?
            </h4>
          )}
          {type !== 'scoreDelete' && (
            <h4 className="center_title">
              {reject ? "Are you sure you want to block" : "Are you sure you want to delete"}
              <strong style={{ marginLeft: '5px' }}>{message}</strong>?
            </h4>
          )}
        </div>
      </Modal.Body>
      <Modal.Footer className="text-center">
        <Button variant="default" className="btn_outline" onClick={hideModal}>
          Cancel
        </Button>
        <Button
          variant="danger"
          className="btn_danger"
          onClick={() => confirmModal(type, id)}
        >
          {reject ? "Yes" : <>
            {type === 'scoreDelete' ? "Confirm" : "Delete"}

          </>}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};

export default DeleteConfirmation;

import { useEffect,useState } from 'react';
import { useParams } from "react-router-dom";
import { useSearchParams } from "react-router-dom";
import { yearDate } from "../../../utilities/DateFormate";
import Checkbox from "../../custom/checkbox/Checkbox";
import { useSelector, useDispatch } from "react-redux";
import { UserDateFormat } from "../../../utilities/DateFormate";
import useAxios from "../../../axiosinstance";


export default function SmallProfilescheduler({ PostOpNoteshow, personalData, fullname, tAge, akpt, rowId, akid, ak_pt, akpT,showModal}) {

    const patientDetails = useSelector((state) => state.mainReducer.patientDetails)
    const login = useSelector((state) => state.mainReducer.loginData.arr);
    const [searchParams] = useSearchParams();
    const { ptId } = useParams();
    let resId = searchParams.get("rId");
    const axiosInstance = useAxios();

    localStorage.setItem("patient_name", fullname)
    localStorage.setItem("patient_age", tAge)

    const [name, setName] = useState("")
    const [userage, setAge] = useState(Number())
    const[ak_id,setak_id]=useState("")

    const GetNoteDetails2 = () => {
        axiosInstance.current
            .get(
                `extapp/doctors/getPatientProfile?ak_id=${patientDetails.arr.ak_id?patientDetails.arr.ak_id:ptId}&ak_dc=${login.ing_ak_id}`
            )
            .then((response) => {
                setName(response.data.data.firstname + (" ") + response.data.data.lastname);
                setAge(Number(yearDate(response.data.data.dob)));
                setak_id(response.data.data.ak_id)
                sessionStorage.setItem("ak_pt_id",response.data.data.ak_id)
                // console.log(response.data.op_notes[0].org_id,"checkingof");

            })
            .catch((error) => {
                if (error.response.status === 401) {
                }
            });
    };

    useEffect(() => {
        if (showModal) {
            GetNoteDetails2()
        }
    }, [showModal])

    return (
        <div className="">
            <div className="p-2" style={{ backgroundColor: 'rgb(243, 245, 255)', borderRadius: 10 }}>
                <div className='d-flex justify-content-between mb-2'>
                    <h6 className='' style={{ height: '23px' }}>
                        <label>{name}</label>
                    </h6>
                    <div className="float-end ps-5" style={{ width: '80px' }}>
                        <Checkbox
                            selected={"CarpalDiagnosis1"}
                            onChange={"handleCarpalDiagnosis1"}
                            styleClass="custom_check_box tick f_400 large top_18"
                            id="CarpalDiagnosis1"
                        />
                    </div>
                </div>
                <div className='d-flex justify-content-between'>
                    <label className="">{userage} Years</label>
                    <label>{ak_id}</label>
                </div>
            </div>
        </div>
    )
}

const initialState = {
  painScore: [
    {
      painTodayRatingL: "0",
      painTodayRatingR: "0",
      howBadPainRatingL: "0",
      howBadPainRatingR: "0",
      oftenL: "0",
      oftenR: "0",
      interferedL: "0",
      interferedR: "0",
      straighteningL: "0",
      straighteningR: "0",
      bendingL: "0",
      bendingR: "0",
      surfaceL: "0",
      surfaceR: "0",
      walkL: "4",
      walkR: "4",
      hardSurfaceL: "0",
      hardSurfaceR: "0",
      unevenSurfaceL: "0",
      unevenSurfaceR: "0",
      stairsL: "0",
      stairsR: "0",
      bedL: "0",
      bedR: "0",
      troubledL: "0",
      troubledR: "0",
      sittingL: "0",
      sittingR: "0",
      uprightL: "0",
      uprightR: "0",
      chairL: "0",
      chairR: "0",
      activityL: "0",
      activityR: "0",
      describesR: "0",
      describesL: "0"
    },
  ],
  symptomsScore: [
    {
      grindingL: "0",
      grindingR: "0",
      catchingL: "0",
      catchingR: "0",
      stifL: "0",
      stifR: "0",
      stifSitL: "0",
      stifSitR: "0",
      limpingL: "0",
      limpingR: "0",
      suddenL: "0",
      suddenR: "0",
      hipGivingL: "0",
      hipGivingR: "0",
      decreasedL: "0",
      decreasedR: "0",
      supportL: "0",
      supportR: "0",
    },
  ],
  functionScore: [
    {
      desstairsR: "0",
      desstairsL: "0",
      ascstairsR: "0",
      ascstairsL: "0",
      sittingR: "0",
      sittingL: "0",
      risingSittingR: "0",
      risingSittingL: "0",
      floorR: "0",
      floorL: "0",
      standingR: "0",
      standingL: "0",
      standingMinR: "0",
      standingMinL: "0",
      spreadingR: "0",
      spreadingL: "0",
      strideR: "0",
      strideL: "0",
      funBendlinR: "0",
      funBendlinL: "0",
      walkingR: "0",
      walkingL: "0",
      walkUnevenR: "0",
      walkUnevenL: "0",
      walkLongR: "0",
      walkLongL: "0",
      walkExeR: "0",
      walkExeL: "0",
      walkSteepR: "0",
      walkSteepL: "0",
      walkHillsR: "0",
      walkHillsL: "0",
      walkInitR: "0",
      walkInitL: "0",
      walkAppTenR: "0",
      walkAppTenL: "0",
      walkFiftR: "0",
      walkFiftL: "0",
      steppingR: "0",
      steppingL: "0",
      publicTransR: "0",
      publicTransL: "0",
      goingShopR: "0",
      goingShopL: "0",
      puttingR: "0",
      puttingL: "0",
      takingR: "0",
      takingL: "0",
      lyingR: "0",
      lyingL: "0",
      rollingR: "0",
      rollingL: "0",
      risingR: "0",
      risingL: "0",
      getbathR: "0",
      getbathL: "0",
      gettoiletR: "0",
      gettoiletL: "0",
      washingR: "0",
      washingL: "0",
      lightR: "0",
      lightL: "0",
      heavyR: "0",
      heavyL: "0",
      lightDomesticR: "0",
      lightDomesticL: "0",
      pickingR: "0",
      pickingL: "0",
      squattingR: "0",
      squattingL: "0",
      joggingR: "0",
      joggingL: "0",
      runningR: "0",
      runningL: "0",
      runningOneR: "0",
      runningOneL: "0",
      twistingR: "0",
      twistingL: "0",
      jumpingR: "0",
      jumpingL: "0",
      landingR: "0",
      landingL: "0",
      swingingR: "0",
      swingingL: "0",
      startingR: "0",
      startingL: "0",
      cuttingR: "0",
      cuttingL: "0",
      sexualR: "0",
      sexualL: "0",
      sportsR: "0",
      sportsL: "0",
      lowR: "0",
      lowL: "0",
      recreationalR: "0",
      recreationalL: "0",
      fastWalkR: "0",
      fastWalkL: "0",
      abilityPerformR: "0",
      abilityPerformL: "0",
      abilityPartR: "0",
      abilityPartL: "0",
      hipPer: "100",
      hipPerL: "100",
      specifyHighestActivityR: "0",
      specifyHighestActivityL: "0",
      select1: false,
      select2: false,
      highestActivity: "",
      highestActivityL: "",
      sports: "",
      currsports: ""

    },
  ],
  qolScore: [
    {
      aware: "0",
      awareL: "0",
      modified: "0",
      modifiedL: "0",
      concerned: "0",
      concernedL: "0",
      troubled: "0",
      troubledL: "0",
      distraction: "0",
      distractionL: "0",
      difficulty: "0",
      difficultyL: "0",
      depressed: "0",
      depressedL: "0",
      health: "100",
      healthL: "100",
    },
  ],
  satisfactionScore: [
    {
      overallL: "",
      overallR: "",
      improvePainL: "",
      improvePainR: "",
      improveHomeL: "",
      improveHomeR: "",
      improveRecrL: "",
      improveRecrR: "",
      applicableR: false,
      applicableL: false,
      applicable1R: false,
      applicable1L: false,
      applicable2R: false,
      applicable2L: false,
      applicable3R: false,
      applicable3L: false


    },
  ],
  TotalScores: [
    {
      mhhsTR: 0,
      mhhsTL: 0,
      hoosQORT: 0,
      hoosQOLT: 0,
      hossSportTR: 0,
      hossSportTL: 0,
      hoosADLTR: 0,
      hoosADLTL: 0,
      hoosPainTR: 0,
      hoosPainTL: 0,
      hoosSymptomsTR: 0,
      hoosSymptomsTL: 0,
      ohsFuncTR: 0,
      ohsFuncTL: 0,
      ohsPainTR: 0,
      ohsPainTL: 0,
      womacPainTR: 0,
      womacPainTL: 0,
      womacstiffTR: 0,
      womacstiffTL: 0,
      womacFunctionTR: 0,
      womacFunctionTL: 0,
      nahsTR: 0,
      nahsTL: 0,
      hosadlTR: 0,
      hosadlTL: 0,
      hossportTR: 0,
      hossportTL: 0,
      ihotR: 0,
      ihotL: 0,
      vas: 0,
      vasR: 0,
      sane: 0,
      eq4deq5dwalkingR: 0,
      eqdSelfR: 0,
      eqdSelfL: 0,
      eqdUsual: 0,
      eqdUsualL: 0,
      eqdPain: 0,
      eqdPainR: 0,
      eqdAnxi: 0,
      eqdAnxiL: 0,
      eqVas: 0,
      eqVasL: 0,
      SapsR: 0,
      SapsL: 0,
      overallohsR: 0,
      overallohsL: 0,
      SapsOverL: 0,
      SapsOverR: 0,
      sapsPainR: 0,
      sapsPainL: 0,
      sapsWorkR: 0,
      sapsWorkL: 0,
      sapsRecreR: 0,
      sapsRecreL: 0,
      TignerRight: 0,
      TignerLeft: 0
    },
  ],
  doctorFeedback: [
    {
      ir1Right: "0",
      ir1Left: "0",
      ir2Right: "0",
      ir2Left: "0",
      ir3Right: "0",
      ir3Left: "0",
      ir4Right: "",
      ir4Left: "",
      bearHugRightVal: "",
      bearHugLeftVal: "",
      RightFlexionR: null,
      RightFlexionL: null,
      LeftFlexionR: null,
      LeftFlexionL: null,
      RightExtensionR: null,
      RightExtensionL: null,
      LeftExtensionR: null,
      LeftExtensionL: null,
      RightAbductionR: null,
      RightAbductionL: null,
      LeftAbductionR: null,
      LeftAbductionL: null,
      RightAdductionR: null,
      RightAdductionL: null,
      LeftAdductionR: null,
      LeftAdductionL: null,
      RightERR: null,
      RightERL: null,
      LeftERR: null,
      LeftERL: null,
      RightERExL: null,
      RightERExR: null,
      LeftERExL: null,
      LeftERExR: null,
      RightIRFlexL: null,
      RightIRFlexR: null,
      LeftIRFlexL: null,
      LeftIRFlexR: null,
      RightIRExL: null,
      RightIRExR: null,
      LeftIRExR: null,
      LeftIRExL: null,
      otherR: "",
      otherL: "",
      subscapularisPowerR: null,
      subscapularisPowerL: null,
      limbAR: "",
      limbTL: "",
      limbTRL: "",
      limbRAR: "",
      rightpspecify: "",
      leftpspecify: "",
      rightaspecify: "",
      leftaspecify: "",
      greaterRightVal: "",
      greaterLeftVal: "",
      inguinalRightVal: "",
      inguinalLeftVal: "",
      LogRightVal: "",
      LogLeftVal: "",
      GaleazziRightVal: "",
      GaleazziLeftVal: "",
      TrendelenburgRightVal: "",
      TrendelenburgLeftVal: "",
      ThomasRightVal: "",
      ThomasLeftVal: "",
      FADIRRightVal: "",
      FADIRLeftVal: "",
      PatrickRightVal: "",
      PatrickLeftVal: "",
      OberRightVal: "",
      OberLeftVal: "",
      StraightRightVal: "",
      StraightLeftVal: "",
      BarlowRightVal: "",
      BarlowLeftVal: "",
      OrtolaniRightVal: "",
      OrtolaniLeftVal: "",
      AsymmetricRightVal: "",
      AsymmetricLeftVal: "",
      others_r: "",
      others_l: "",
      others2_r: "",
      others2_l: "",
      asymmetricL: "1",
      asymmetricR: "1",
      asymmetricOtherR: "",
      asymmetricOtherL: "",
    }
  ],
  evlHipPain: "1",
  dominant: 0
};

const HipScoreReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_HIP_DATA":
      return { ...state, evlHipPain: action.data };

    // case "UPDATE_HIP_DATA_DOMINANT":
    //   return { ...state, dominant: action.data };

    case 'UPDATE_HIP_PAIN_FIELD_DATA':
      const painFieldVal = action.data.value;
      const painFieldKey = action.data.key;
      const painNewArr = state.painScore.map((object) => {
        return { ...object, [painFieldKey]: painFieldVal };
      });
      return { ...state, painScore: painNewArr };

    case 'UPDATE_HIP_PAIN_DATA':
      return { ...state, painScore: action.data };

    case 'UPDATE_HIP_SYMPTOMS_FIELD_DATA':
      const fieldVal = action.data.value;
      const fieldKey = action.data.key;
      const newArr = state.symptomsScore.map((object) => {
        return { ...object, [fieldKey]: fieldVal };
      });
      return { ...state, symptomsScore: newArr };

    case 'UPDATE_HIP_SYMPTOMS_DATA':
      return { ...state, symptomsScore: action.data };

    case 'UPDATE_HIP_FUNCTION_FIELD_DATA':
      const fieldValF = action.data.value;
      const fieldKeyF = action.data.key;
      const newArrF = state.functionScore.map((object) => {
        return { ...object, [fieldKeyF]: fieldValF };
      });
      return { ...state, functionScore: newArrF };

    case 'UPDATE_HIP_FUNCTION_DATA':
      return { ...state, functionScore: action.data };

    case 'UPDATE_HIP_QOL_FIELD_DATA':
      const qolVal = action.data.value;
      const qolKey = action.data.key;
      const qolNewArr = state.qolScore.map((object) => {
        return { ...object, [qolKey]: qolVal };
      });
      return { ...state, qolScore: qolNewArr };

    case 'UPDATE_HIP_QOL_DATA':
      return { ...state, qolScore: action.data };

    case 'UPDATE_HIP_SATISFACTION_FIELD_DATA':
      const satVal = action.data.value;
      const satKey = action.data.key;
      const satNewArr = state.satisfactionScore.map((object) => {
        return { ...object, [satKey]: satVal };
      });
      return { ...state, satisfactionScore: satNewArr };

    case 'UPDATE_HIP_SATISFACTION_DATA':
      return { ...state, satisfactionScore: action.data };

    case 'UPDATE_HIP_FEEDBACK_FIELD_DATA':
      const satf = action.data.value;
      const satK = action.data.key;
      const satNewarr = state.doctorFeedback.map((object) => {
        return { ...object, [satK]: satf };
      });
      return { ...state, doctorFeedback: satNewarr };

    case 'UPDATE_HIP_FEEDBACK_DATA':
      return { ...state, doctorFeedback: action.data };



    case 'UPDATE_HIP_TOTAL_SCORE':
      return { ...state, TotalScores: action.data };


    default:
      return state;

  }
};

export default HipScoreReducer;

import React, {  useEffect } from "react";
import { useKeycloak } from "@react-keycloak/web";


const Welcome = () => {
  const { keycloak } = useKeycloak();


  useEffect(() => {
    const redirectToLogin = () => {
      keycloak.login()
    }
    redirectToLogin ();
  }, []);

  return(
    <>
      <div className="jumbotron">
      {/* <p className="lead">Please authenticate yourself!</p>
      <p>
        <button className="btn btn-lg btn-warning"  onClick={() => keycloak.login()}>Login</button>
      </p> */}
    </div>
    </>
  );
}


export default Welcome
const initialState = {
  History: [
    {
      drop1: [],
      complaint: "",
      startDate: new Date(),
      Investigation: "",
    },
  ],
  Social: [
    {
      live: "",
      lives: "",
      smoke: "",
      smokes1: "",
      smokes: "",
      smokePast: "",
      smokePastText: "",
      alcohol: "",
      alcoholText: "",
      drugs: "",
      drop1:"",
    },
  ],
  Past: [
    {
      knowAllergie: "",
      reaction: "",
      allergicLatex: "",
      bloodPressure: "",
      heartIllness: "",
      dropIllness: [],
      dropProblem: "0",
      cardiologist: "",
      vascularDisease: "",
      drop9: "",
      drop10: [],
      checkAsth: "", // new
      checkCOPD: "", //
      checkHigh: "", //
      checkCystic: "", //
      checkPulmo: "", //
      checkTB: "", //
      checkSarco: "", // new
      checkOther: "", // new
      drop11: "",
      drop12: "",
      drop13: "",
      drop14: "",
      drop15: "",
      drop16: "",
      drop17: "",
      drop18: "",
      drop19: "",
      drop20: "",
      drop21: "",
      drop22: "",
      drop23: "",
      drop24: "",
      drop24Ch: "",
      dropArt: "",
      droplung: [],
      drop25: "",
      bleed: "",
      drop26: "",
      drop27: "",
      drop28: "",
      drop29: "",
      drop30: "",
      drop31: "",
      startDate: new Date(),
      drop33: "",
      drop34: "0",
      drop35: "",
      drop36: "",
      drop37: "",
      drop38: "",
      drop39: "",
      drop41: "",
      drop42: "",
      drop43: "",
      drop44: "",
      drop45: "",
      drop99: "",
      drop87: "",
      drop75: "",
      drop50: "",
      drop501: "",
      drop502:"",
      drop503: "",
      drop510: "",
      drop511: "",
      drop512: "",
      checkvalue50:"",
      checkvalue82: "",
      checkvalue81: "",
      checkvalue80: "",
      checkvalue: "",
      checkvalue1: "",
      checkvalue3: "",
      checkvalue4: "",
      checkvalue5: "",
      checkvalue6: "",
      checkvalue7: "",
      checkvalue8: "",
      checkvalue9: "",
      neurologicinput: "",
      muscularinput: "",
      checkvalue10: "",
      otherinput: "",
      arthritisdrop75: "",
      Osteoarthritischeck: "",
      Rheumatoidcheck: "",
      Othercheck: "",
      psychiatric75: "",
      psychiatriccheck1: "",
      psychiatriccheck2: "",
      psychiatriccheck3: "",
      bleeding75: "",
      bleedingcheck1: "",
      bleedingcheck2: "",
      bleedingcheck3: "",
      bleedingcheck4: "",
      bleedingcheck5: "",
      bleedingcheck6: "",
      bleedingcheck6input: "",
      seriousradio: "",
      seriousradio2: "",
      seriousradio3: "",
      cancerinput: "",
      cancerinput2: "",
      drop33check1: [],
      drop33check2: "",
      drop33check3: "",
      drop33check4: "",
      drop33check5: "",
      drop33checkinput: "",
      pregnantcheck:false,
    },
  ],
  doctorFeedback: [
    {
      feedback: "",

    }
  ],
  general: "1",
  status:"0"
};

const GeneralFormReduces = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_GENERAL_DATA":
      return { ...state, general: action.data };
    case "UPDATE_STATUS_DATA":
      return { ...state, status: action.data };

    case "UPDATE_HISTORY_COMPLAINT_FIELDS":
      const painFieldVal = action.data.value;
      const painFieldKey = action.data.key;
      const painNewArr = state.History.map((object) => {
        return { ...object, [painFieldKey]: painFieldVal };
      });
      return { ...state, History: painNewArr };

    case "UPDATE_HISTORY_COMPLAINT":
      return { ...state, History: action.data };

    case "UPDATE_SOCIAL_COMPLAINT_FIELDS":
      const painFieldVal2 = action.data.value;
      const painFieldKey2 = action.data.key;
      const painNewArr2 = state.Social.map((object) => {
        return { ...object, [painFieldKey2]: painFieldVal2 };
      });
      return { ...state, Social: painNewArr2 };


    case "UPDATE_SOCIAL_COMPLAINT":
      return { ...state, Social: action.data };

    case "UPDATE_PAST_COMPLAINT_FIELDS":
      const pastFieldVal2 = action.data.value;
      const pastFieldKey2 = action.data.key;
      const pastNewArr2 = state.Past.map((object) => {
        return { ...object, [pastFieldKey2]: pastFieldVal2 };
      });
      return { ...state, Past: pastNewArr2 };

    case "UPDATE_PAST_COMPLAINT":
      return { ...state, Past: action.data };

    case "UPDATE_FEEDBACK_COMPLAINT_FIELDS":
      const FeedbackField = action.data.value;
      const Feedbackkey = action.data.key;
      const Feedbackarray = state.doctorFeedback.map((object) => {
        return { ...object, [Feedbackkey]: FeedbackField };
      });
      return { ...state, doctorFeedback: Feedbackarray };

    case "UPDATE_FEEDBACK_COMPLAINT":
      return { ...state, doctorFeedback: action.data };

    default:
      return state;
  }
};

export default GeneralFormReduces;

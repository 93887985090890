/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import settingsStyle from "../Settings.module.css";
import Checkbox from "../../custom/checkbox/Checkbox";
import Dropdown from "../../custom/dropdown/Dropdown";
import { useDispatch, useSelector } from "react-redux";
import useAxios from "../../../axiosinstance";
import useTranslation from "../../customHooks/translations";
import Message from "../../custom/toster/Message";
import { loginData } from "../../../redux/actions/index";
import LanguageHandler from "../../LanguageHandler";

function Language() {
  const dispatch = useDispatch();
  let akToken = localStorage.getItem("AkTOKEN");
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const timeZone = useSelector((state) => state.mainReducer.timeZone.arr);
  const axiosInstance = useAxios();
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  // const [languageStatus, setLanguageStatus] = useState("english");
  const [timezone, setTimezone] = useState(login.ing_timezone);
  const [timeformat, setTimeformat] = useState(login.ing_timeformat);
  const [show, setShow] = useState(login.ing_timeformat === "1" ? false : true);
  const [dateFormat, setdateFormat] = useState(login.ing_date_formate);
  const [dateFormatData, setdateFormatData] = useState([]);
  const [country, setcountry] = useState(login.ing_operating_country_id);
  const [countrydata, setCountryLists] = useState([]);
  const translation = useTranslation();

  useEffect(() => {
    if (timeformat == "1") {
      setShow(false);
    } else {
      setShow(true);
    }
  }, [timeformat]);

  // const changeLanguage = (event) => {
  //   setLanguageStatus(event);
  // };
  const changeTimezone = (event) => {
    setTimezone(event);
  };
  const changeDateFormat = (event) => {
    setdateFormat(event);
  };
  const handleTimeformat = (e) => {
    console.log(e);
    // setTimeformat(!timeformat);

    if (e === false) {
      setTimeformat("1");
    }
    if (e === true) {
      setTimeformat("2");
    }
  };

  const handleCountry = (e) => {
    setcountry(e);
  };

  const countryList = async () => {
    try {
      const res = await axiosInstance.current.get(
        "extapp/forms/operating_country_list"
      );
      if (res.status === 200) {
        setCountryLists(res.data.data);
      }
    } catch (err) {
      console.log(err);
    }
  };

  useEffect(() => {
    countryList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleConnectMe = async () => {
    try {
      const result = await axiosInstance.current.get(
        `extapp/doctors/myprofile?ak_id=${akToken}`
      );
      if (result.status == "200") {
        // if(result.message)
        if (result.data.data.ing_acc_status == 1) {
          dispatch(loginData(result.data.data));
        }
      }
    } catch (err) {
      // console.log(err.message || "Unexpected Error!");
    } finally {
      console.log("api response finally");
    }
  };
  const handleSave = () => {
    const json = JSON.stringify({
      timeformat: timeformat,
      timezone: timezone,
      dateFormat: dateFormat,
      operating_country_id: country,
    });

    axiosInstance.current
      .put(
        `extapp/doctors/doctor_update_profile?ak_id=${login.ing_ak_id}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 200) {
          handleConnectMe();
          // if (res.data.data.message !== "Profile not updated") {
          setMessage(
            res.data.data.message === "Profile not updated"
              ? ""
              : res.data.data.message
          );
          setMessageType("success");
          setTimeout(() => {
            setMessage(false);
          }, 3000);
          // }
        }
      })
      .catch((err) => {
        setMessage("Something went wrong");
        setMessageType("error");
      });
  };
  // console.log(timezone)
  useEffect(() => {
    handleSave();
  }, [timeformat, timezone, dateFormat, country]);

  const data = timeZone.map((item) => {
    return { value: item.time, label: item.utctime };
  });
  useEffect(() => {
    axiosInstance.current
      .get(`extapp/doctors/getAllDateFormats/${login.ing_ak_id}`)
      .then((res) => {
        setdateFormatData(res.data.data);
        // console.log(res.data.data);
      });
  }, []);

  const data1 = dateFormatData.map((item) => {
    return { value: item.value, label: item.text };
  });

  return (
    <div className="">
      {message && <Message message={message} type={messageType} />}

      <h4 class={`${settingsStyle.content_main_title}`}>{"Language"}</h4>
      <div className="mb_15">
        <LanguageHandler></LanguageHandler>
        {/* <Dropdown
          data={[{ value: "english", label: "English (UK)" }]}
          label=""
          styleClass="language_drp_dwn"
          value={languageStatus}
          placeholder="Select Language"
          onChange={(e) => changeLanguage(e)}
        /> */}
        <p className="default_c pb_5 ">{translation.select}</p>
      </div>
      <hr />
      <div className="mb_15">
        <h4 class={`${settingsStyle.content_main_title}`}>
          {translation.timezone}
        </h4>
        <Dropdown
          data={data}
          label=""
          styleClass="language_drp_dwn"
          value={timezone}
          placeholder="Select Timezone"
          onChange={(e) => changeTimezone(e)}
        />
      </div>
      <br />
      <div className="mb_15 ">
        <h4 class={`${settingsStyle.content_main_title}`}>
          {translation.country}
        </h4>
        <Dropdown
          data={countrydata}
          label=""
          styleClass="language_drp_dwn"
          value={country}
          placeholder="Select Country"
          onChange={(e) => handleCountry(e)}
        />
      </div>
      <br />
      <div className="mb_15">
        <h4 class={`${settingsStyle.content_main_title}`}>
          {translation.Timeformat}
        </h4>
        <Checkbox
          label="24-Hour Time"
          selected={show}
          onChange={(e) => handleTimeformat(e)}
          styleClass="custom_check_box tick"
          id="Time_format"
        />
      </div>
      <div className="mb_15">
        <h4 class={`${settingsStyle.content_main_title}`}>
          {translation.dateFormet}
        </h4>
        <Dropdown
          data={data1}
          label=""
          styleClass="language_drp_dwn"
          value={dateFormat}
          placeholder="Select Date Format"
          onChange={(e) => changeDateFormat(e)}
        />
      </div>
    </div>
  );
}

export default Language;

/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import PropTypes from "prop-types";
import Dropdown from "react-bootstrap/Dropdown";
import actionStyles from "./TableActionItems.module.css";
import Message from "../toster/Message";
import DeleteConfirmation from "../delete-confirmation/DeleteConfirmation";
import toggleIcon from "../../../images/dots-blue.svg";
import viewIcon from "../../../images/eye-icon.svg";
import searchIcon from "../../../images/searchIcon.svg";
import linkicon from "../../../images/link.svg";
import Schedulerblue from "../../../images/Schedulerblue.png";
import editIcon from "../../../images/edit-icon.svg";
import checkIcon from "../../../images/check-icon.svg";
import deleteIcon from "../../../images/delete-icon.svg";
import navigateIcon from "../../../images/right-arrow-blue.svg";
import downloadLink from "../../../images/download-icon.svg";
import ProcessIcon from "../../../images/process-icon.gif";
import useAxios from "../../../axiosinstance";
import Editnote from "../editnote_popup/Editnote";
import { noteRefresh } from "../../../redux/actions";
import axios from "axios";
import { useSelector, useDispatch } from "react-redux";
import { s3UrlLink } from "../../../utilities/S3Url";
import Viewdoc from "./Viewdoc";
import Modal from "react-bootstrap/Modal";
import WidgetScheduler from "../../patients/WidgetScheduler";
import { useSearchParams } from "react-router-dom";
import Checkbox from "../../custom/checkbox/Checkbox";
import { yearDate } from "../../../utilities/DateFormate";
import AddModelTable from "../../patients/AddModelTable";
import SmallProfilescheduler from "../../settings/Proms/SmallProfilescheduler";
import { useRef } from "react";
import Button from "../button/Button";
import CreatableSelect from "react-select/creatable";
import Promsschudle from "../../patients/doctor-score-form/Promsschudle";

const TableActionItems = ({
  rowData,
  setshowModal,
  // styleClass,
  // onChange,
  // value,
  searchAction,
  linkAction,
  viewAction,
  editAction,
  feedbackAction,
  deleteAction,
  arrowAction,
  rowName,
  rowId,
  rowType,
  rowOrgId,
  other_link,
  link_url,
  filetype,
  filelinkUrl,
  edit,
  notetitle,
  notedescription,
  feedbackView,
  formID,
  patientId,
  feedbackEdit,
  managePlan,
  show_model,
  editopNote,
  fileName,
  opnoteview,
  download,
  Modelon1,
  Key,
  resType,
  evaluated,
  orgId,
  personal_info,
  doctorid,
  status,
  treatment_category,
  personalData,
  PostOpNoteshow,
  SchedulerCalenderView,
  fullname,
  tAge,
  resource_Id,
  side,
  setDeleteref,
  onlyuse,
  setgetOpNotecall,
  usedOpNote,
  shared_data,
  Status,
  clinicianeditevaluated,
  clinicianeditlocked,
  promseditlock
}) => {
  const axiosInstance = useAxios();
  // personal_info={row.row.original.personal_info}
  // let [searchParams, setSearchParams] = useSearchParams();
  // const ptshow = searchParams.get("ptshow");
  const dispatch = useDispatch();
  // const dRef = useRef();
  // Set up some additional local state
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const [displayConfirmationModal, setDisplayConfirmationModal] =
    useState(false);
  const [displayModal, setdisplayModal] = useState(false);
  // const handleChange = (event) => {
  //   const { value } = event.target;
  //   onChange(value);
  // };
  // Handle the displaying of the modal based on type and id

  const { ptId } = useParams();
  const [getData2, setGetData2] = useState(null);
  const [showModal1, setshowModal1] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState();
  const [Personaldata, setPersonaldata] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [operr, setOperr] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState([]);
  const [speciality, setSpeciality] = useState("");
  const [searchParams] = useSearchParams();
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const [schedulerForm, setschedulerForm] = useState([]);
  const [limit, setLimit] = useState(10);
  const [autoId, setAutoId] = useState("");
  const [isLoading, setIsLoading] = useState(true);
  const [dataLoading, setdataLoading] = useState(true)
  const [showModals, setShowModals] = useState(false);
  const [isApiPending, setIsApiPending] = useState(false);
  let age = searchParams.get("age");
  let resId = searchParams.get("rId");
  const [assignCheck, setAssignCheck] = useState([]);
  const [checkspecialtyType, setcheckspecialtyType] = useState([])

  const showDeleteModal = () => {
    setDisplayConfirmationModal(true);
  };
  const showNoteModal = () => {
    setdisplayModal(true);
  };

  const [Selectspec, setSelectspec] = useState([]);
  let spe = [];
  let selectspecialty = [
    { value: 4, label: "Shoulder" },
    { value: 5, label: "Knee" },
    { value: 6, label: "Hip" },
    { value: 33, label: "General" },
    { value: 23, label: "Hand and Wrist" },
    { value: 21, label: "Foot and Ankle" },
    { value: 22, label: "Elbow" },
  ];

  const HandleSelectspec = (selectedOptions) => {
    setSelectspec(selectedOptions);
    setschedulerForm([]);
  };

  Selectspec.map((ele) => {
    spe.push(ele.value);
  });

  let data = Selectspec?.map((e) => e.value);

  let specialtyarr = Selectspec?.map((item) => item.value);

  const GetTask = (url, name) => {
    axios
      .get(s3UrlLink(url), {
        headers: {
          "Content-Type": "application/octet-stream",
        },
        responseType: "blob",
      })
      .then((response) => {
        const a = document.createElement("a");
        const url = window.URL.createObjectURL(response.data);
        a.href = url;
        a.download = name;
        a.click();
      })
      .catch((err) => {
        console.log("error", err);
      });
  };

  // const GetTask = (fileli) => {
  //   axios.get(fileli).then((res) => {
  //     window.location.href = res.data;
  //   });
  // };

  const ModelOn = (res_id, Key, resType) => {
    Modelon1(true, res_id, Key, resType);
  };
  // Hide the modal
  const hideConfirmationModal = () => {
    setDisplayConfirmationModal(false);
  };

  const handleClose = () => {
    setdisplayModal(false);
    setIsApiPending(false)
  };

  // Handle the actual deletion of the item based on type
  // const handleDel = async () => {
  //   try {
  //     const result = await axiosInstance.current.delete(
  //       `extapp/forms/delete_froms?ak_id=${login.ing_ak_id}&res_id=${rowId}`
  //       // `extapp/forms/soft_delete_all_patient_form?akou=${orgId === undefined ? "" : orgId
  //       // }&akpt=${rowOrgId}&akdc=${rowName}`
  //     );
  //     if (result) {
  //       if (onlyuse) {
  //         setDeleteref(new Date().getMilliseconds())

  //       }
  //       dispatch(noteRefresh(new Date().getMilliseconds()));

  //       setMessage("Form Deleted Successfully");
  //       setMessageType("success");
  //       dispatch(noteRefresh(!dataRefe));
  //       setTimeout(() => {
  //         setMessage(false);
  //       }, 4000);
  //     }
  //     // setMessage("Form Deleted Successfully");
  //     // setMessageType("success");
  //     // dispatch(noteRefresh(!dataRefe));
  //     // setTimeout(() => {
  //     //   setMessage(false);
  //     // }, 4000);
  //   } catch (err) {
  //     console.error("Error in handleDel:", err);
  //   } finally {
  //     console.log("api response finally");
  //   }
  // };
  const handleDel = async () => {
    try {
      const result = await axiosInstance.current.delete(
        `extapp/forms/delete_froms?ak_id=${login.ing_ak_id}&res_id=${rowId}`
      );

      if (result) {
        // Update local state or dispatch Redux action to trigger re-render
        if (onlyuse) {
          setDeleteref(new Date().getMilliseconds());
        }
        dispatch(noteRefresh(new Date().getMilliseconds()));

        // Show success message or handle UI update
        setMessage("Form Deleted Successfully");
        setMessageType("success");

        // Optionally clear message after some time
        setTimeout(() => {
          setMessage("");
        }, 4000);
      }
    } catch (err) {
      console.error("Error in handleDel:", err);
      // Handle error state or display error message
    } finally {
      console.log("API response handled");
    }
  };

  const handleDelByrID = async () => {
    try {
      const result = await axiosInstance.current.patch(
        `extapp/forms/soft_delete_form?resource_id=${rowName}`
      );
      if (usedOpNote) {
        setgetOpNotecall(new Date().getMilliseconds())
      }
      setMessage("Treatment Note Deleted");
      setMessageType("success");
      dispatch(noteRefresh(!dataRefe));
      setTimeout(() => {
        setMessage(false);
      }, 4000);
    } catch (err) {
      console.log(err.message);
    } finally {
      console.log("api response finally");
    }
  };
  const submitDelete = async () => {
    // await
    if (rowType === "patients") {
      handleDel();

      // console.log("==patient row deleted==");
    }
    if (rowType === "management") {
      handleDel();
    }

    if (rowType === "opNote") {
      handleDelByrID();
    }
    if (rowType === "document") {
      handleDelByrID();
    }
    if (rowType === "userNote") {
      handleDelByrID();
    }

    setDisplayConfirmationModal(false);
  };
  // const OnClick=()=>{
  //   console.log("Hello");
  // }

  const handleForm = () => {
    if (formID === 4) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "shoulder-view-treatment-form" +
        `/${rowId}?doctor=${rowName}&patient=${patientId}&form=true&action=2&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 6) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "hip-view-treatment-form" +
        `/${rowId}?doctor=${rowName}&patient=${patientId}&form=true&action=2&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 21) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "foot-ankle-view-treatment-form" +
        `/${rowId}?doctor=${rowName}&patient=${patientId}&form=true&action=2&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 22) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "elbow-view-treatment-form" +
        `/${rowId}?doctor=${rowName}&patient=${patientId}&form=true&action=2&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 23) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "hand-wrist-view-treatment-form" +
        `/${rowId}?doctor=${rowName}&patient=${patientId}&form=true&action=2&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 20) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "pediaknee-view-treatment-form" +
        `/${rowId}?doctor=${rowName}&patient=${patientId}&form=true&action=2&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 5) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "adultknee-view-treatment-form" +
        `/${rowId}?doctor=${rowName}&patient=${patientId}&form=true&action=2&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 33) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "general-treatment-view" +
        `/${rowId}?doctor=${rowName}&patient=${patientId}&form=true&action=2&is_doctor_form=true`,
        "_blank"
      );
    }
  };
  const reDir = () => {
    if (formID === 4) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "/pain/" +
        rowId +
        "?is_doctor_form=true"
      );
    }
    if (formID === 5) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "/adult-knee-pain/" +
        rowId +
        "?is_doctor_form=true"
      );
    }
    if (formID === 20) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "/pedia-knee-pain/" +
        rowId +
        "?is_doctor_form=true"
      );
    }
    if (formID === 6) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "/hip-pain/" +
        rowId +
        "?is_doctor_form=true"
      );
    }
    if (formID === 21) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "/foot-ankle-pain/" +
        rowId +
        "?is_doctor_form=true"
      );
    }
    if (formID === 22) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "/elbow-pain/" +
        rowId +
        "?is_doctor_form=true"
      );
    }
    if (formID === 23) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "/hand-and-wrist-pain/" +
        rowId +
        "?is_doctor_form=true"
      );
    }
    if (formID === 33) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "/general-history/" +
        rowId +
        "?is_doctor_form=true"
      );
    }
  };

  const handleFeedBack = () => {
    // alert(formID)
    // if (personal_info) {
    if (formID === 4) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "shoulder-treatment-form/step-1" +
        `/${rowId}?doctor=${rowName}&patient=${patientId}&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 6) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "hip-feedback" +
        `/${rowId}?doctor=${rowName}&patient=${patientId}&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 21) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "foot-doctor-feedback" +
        `/${rowId}?doctor=${rowName}&patient=${patientId}&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 22) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "elbow-feedback" +
        `/${rowId}?doctor=${rowName}&patient=${patientId}&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 23) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "hand-wrist-feedback" +
        `/${rowId}?doctor=${rowName}&patient=${patientId}&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 20) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "knee-feedback" +
        `/${rowId}?doctor=${rowName}&patient=${patientId}&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 5) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "knee-feedback" +
        `/${rowId}?doctor=${rowName}&patient=${patientId}&is_doctor_form=true`,
        "_blank"
      );
    }
    if (formID === 33) {
      window.open(
        process.env.REACT_APP_FORM_URL +
        "general-feedback" +
        `/${rowId}?doctor=${rowName}&patient=${patientId}&is_doctor_form=true`,
        "_blank"
      );
    }
    // } else {
    //   window.open(
    //     process.env.REACT_APP_FORM_URL +
    //     "personal-data" +
    //     `/${patientId}` +
    //     `/${window.btoa(rowName)}` +
    //     `/${window.btoa(orgId)}` +
    //     `/${window.btoa(formID)}?doctor=true`,
    //     "_blank"
    //   );
    // }
  };
  let treatmentcategory = localStorage.getItem("SchedulerTreatmentcategory");
  const getData = async () => {
    setIsLoading(true);
    setCheckboxValues([])
    setcheckspecialtyType([])
    try {
      const response = await axiosInstance.current.get(
        `extapp/doctors/proms_clinicians_schedulerGet?ak_dc=${login.ing_ak_id
        }&limit=${limit}&offset=${0}&resource_type=44&akou_id=${rowOrgId}&reference_date=1&speciality=${specialtyarr.toString()}&status=1&treatment_category=${treatment_category === "surgical"
          ? "Surgical"
          : treatment_category === "research"
            ? "Research"
            : treatment_category === "non_surgical"
              ? "Non-Surgical"
              : ""
        }`
      );
      if (response.status === 200) {
        let datasurgical = response.data.data;

        setschedulerForm(datasurgical);
        setAutoId(response.data.data[0].auto_id);
        // let datasurgical = response.data.data.filter((item) => item.treatment_category === "Surgical")
        // let datasurgical = response.data.data;
        // setschedulerForm(datasurgical);
        setSpeciality(response.data.data.speciality);
      }
    } catch (error) {
      if (error) {
        setschedulerForm(error.response.data.data);
      }
    } finally {
      setIsLoading(false);
    }
  };

  console.log(status,"statusstatusstatus",Status);
  

  useEffect(() => {
    if (specialtyarr.length) {
      getData();
    }
  }, [showModal, specialtyarr.length]);
  const akou = window.btoa(orgId);
  const [assignErr, setassignErr] = useState(false);
  const [concatenatedIds, setConcatenatedAutoIds] = useState("");
  const [ptIds, setptIds] = useState("");
  const [showSchedulerImage, setShowSchedulerImage] = useState(true);

  const json = JSON.stringify({
    proms_clinicians_auto_id: checkboxValues,
  });

  const handleSaveTask = async () => {
    try {
      const res = await axiosInstance.current.put(
        `/extapp/forms/update_op_notes?resource_id=${resource_Id}&status=1`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.status === 200) {
        setCheckboxValues([]);
        dispatch(noteRefresh(new Date().getMilliseconds()));
        // generatePDFDocument();
        // navigate('/operative-notes-list')
      }
    } catch (err) {
      console.log(err);
    }
  };


  const handleCreate = () => {
    const concatenatedAutoIds = checkboxValues.join(",");
    setConcatenatedAutoIds(concatenatedAutoIds);
    setptIds(ptId);
    const operativeSide = getData2?.operativeSide || "";
    if (concatenatedAutoIds === "") {
      setassignErr(true);
      return;
    } else {
      setassignErr(false);
    }
    const json = JSON.stringify({
      // resource_id: resId,
      auto_id: concatenatedAutoIds,
      // side: operativeSide,
      side: side,
      ak_ou: rowOrgId,
      ak_pt: rowId,
      ak_dc: login.ing_ak_id,
      speciality: [...new Set(checkspecialtyType)].toString(),
      assign_note_id: resource_Id.toString(),
    });
    axiosInstance.current
      .post(
        `extapp/doctors/assigned_schedulesCreate?ak_dc=${login.ing_ak_id}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        if (res.status === 201) {
          if (usedOpNote) {
            setgetOpNotecall(new Date().getMilliseconds())
          }
          setMessageType("success");
          setMessage("Schedule Assigned successfully");
          setShowModal(false);
          setOperr(false);
          setCheckboxValues([]);
          setSelectspec([]);
          handleSaveTask();
          dispatch(noteRefresh(new Date().getMilliseconds()));
          // setShowSchedulerImage(false);

          // setShowModals(true);
        }
      })
      .catch((err) => {
        // Handle errors if needed
      });
  };

  const submitButtonRef = useRef(null);

  const handleCheckboxChange = () => {
    if (submitButtonRef && submitButtonRef.current) {
      submitButtonRef.current.click();
    }
  };

  const updateCheckboxValues = (newValue) => {
    if (newValue.checked) {
      setCheckboxValues((prevValues) => [...prevValues, newValue.resource_id]);
      setcheckspecialtyType((prevValues) => [...prevValues, newValue.specialitytype])
    } else {
      setCheckboxValues((prevValues) =>
        prevValues.filter((value) => value !== newValue.resource_id)
      );
      setcheckspecialtyType((prevValues) =>
        prevValues.filter((value) => value !== newValue.specialitytype))
    }
  };

  const [resIdCondis, setCondisResId] = useState("");

  const checkShedule = () => {

    const concatenatedAutoIds = checkboxValues.join(",");
    setIsApiPending(true);
    axiosInstance.current
      // .get(
      //   `/extapp/doctors/assignCheck?ak_dc=${login.ing_ak_id}&resource_id=${concatenatedAutoIds}&ak_pt=${rowId}&akou_id=${rowOrgId}&speciality=${spe}&treatment_category=${treatmentcategory}`
      // )
      .get(
        `/extapp/doctors/assignCheck?ak_dc=${login.ing_ak_id}&resource_id=${concatenatedAutoIds}&ak_pt=${rowId}&akou_id=${rowOrgId}&speciality=${[...new Set(checkspecialtyType)].toString()}`
      )

      .then((response) => {
        if (response.status === 200) {

          setAssignCheck(response.data.data);
          setCondisResId(String(response.data.data[0].assign_id));
          setShowModals(true);
          setIsApiPending(false);
          // dispatch(noteRefresh(new Date().getMilliseconds()));
        }
      })
      .catch((err) => {
        if (err) {
          setAssignCheck([]);
          handleCreate();

          setShowModals(false);
        }
      });
  };

  // useEffect(()=>{
  //   if(SchedulerCalenderView !=="true"){
  //     setShowSchedulerImage(false);
  //   }
  // })

  // useEffect(() => {
  //   if (assignCheck.length !== 0) {
  //     setShowModals(true);
  //   }

  // }, [assignCheck]);

  // useEffect(() => {
  //   if (concatenatedIds !== "") {
  //     checkShedule();
  //   }
  // }, [concatenatedIds]);
  let datacheckdata = assignCheck.map((item) => item.assign_id).toString();
  const handleStop = async () => {
    try {
      const result = await axiosInstance.current.put(
        `extapp/doctors/proms_clinicians_schedulerConDis?ak_dc=${login.ing_ak_id}&resource_id=${datacheckdata}&n=-1`
      );
      if (result.status === 200) {
        handleCreate();
        dispatch(noteRefresh(new Date().getMilliseconds()));
      }
    } catch (err) {
      console.log(err);
    } finally {
      console.log("finally api responsed");
    }
  };

  const [show, setShow] = useState(false);
  const handleShow = () => {
    setShow(true);
  };

  const handleShowModal = async () => {
    setShowModal(true);
  };

  const handleCloseShow = () => {
    setShow(false);
  };

  const handleCloseModal = async () => {
    setShowModal(false);
    setSelectspec([]);
  };

  const handleOpenModal = () => {
    setshowModal1(true);
  };

  const handleCloseModals = () => {
    setShowModals(false);
  };

  const handleCancel = () => {
    setShowModals(false);
  };

  const handleKeep = () => {
    handleCreate();
    setShowModals(false);
  };
  const handleReplace = () => {
    handleStop();
    // handleCreate();
    setShowModals(false);
  };

  const [displayConfirmationModal2, setDisplayConfirmationModal2] =
    useState(false);
  const [formid, setFormid] = useState("");
  const[form_status,setFormStatus]=useState('')
  const handleLink = () => {
    setFormid(formID);
    setFormStatus('3')
    if (formID) {
      setDisplayConfirmationModal2(true);
    }
  };
  localStorage.setItem("patient_name", fullname);
  localStorage.setItem("patient_age", tAge);


  const handleSave = () => {
    setshowModal1(false);
    setShowModal(false);
  };

  let set = new Set();

  console.log(promseditlock,"promseditlockpromseditlock");
  

  return (
    <>
      {message && <Message message={message} type={messageType} />}
      <div
        className={`action_large_dev custom_sort_dropdown ${actionStyles.action_large_dev}`}
      >
        <ul>
          {searchAction === "true" ? (
            <>
              <li>
                <img
                  className={`action_icons`}
                  src={searchIcon}
                  alt="Icon"
                  onClick={() => handleLink()}
                  data-bs-toggle="tooltip" data-bs-placement="top" title="Linking"
                />
              </li>
            </>
          ) : (
            ""
          )}
          {linkAction === "true" ? (
            <>
              <li>
                <img className={`action_icons`} src={linkicon} alt="Icon" />
              </li>
            </>
          ) : (
            ""
          )}

          {viewAction === "true" ? (
            <>
              {treatment_category === "research" ? (
                <>
                  <li>
                    <Link
                      to={`/research-details/${window.btoa(
                        rowId
                      )}/${window.btoa(rowName)}?status=${Status}`}
                    >
                      <img
                        className={`action_icons`}
                        src={viewIcon}
                        alt="Icon"
                      />
                    </Link>
                  </li>
                </>
              ) : treatment_category === "non_surgical" ? (
                <>
                  <li>
                    <Link
                      to={`/treatement-details/${window.btoa(
                        rowId
                      )}/${window.btoa(rowName)}?status=${Status}`}
                    >
                      <img
                        className={`action_icons`}
                        src={viewIcon}
                        alt="Icon"
                      />
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  {opnoteview === "4" ? (
                    <>
                      <li>
                        <Link
                          to={`/${rowData.view_url}/${window.btoa(
                            rowId
                          )}/${window.btoa(rowName)}`}
                        >
                          <img
                            className={`action_icons`}
                            src={viewIcon}
                            alt="Icon"
                          />
                        </Link>
                      </li>
                    </>
                  ) : opnoteview === "6" ? (
                    <li>
                      <Link
                        to={`/view-hip-operative-note/${window.btoa(
                          rowId
                        )}/${window.btoa(rowName)}`}
                      >
                        <img
                          className={`action_icons`}
                          src={viewIcon}
                          alt="Icon"
                        />
                      </Link>
                    </li>
                  ) : opnoteview === "33" ? (
                    <li>
                      <Link
                        to={`/view-genaral-operative-notes/${window.btoa(
                          rowId
                        )}/${window.btoa(rowName)}`}
                      >
                        <img
                          className={`action_icons`}
                          src={viewIcon}
                          alt="Icon"
                        />
                      </Link>
                    </li>
                  ) : opnoteview === "5" ? (
                    <li>
                      <Link
                        to={`/view-knee-operative-note/${window.btoa(
                          rowId
                        )}/${window.btoa(rowName)}`}
                      >
                        <img
                          className={`action_icons`}
                          src={viewIcon}
                          alt="Icon"
                        />
                      </Link>
                    </li>
                  ) : opnoteview === "22" ? (
                    <li>
                      <Link
                        to={`/view-elbow-operative-note/${window.btoa(
                          rowId
                        )}/${window.btoa(rowName)}`}
                      >
                        <img
                          className={`action_icons`}
                          src={viewIcon}
                          alt="Icon"
                        />
                      </Link>
                    </li>
                  ) : opnoteview === "21" ? (
                    <li>
                      <Link
                        to={`/view-foot-operative-note/${window.btoa(
                          rowId
                        )}/${window.btoa(rowName)}`}
                      >
                        <img
                          className={`action_icons`}
                          src={viewIcon}
                          alt="Icon"
                        />
                      </Link>
                    </li>
                  ) : opnoteview === "23" ? (
                    <li>
                      <Link
                        to={`/view-hand-operative-note/${window.btoa(
                          rowId
                        )}/${window.btoa(rowName)}`}
                      >
                        <img
                          className={`action_icons`}
                          src={viewIcon}
                          alt="Icon"
                        />
                      </Link>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to={`/${rowData.view_url}/${window.btoa(
                          rowId
                        )}/${window.btoa(rowName)}`}
                      >
                        <img
                          className={`action_icons`}
                          src={viewIcon}
                          alt="Icon"
                        />
                      </Link>
                    </li>
                  )}
                </>
              )}
            </>
          ) : (
            ""
          )}
          


          {managePlan === "true" ? (
            <li>
              <Link
                to={`/${rowData.view_url}/${window.btoa(rowId)}/${window.btoa(
                  rowName
                )}/?org=${window.btoa(rowOrgId)}&formType=${window.btoa(
                  formID
                )}`}
              >
                <img className={`action_icons`} src={viewIcon} alt="Icon" />
              </Link>
            </li>
          ) : (
            ""
          )}

          {feedbackView === "true" ? (
            <li>
              <img
                className={`action_icons`}
                src={viewIcon}
                alt="Icon"
                onClick={() => handleForm()}
              />
            </li>
          ) : (
            ""
          )}
          {other_link === "true" ? (
            <li>
              <a href={link_url} target="_blank">
                <img className={`action_icons`} src={viewIcon} alt="Icon" />
              </a>
            </li>
          ) : (
            ""
          )}

          {status === "0" ? (
            <li>
              <img
                className={`action_icons`}
                src={ProcessIcon}
                alt="Icon"
                style={{ width: "20px" }}
              />
            </li>
          ) : (
            <>
              {download === "true" && (
                <li>
                  <span onClick={() => ModelOn(rowName, Key, resType)}>
                    <img
                      className={`action_icons`}
                      src={downloadLink}
                      alt="Icon"
                    />
                  </span>
                </li>
              )}
            </>
          )}

          {show_model === "true" ? (
            <>
              <li>
                <a onClick={handleShow} target="_blank">
                  <img className={`action_icons`} src={viewIcon} alt="Icon" />
                </a>
              </li>
              <li>
                <span onClick={() => GetTask(filelinkUrl, fileName)}>
                  <img
                    className={`action_icons`}
                    src={downloadLink}
                    alt="Icon"
                  />
                </span>
              </li>
            </>
          ) : (
            ""
          )}

{promseditlock=== false && !clinicianeditevaluated && !clinicianeditlocked?(
            <>
            <li>
                  <img
                    className={`action_icons`}
                    src={editIcon}
                    alt="Icon"
                    onClick={() => reDir()}
                  />
                </li>
            </>
          ):""}

          {editopNote === "true" && Status === "0" && !shared_data ? (
            <li>
              <Link
                to={`/add-operative-notes/${rowName}/${rowId}?ptshow=${true}&orgId=${orgId}`}
              >
                <img className={`action_icons`} src={editIcon} alt="Icon" />
              </Link>
            </li>
          ) : (
            ""
          )}

          {editAction === "true" && !shared_data ? (
            <li>
              <Link to={`/${rowData.edit_url}/${window.btoa(rowId)}`}>
                <img className={`action_icons`} src={editIcon} alt="Icon" />
              </Link>
            </li>
          ) : (
            ""
          )}

          {clinicianeditevaluated === "true" && clinicianeditlocked === "true" ? (
            <>
              <li>
                <img
                  className={`action_icons`}
                  src={editIcon}
                  alt="Icon"
                  onClick={() => handleFeedBack()}
                />
              </li>
            </>
          ) : ""}
          {feedbackEdit === "true" && Status === "0" && !shared_data ? (
            <>
              {doctorid === "2" ? (
                <li>
                  <img
                    className={`action_icons`}
                    src={editIcon}
                    alt="Icon"
                    onClick={() => reDir()}
                  />
                </li>
              ) : (
                <li>
                  <img
                    className={`action_icons`}
                    src={editIcon}
                    alt="Icon"
                    onClick={() => handleFeedBack()}
                  />
                </li>
              )}
            </>
          ) : (
            ""
          )}
          {edit === "true" && Status === "0" && !shared_data ? (
            <li>
              <span onClick={() => showNoteModal()}>
                <img className={`action_icons`} src={editIcon} alt="Icon" />
              </span>
            </li>
          ) : (
            ""
          )}
          {feedbackAction === "true" ? (
            <li>
              <Link to={`/${rowData.mark_url}/${window.btoa(rowId)}`}>
                <img className={`action_icons`} src={checkIcon} alt="Icon" />
              </Link>
            </li>
          ) : (
            ""
          )}

          {deleteAction === "0" ? (
            <li>
              <span
                onClick={() => showDeleteModal("vegetable", 1)}
                style={{ cursor: "pointer" }}
              >
                <img className={`action_icons`} src={deleteIcon} alt="Icon" />
              </span>
            </li>
          ) : (
            ""
          )}

          {SchedulerCalenderView && showSchedulerImage && (
            <li>
              <img
                className={`action_icons`}
                src={Schedulerblue}
                alt="Icon"
                onClick={handleShowModal}
                style={{ cursor: "pointer", width: "20px", height: "20px" }}
                data-bs-toggle="tooltip"
                title="Set Schedule"
              />
            </li>
          )}

          {arrowAction === "true" ? (
            <>
              {treatment_category === "research" ? (
                <>
                  <Link
                    to={`/research-details/${window.btoa(
                      rowId
                    )}/${window.btoa(rowName)}`}
                  >
                    <img
                      className={`action_icons`}
                      src={navigateIcon}
                      alt="Icon"
                    />
                  </Link>

                </>
              ) : treatment_category === "non_surgical" ? (
                <>
                  <li>
                    <Link
                      to={`/treatement-details/${window.btoa(
                        rowId
                      )}/${window.btoa(rowName)}`}
                    >
                      <img
                        className={`action_icons`}
                        src={navigateIcon}
                        alt="Icon"
                      />
                    </Link>
                  </li>
                </>
              ) : (
                <>
                  {opnoteview === "4" ? (
                    <>
                      <li>
                        <Link
                          to={`/${rowData.view_url}/${window.btoa(
                            rowId
                          )}/${window.btoa(rowName)}`}
                        >
                          <img
                            className={`action_icons`}
                            src={navigateIcon}
                            alt="Icon"
                          />
                        </Link>
                      </li>
                    </>
                  ) : opnoteview === "6" ? (
                    <li>
                      <Link
                        to={`/view-hip-operative-note/${window.btoa(
                          rowId
                        )}/${window.btoa(rowName)}`}
                      >
                        <img
                          className={`action_icons`}
                          src={navigateIcon}
                          alt="Icon"
                        />
                      </Link>
                    </li>
                  ) : opnoteview === "33" ? (
                    <li>
                      <Link
                        to={`/view-genaral-operative-notes/${window.btoa(
                          rowId
                        )}/${window.btoa(rowName)}`}
                      >
                        <img
                          className={`action_icons`}
                          src={navigateIcon}
                          alt="Icon"
                        />
                      </Link>
                    </li>
                  ) : opnoteview === "5" ? (
                    <li>
                      <Link
                        to={`/view-knee-operative-note/${window.btoa(
                          rowId
                        )}/${window.btoa(rowName)}`}
                      >
                        <img
                          className={`action_icons`}
                          src={navigateIcon}
                          alt="Icon"
                        />
                      </Link>
                    </li>
                  ) : opnoteview === "22" ? (
                    <li>
                      <Link
                        to={`/view-elbow-operative-note/${window.btoa(
                          rowId
                        )}/${window.btoa(rowName)}`}
                      >
                        <img
                          className={`action_icons`}
                          src={navigateIcon}
                          alt="Icon"
                        />
                      </Link>
                    </li>
                  ) : opnoteview === "21" ? (
                    <li>
                      <Link
                        to={`/view-foot-operative-note/${window.btoa(
                          rowId
                        )}/${window.btoa(rowName)}`}
                      >
                        <img
                          className={`action_icons`}
                          src={navigateIcon}
                          alt="Icon"
                        />
                      </Link>
                    </li>
                  ) : opnoteview === "23" ? (
                    <li>
                      <Link
                        to={`/view-hand-operative-note/${window.btoa(
                          rowId
                        )}/${window.btoa(rowName)}`}
                      >
                        <img
                          className={`action_icons`}
                          src={navigateIcon}
                          alt="Icon"
                        />
                      </Link>
                    </li>
                  ) : (
                    <li>
                      <Link
                        to={`/${rowData.view_url}/${window.btoa(
                          rowId
                        )}/${window.btoa(rowName)}`}
                      >
                        <img
                          className={`action_icons`}
                          src={navigateIcon}
                          alt="Icon"
                        />
                      </Link>
                    </li>
                  )}
                </>
              )}
            </>
          ) : (
            ""
          )}
        </ul>
      </div>
      <div
        className={`action_small_dev text-end custom_sort_dropdown ${actionStyles.action_small_dev}`}
      >
        <Dropdown
          className={`custom_dropdown ${actionStyles.drop_down} text-start `}
        >
          <Dropdown.Toggle
            className={actionStyles.dropdown_button}
            variant="default"
            id="dropdown-basic"
          >
            <span>
              <img
                className={`toggle_dots_img`}
                src={toggleIcon}
                alt="toggle"
              />
            </span>
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {searchAction === "true" ? (
              <>
                <Dropdown.Item onClick={() => handleLink()}>
                  {/* <Link
                        to={`/${rowData.view_url}/${window.btoa(
                          rowId
                        )}/${window.btoa(rowName)}`}
                      > */}
                  <img className={`action_icons`} src={searchIcon} alt="Icon" />
                  Link Now
                  {/* </Link> */}
                </Dropdown.Item>
                {/* <li >
                  <img className={`action_icons`} src={searchIcon} alt="Icon" onClick={()=>handleLink()} />
                </li> */}
              </>
            ) : (
              ""
            )}
            {linkAction === "true" ? (
              <>
                <li>
                  <img className={`action_icons`} src={linkicon} alt="Icon" />
                  Link Now
                </li>
              </>
            ) : (
              ""
            )}

            {viewAction === "true" ? (
              <>
                {treatment_category === "research" ? (
                  <>
                    <Dropdown.Item>
                      <Link
                        to={`/research-details/${window.btoa(
                          rowId
                        )}/${window.btoa(rowName)}`}
                      >
                        <img
                          className={`action_icons`}
                          src={viewIcon}
                          alt="Icon"
                        />
                        View
                      </Link>
                    </Dropdown.Item>
                  </>
                ) : treatment_category === "non_surgical" ? (
                  <>
                    <Dropdown.Item>
                      <li>
                        <Link
                          to={`/treatement-details/${window.btoa(
                            rowId
                          )}/${window.btoa(rowName)}`}
                        >
                          <img
                            className={`action_icons`}
                            src={viewIcon}
                            alt="Icon"
                          />
                          View
                        </Link>
                      </li>
                    </Dropdown.Item>
                  </>
                ) : (
                  <>
                    {opnoteview === "4" ? (
                      <>
                        <Dropdown.Item>
                          <Link
                            to={`/${rowData.view_url}/${window.btoa(
                              rowId
                            )}/${window.btoa(rowName)}`}
                          >
                            <img
                              className={`action_icons`}
                              src={viewIcon}
                              alt="Icon"
                            />
                            View
                          </Link>
                        </Dropdown.Item>
                      </>
                    ) : opnoteview === "6" ? (
                      <Dropdown.Item>
                        <Link
                          to={`/view-hip-operative-note/${window.btoa(
                            rowId
                          )}/${window.btoa(rowName)}`}
                        >
                          <img
                            className={`action_icons`}
                            src={viewIcon}
                            alt="Icon"
                          />
                          View
                        </Link>
                      </Dropdown.Item>
                    ) : opnoteview === "33" ? (
                      <Dropdown.Item>
                        <Link
                          to={`/view-genaral-operative-notes/${window.btoa(
                            rowId
                          )}/${window.btoa(rowName)}`}
                        >
                          <img
                            className={`action_icons`}
                            src={viewIcon}
                            alt="Icon"
                          />
                          View
                        </Link>
                      </Dropdown.Item>
                    ) : opnoteview === "5" ? (
                      <Dropdown.Item>
                        <Link
                          to={`/view-knee-operative-note/${window.btoa(
                            rowId
                          )}/${window.btoa(rowName)}`}
                        >
                          <img
                            className={`action_icons`}
                            src={viewIcon}
                            alt="Icon"
                          />
                          View
                        </Link>
                      </Dropdown.Item>
                    ) : opnoteview === "22" ? (
                      <Dropdown.Item>
                        <Link
                          to={`/view-elbow-operative-note/${window.btoa(
                            rowId
                          )}/${window.btoa(rowName)}`}
                        >
                          <img
                            className={`action_icons`}
                            src={viewIcon}
                            alt="Icon"
                          />
                          View
                        </Link>
                      </Dropdown.Item>
                    ) : opnoteview === "21" ? (
                      <Dropdown.Item>
                        <Link
                          to={`/view-foot-operative-note/${window.btoa(
                            rowId
                          )}/${window.btoa(rowName)}`}
                        >
                          <img
                            className={`action_icons`}
                            src={viewIcon}
                            alt="Icon"
                          />
                          View
                        </Link>
                      </Dropdown.Item>
                    ) : opnoteview === "23" ? (
                      <Dropdown.Item>
                        <Link
                          to={`/view-hand-operative-note/${window.btoa(
                            rowId
                          )}/${window.btoa(rowName)}`}
                        >
                          <img
                            className={`action_icons`}
                            src={viewIcon}
                            alt="Icon"
                          />
                          View
                        </Link>
                      </Dropdown.Item>
                    ) : (
                      <Dropdown.Item>
                        <Link
                          to={`/${rowData.view_url}/${window.btoa(
                            rowId
                          )}/${window.btoa(rowName)}`}
                        >
                          <img
                            className={`action_icons`}
                            src={viewIcon}
                            alt="Icon"
                          />
                          View
                        </Link>
                      </Dropdown.Item>
                    )}
                  </>
                )}
              </>
            ) : (
              ""
            )}
            {managePlan === "true" ? (
              <>
                <Dropdown.Item>
                  <Link
                    to={`/${rowData.view_url}/${window.btoa(
                      rowId
                    )}/${window.btoa(rowName)}/?org=${window.btoa(
                      rowOrgId
                    )}&formType=${window.btoa(formID)}`}
                  >
                    <img className={`action_icons`} src={viewIcon} alt="Icon" />
                    View
                  </Link>
                </Dropdown.Item>
              </>
            ) : (
              ""
            )}

            {editopNote === "true" && Status === "0" && !shared_data ? (
              <Dropdown.Item>
                <Link
                  to={`/add-operative-notes/${rowName}/${rowId}?ptshow=${true}&orgId=${orgId}`}
                >
                  <img className={`action_icons`} src={editIcon} alt="Icon" />
                  Edit
                </Link>
              </Dropdown.Item>
            ) : ""}
            {other_link === "true" ? (
              <Dropdown.Item href={link_url}>
                <a href={link_url} target="_blank">
                  <img className={`action_icons`} src={viewIcon} alt="Icon" />
                  View
                </a>
              </Dropdown.Item>
            ) : (
              ""
            )}

            {clinicianeditevaluated === "true" && clinicianeditlocked === "true" ? (
              <>
                <Dropdown.Item>
                  <span onClick={() => handleFeedBack()}>
                    <img
                      className={`action_icons`}
                      src={editIcon}
                      alt="Icon"
                    />
                    Edit
                  </span>
                </Dropdown.Item>
              </>
            ) : ""}
            {editAction === "true" && Status === "0" && !shared_data === true ? (
              <Dropdown.Item>
                <Link to={`/${rowData.edit_url}/${window.btoa(rowId)}`}>
                  <img className={`action_icons`} src={editIcon} alt="Icon" />
                  Edit
                </Link>
              </Dropdown.Item>
            ) : (
              ""
            )}
            {edit === "true" && Status === "0" && !shared_data === true ? (
              <Dropdown.Item href={link_url}>
                <span onClick={() => showNoteModal()}>
                  <a href={link_url} target="_blank">
                    <img className={`action_icons`} src={editIcon} alt="Icon" />
                    Edit
                  </a>
                </span>
              </Dropdown.Item>
            ) : (
              ""
            )}
            {feedbackAction === "true" ? (
              <Dropdown.Item>
                <Link to="/">
                  <img className={`action_icons`} src={checkIcon} alt="Icon" />
                  Feedback
                </Link>
              </Dropdown.Item>
            ) : (
              ""
            )}
            {promseditlock=== false ?(
            <>
             <Dropdown.Item>
                    <span onClick={() => reDir()}>
                      <img
                        className={`action_icons`}
                        src={editIcon}
                        alt="Icon"
                      />
                      Edit
                    </span>
                  </Dropdown.Item>
            </>
            ):""}
            {feedbackEdit === "true" && Status === "0" && !shared_data === true ? (
              <>
                {doctorid === "2" ? (
                  <Dropdown.Item>
                    <span onClick={() => reDir()}>
                      <img
                        className={`action_icons`}
                        src={editIcon}
                        alt="Icon"
                      />
                      Edit
                    </span>
                  </Dropdown.Item>
                ) : (
                  <Dropdown.Item>
                    <span onClick={() => handleFeedBack()}>
                      <img
                        className={`action_icons`}
                        src={editIcon}
                        alt="Icon"
                      />
                      Edit
                    </span>
                  </Dropdown.Item>
                )}
              </>
            ) : (
              ""
            )}
            {feedbackView === "true" ? (
              <Dropdown.Item>
                <span onClick={() => handleForm()}>
                  <img
                    className={`action_icons`}
                    src={viewIcon}
                    alt="Icon"
                    onClick={() => handleForm()}
                  />
                  View
                </span>
              </Dropdown.Item>
            ) : (
              ""
            )}
            {show_model === "true" ? (
              <>
                <Dropdown.Item>
                  <a onClick={handleShow} target="_blank" href="">
                    <img className={`action_icons`} src={viewIcon} alt="Icon" />
                    View
                  </a>
                </Dropdown.Item>
                <Dropdown.Item>
                  <span onClick={() => GetTask(filelinkUrl, fileName)}>
                    <img
                      className={`action_icons`}
                      src={downloadLink}
                      alt="Icon"
                    />
                    Download
                  </span>
                </Dropdown.Item>
              </>
            ) : (
              ""
            )}
            {status === "0" ? (
              <Dropdown.Item>
                <span>
                  <img
                    className={`action_icons`}
                    src={ProcessIcon}
                    alt="Icon"
                    style={{ width: "20px" }}
                  />
                  Processing
                </span>
              </Dropdown.Item>
            ) : (
              <>
                {download === "true" ? (
                  <Dropdown.Item>
                    <span onClick={() => ModelOn(rowName, Key, resType)}>
                      <img
                        className={`action_icons`}
                        src={downloadLink}
                        alt="Icon"
                      />
                      Download
                    </span>
                  </Dropdown.Item>
                ) : (
                  ""
                )}
              </>
            )}
            {deleteAction === "0" ? (
              <Dropdown.Item>
                <span
                  onClick={() => showDeleteModal("vegetable", 1)}
                  style={{ cursor: "pointer" }}
                >
                  <img className={`action_icons`} src={deleteIcon} alt="Icon" />
                  Delete
                </span>
              </Dropdown.Item>
            ) : (
              ""
            )}

            {SchedulerCalenderView && showSchedulerImage && (
              <Dropdown.Item>
                <span onClick={handleShowModal}>
                  <img
                    className={`action_icons`}
                    src={Schedulerblue}
                    alt="Icon"
                    style={{ cursor: "pointer", width: "20px", height: "20px" }}
                    data-bs-toggle="tooltip"
                    title="Set Schedule"
                  />
                  Scheduler
                </span>
              </Dropdown.Item>
            )}
          </Dropdown.Menu>
        </Dropdown>
      </div>
      <DeleteConfirmation
        showModal={displayConfirmationModal}
        confirmModal={submitDelete}
        hideModal={hideConfirmationModal}
        message={notetitle}
        type={rowType}
        id={rowId}
      />
      <Editnote
        showModal={displayModal}
        handleClose={handleClose}
        notetitle={notetitle}
        notedescription={notedescription}
        message={rowName}
        type={rowType}
      />
      <Viewdoc
        showModal={show}
        handleCloseShow={handleCloseShow}
        link_url={link_url}
        filetype={filetype}
        filelinkUrl={filelinkUrl}
        fileName={fileName}
      />
      <Promsschudle
        openModal={displayConfirmationModal2}
        setDisplayConfirmationModal={setDisplayConfirmationModal2}
        type={String(formid)}
        rowId={rowId}
        patient_akid={rowOrgId}
        org_id={orgId}
        form_status={form_status}
      />
      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        dialogClassName="patient_notes_popup"
        centered
      >
        <Modal.Header className=" close-buttonM">
          <div className="d-flex justify-content-between align-items-center w-100">
            <Modal.Title className="text-right">
              <strong>{"Set PROMs Schedule"}</strong>
            </Modal.Title>
            <button className="close-button" onClick={handleCloseModal}>
              &times;
            </button>
          </div>
        </Modal.Header>
        <Modal.Body className="">
          <div className="">
            <div
              className="p-2"
              style={{
                backgroundColor: "rgb(243, 245, 255)",
                borderRadius: 10,
              }}
            >
              <div className="col-12">
                <SmallProfilescheduler
                  fullname={fullname}
                  tAge={tAge}
                  rowId={rowId}
                  showModal={showModal}
                />
              </div>
              <div className="d-flex justify-content-between mb-2"></div>
            </div>
          </div>
          <br />

          <div
            className="whitebox"
            style={{
              border: "1px solid #3f2783",
              // height: "200px",
              // width: "100%",
            }}
          >
            <div className="col-12">
              <label className="form-label">Select Specialty</label>
              <CreatableSelect
                isClearable
                isMulti
                value={Selectspec}
                onChange={HandleSelectspec}
                options={selectspecialty}
                className="multi_select_drp1"
                isSearchable={false}
              />
            </div>

            {Selectspec && Selectspec.length > 0 && (
              <>
                {isLoading ? (
                  <div className="d-flex justify-content-center text-primary">
                    <div className="spinner-border mt-20" role="status">
                      <span className="sr-only"></span>
                    </div>
                  </div>
                ) : (
                  <>
                    {schedulerForm?.length > 0 ? (
                      <div className="whitebox" style={{ border: "1px solid black" }}>
                        <label>
                          <b>Please choose PROMs schedule</b>
                        </label>
                        <div className="scrollbar_schedular" style={{ height: "200px" }}>
                          <AddModelTable
                            data={schedulerForm}
                            submitButtonRef={submitButtonRef}
                            updateCheckboxValues={updateCheckboxValues}
                            onCheckboxChange={handleCheckboxChange}
                          />
                        </div>
                      </div>
                    ) : (
                      <>
                        {!isLoading && schedulerForm?.length === 0 && (
                          <p style={{ textAlign: "center", fontWeight: "bold", color: "rgba(0, 0, 0, 0.5)" }}>
                            No data found
                          </p>
                        )}
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </div>

          <div className="text-center">
            {schedulerForm?.length > 0 && (
              <>
                <Button
                  onClick={(showModals) => checkShedule()}
                  value="Submit"
                  buttonStyle="btn_outline ms-2 mb-3 "
                  width="400px"
                  height={"50px"}
                  disabled={isApiPending}
                />
              </>
            )}

            <br />
            {assignErr && (
              <>
                {checkboxValues.length === 0 && (
                  <span className="text-danger">
                    Please select atleast one schedule or close.
                  </span>
                )}
              </>
            )}
            <p>Can't find appropriate PROMs Schedule to link?</p>
            <label onClick={handleOpenModal} className="c_pointer">
              <u>
                <b>Create a New PROMs Schedule</b>
              </u>
            </label>
          </div>
        </Modal.Body>
      </Modal>
      {showModal1 && (
        <WidgetScheduler
          showModal1={showModal1}
          setshowModal={setshowModal}
          setshowModal1={setshowModal1}
          PostOpNoteshow={PostOpNoteshow}
          fullname={fullname}
          tAge={tAge}
          popupshow="true"
          rowOrgId={rowOrgId}
          rowId={rowId}
          type="postopnote"
          resourceID={resource_Id}
          onSave={handleSave}
          ppShow={"true"}
          type2="postopnote2"
          side={side}
          pop="false"
          setgetOpNotecall={setgetOpNotecall}
          usedOpNote={"usedOpNote"}
        />
      )}
      {assignCheck.length !== 0 ? (
        <Modal
          show={showModals}
          // show={true}
          onHide={handleCloseModals}
          backdrop="static"
          keyboard={false}
          dialogClassName="modal-lg patient_notes_popup modal-popup modal-dialog-centered"
        >
          <Modal.Header closeButton>
            <Modal.Title className="text-left pe-5">
              <div className="fw-bold">

                This patient has an existing  {spe == 4 ? "Shoulder" : spe == 6 ? "Hip" : spe == 21 ? "Foot and Ankle" : spe == 22 ? "Elbow" : spe == 5 ? "Knee" : spe == 23 ? "Hand and Wrists" : spe == 33 ? "General" : ""} PROMs Schedule.Do you want to replace it with the one you've created?

              </div>
            </Modal.Title>
          </Modal.Header>
          <Modal.Body className="">
            <div className="col-12 proms_Scheduler">
              <div className="row" style={{ flexDirection: "row" }}>
                <div style={{ width: '30%', }}>
                  <span>
                    <strong>Title : </strong>
                  </span>
                </div>
                <div className="col">
                  {assignCheck && assignCheck.length > 0 ? (
                    <>
                      {Array.isArray(assignCheck) && assignCheck.length > 0 ? (
                        <>
                          {assignCheck.map((item, index) => (
                            <>
                              {item.label}
                              {index !== assignCheck.length - 1 && ", "}
                            </>
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="col-12 proms_Scheduler">
              <div className="row">
                <div style={{ width: '30%', }}>
                  <span>
                    <strong>Treatment Side : </strong>
                  </span>
                </div>
                <div className="col">
                  {Array.isArray(assignCheck) && assignCheck?.length > 0 ? (
                    <>
                      {assignCheck.map((item, index) =>
                        item.side.map((val) => {
                          if (val === "1") set.add("Right");
                          if (val === "2") set.add("Left");
                        })
                      )}
                      {Array.from(set).join(",")}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

            <div className="col-12 proms_Scheduler">
              <div className="row">
                <div style={{ width: '30%', }}>
                  <span>
                    <strong>Primary Diagnosis : </strong>
                  </span>
                </div>
                <div className="col">
                  {Array.isArray(assignCheck) && assignCheck.length > 0 ? (
                    <>
                      {assignCheck.map((item, index) => (
                        <>
                          {item.primary_diagnosis}
                          {index !== assignCheck.length - 1 && ", "}
                        </>
                      ))}
                    </>
                  ) : (
                    ""
                  )}
                </div>
              </div>
            </div>

          </Modal.Body>
          <Modal.Footer
            style={{
              display: "flex",
              justifyContent: "center",
              gap: "10px",
            }}
          >
            <Button
              onClick={handleCancel}
              value="Cancel"
              buttonStyle="btn_outline ms-2 mb-3 "
              width="170px"
              height={"40px"}
            />
            <Button
              onClick={handleKeep}
              value="keep Both"
              buttonStyle="btn_outline ms-2 mb-3 "
              width="170px"
              height={"40px"}
            />
            <Button
              onClick={handleReplace}
              value="Replace"
              buttonStyle="btn_outline ms-2 mb-3 "
              width="170px"
              height={"40px"}
            />
            {/* <span
            className="btnpp_fill"
            style={{ cursor: "pointer" }}>Discontinue existing and apply new schedule</span> */}
          </Modal.Footer>
        </Modal>
      ) : (
        ""
      )}
    </>
  );
};

TableActionItems.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  label: PropTypes.string,
  data: PropTypes.array,
  styleClass: PropTypes.string,
  onChange: PropTypes.func,
};

TableActionItems.defaultProps = {
  value: "",
  label: "",
  styleClass: "",
  placeholder: "",
  rowData: "",
  viewAction: "",
  linkAction: "",
  searchAction: "",
  editAction: "",
  feedbackAction: "",
  deleteAction: "",
  arrowAction: "",
  rowName: "",
  rowId: "",
  rowType: "",
};

export default TableActionItems;

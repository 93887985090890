import React from "react";
// import ReactPlayer from "react-player";
const VideoPlayer = ({ url }) => {
  // const [state, setPlayed] = useState();
  return (
    <div className="mt-3">
      {url !== "" ? (
        <div>
          <video width="100%" controls autoplay controlsList="nodownload">
            <source src={url} type="video/mp4" />
            <source src="path/to/video.webm" type="video/webm" />
            Your browser does not support the video tag.
          </video>
        </div>
      ) : (
        "loading..."
      )}
    </div>
  );
};

export default VideoPlayer;

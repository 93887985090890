import React, {  useState, useEffect } from "react";
import { Form } from "react-bootstrap";
import { Link } from "react-router-dom";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import Tabs from "react-bootstrap/Tabs";
import Tab from "react-bootstrap/Tab";
import Button from "../custom/button/Button";
import InputField from "../custom/inputfield/InputField";
import Dropdown from "../custom/dropdown/Dropdown";
// import { Validators } from "../../utilities/Validator";
import SortDropdown from "../custom/sortdropdown/SortDropdown";
import AllCases from "./AllCases";
import SubmittedCases from "./SubmittedCases";
import PendingCases from "./PendingCases";
import CompleteCases from "./CompleteCases";
import WorkingCases from "./WorkingCases";
import LoadingSpinner from "../custom/loader/LoadingSpinner";
import axios from "axios";
// const baseURL = "https://gorest.co.in/public/v2/users";

function CasesList() {
  const [key, setKey] = useState("allcases");
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [firstName, setFrstName] = useState("");
  const [email, setEmail] = useState("");
  const [status, setStatus] = useState("");
  const [startDate, setStartDate] = useState(new Date());

  const handleSubmit = () => {
   
  };
  const advsearchToFirstName = (event) => {
    setFrstName(event);
  };
  const advsearchToMail = (event) => {
    setEmail(event);
  };
  const advsearchToStatus = (event) => {
    setStatus(event);
  };

  const handleSelect = (key) => {
    if (key === "allcases") {
      setKey(key);
      // getTabData("");
    } else if (key === "submitted") {
      setKey(key);
      // getTabData("");
    } else if (key === "pending") {
      setKey(key);
      // getTabData("");
    } else if (key === "working") {
      setKey(key);
      // getTabData("");
    } else if (key === "complete") {
      setKey(key);
      // getTabData("");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
    const getData = async () => {
      const response = await axios.get("https://gorest.co.in/public/v2/users");
      setData(response.data);
    };
    getData();
  }, []);

  const addMoreData = async () => {
    const response = await axios.get("https://gorest.co.in/public/v2/users");
    const resdata = response.data;
    setTimeout(() => {
      setData(data.concat(resdata));
    }, 500);
  };
  const fetchMoreData = () => {
    addMoreData();
  };

  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <div className="cases__list">
          <div className="row page_header">
            <div className="col-sm-6 col-md-6">
              <h4 className="ph_title">Cases List</h4>
            </div>
            <div className="col-sm-6 col-md-6 text-end">
              <Link to="/start-new-case" className={`btn_outline new__note_btn`}>
                New Case
              </Link>
            </div>
          </div>
          <div className="panel pt_30">
            <div className="adv_search_sec">
              <h4 className="mb_30">Advanced Search</h4>
              <form onSubmit={handleSubmit}>
                <div className="row">
                  <div className="col-sm-6 col-md-3">
                    <InputField
                      label="Patient Name"
                      value={firstName}
                      type="text"
                      placeholder="Enter Patient First Name"
                      onChange={advsearchToFirstName}
                    />
                    <InputField
                      label=""
                      value={email}
                      type="text"
                      placeholder="Enter Patient Last Name"
                      onChange={(e) => advsearchToMail(e)}
                    />
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <Dropdown
                      data={[
                        { value: "active", label: "Active" },
                        { value: "inactive", label: "Inactive" },
                      ]}
                      label="Status"
                      styleClass=""
                      value={status}
                      placeholder="All Cases"
                      onChange={(e) => advsearchToStatus(e)}
                    />
                  </div>
                  <div className="col-sm-6 col-md-3">
                    <Form.Group className="mb-3" controlId="fromDate">
                      <Form.Label>Date</Form.Label>
                      <DatePicker
                        className="form-control mb_0"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </Form.Group>
                    <Form.Group className="mb-3" controlId="toDate">
                      <DatePicker
                        className="form-control"
                        selected={startDate}
                        onChange={(date) => setStartDate(date)}
                      />
                    </Form.Group>
                  </div>
                  <div className="col-sm-6 col-md-3 pt_30">
                    <Button
                      onClick={() => console.log("You clicked on the button")}
                      value="Reset"
                      buttonStyle="btn_nill mr_20"
                    />
                    <Button
                      onClick={() => console.log("You clicked on the button")}
                      value="Submit"
                      buttonStyle="btn_fill"
                    />
                  </div>
                </div>
              </form>
            </div>
            <hr />
            <div className="row">
              <div className="col-sm-6 col-md-6">
                <h4 className="mb_30 ml_25">List of Cases</h4>
              </div>
              <div className="col-sm-6 col-md-6">
                <SortDropdown />
              </div>
            </div>
            <Tabs
              id="case-list-tabs"
              activeKey={key}
              onSelect={handleSelect}
              // onSelect={(k) => setKey(k)}
              className="mb-1"
            >
              <Tab eventKey="allcases" title="AllCases">
                <AllCases data={data} updatePagination={fetchMoreData} />
              </Tab>
              <Tab eventKey="submitted" title="Submitted">
                <SubmittedCases data={data} updatePagination={fetchMoreData} />
              </Tab>
              <Tab eventKey="pending" title="Pending">
                <PendingCases data={data} updatePagination={fetchMoreData} />
              </Tab>
              <Tab eventKey="working" title="Working">
                <WorkingCases data={data} updatePagination={fetchMoreData} />
              </Tab>
              <Tab eventKey="complete" title="Complete">
                <CompleteCases data={data} updatePagination={fetchMoreData} />
              </Tab>
            </Tabs>
          </div>
        </div>
      )}
    </>
  );
}

export default CasesList;


import React, { useState } from "react";
import { useTable } from "react-table";
import { useSelector } from "react-redux";
import { useSearchParams } from "react-router-dom";

export default function AddModelTable({
  data,
  updateCheckboxValues,
}) {
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const [doctorID] = useState(login.ing_ak_id);
  const [searchParams] = useSearchParams();
  let org = searchParams.get("org");
  const [check, setCheck] = useState(false);

  const handleCheckboxChange = (resource_id, event,specialitytype) => {
    const { name, checked } = event.target;
    updateCheckboxValues({ resource_id, name, checked ,specialitytype});
  };

  const columns = React.useMemo(
    () => [
      {
        Header: "Title",
        accessor: "title",
        Cell: (row) => (
          <label className="text-black f_500 pt-3 ps-2" data-bs-toggle="tooltip"
            data-bs-placement="top"
            title={row.row.original.title} style={{
              paddingRight: "14px",
              width: "auto",
              overflow: "visible",
              // textOverflow: "ellipsis",
              whiteSpace: "normal",
              wordWrap: "break-word",
            }} > <span className=""></span>
            <strong>{row.row.original.title}</strong>
          </label>
        ),
      },

      {
        Header: "Speciality",
        accessor: "speciality",
        Cell: (row) => (
          <span className="text-black pt-3">
            {row.row.original.speciality === "5"
              ? "knee"
              : row.row.original.speciality === "4"
                ? "shoulder"
                : row.row.original.speciality === "6"
                  ? "Hip"
                  : row.row.original.speciality === "21"
                    ? "Foot and ankle"
                    : row.row.original.speciality === "22"
                      ? "Elbow"
                      : row.row.original.speciality === "23"
                        ? "Hand and Wrist"
                        : row.row.original.speciality === "33"
                          ? "General"
                          : ""}
                          {row.row.original.treatment_category && ` (${row.row.original.treatment_category})`}
          </span>
        ),
      },

      {
        Header: "Checkbox",
        accessor: "checkbox",
        Cell: (row) => (
          <>
            <div
              className={`form-group custom_check_box ${check ? "tick" : ""
                } text-black`}
            >
              <input
                type="checkbox"
                value={check}
                name="checked"
                id={row.row.original.resource_id}
                onChange={(event) =>
                  handleCheckboxChange(row.row.original.resource_id, event,row.row.original.speciality)
                }
              />
              <label key={row.row.original.resource_id}
                className="me-4"
                id={row.row.original.resource_id}
                for={row.row.original.resource_id} > </label>
            </div>
          </>
        ),
      },
    ],
    []
  );

  const { getTableProps, getTableBodyProps, rows, prepareRow } =
    useTable({
      columns,
      data,
    });
  return (
    <div className=" " id="ripScrollableDiv">
      <table {...getTableProps()} className="table-borderless">
        <tbody {...getTableBodyProps()}>
          {rows.map((row) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} className="align-items-center">
                <td>
                  <span className="circle"></span>
                </td>
                {row.cells.map((cell) => (
                  <td
                    {...cell.getCellProps([
                      { className: cell.column.className },
                    ])}
                  >
                    {cell.render("Cell")}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
}
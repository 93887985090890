import React from "react";
import { useTable } from "react-table";
import InfiniteScroll from "react-infinite-scroll-component";
import TableSpinner from "../custom/spinner/TableSpinner";
import TableActionItems from "../custom/table-action-items/TableActionItems";
// import axios from "axios";
import "./Cases.css";

function SubmittedCases({ data, updatePagination }) {
  // function handleEdit(row) {
  //   console.log(row);
  //   // display modal
  //   // say user types in modal new firstName
  //   // post request

  //   // set row.firstName = newFirstName
  // }
  const actionData = {
    view_url: "view-case",
    edit_url: "",
    mark_url: "",
    delete_url: ""
  }
  const columns = React.useMemo(
    () => [
      {
        Header: "",
        id: "1",
        headerClassName: "col-img",
        className: "col-img",
        accessor: "name",
        Cell: (row) => (
          <span>
            <label className="user-letter small">
              {row.value.charAt(0).toUpperCase()}
            </label>
          </span>
        ),
      },
      {
        Header: "Name",
        accessor: "name",
      },
      {
        Header: "Email",
        accessor: "email",
      },
      {
        Header: "Gender",
        accessor: "gender",
      },
      {
        Header: "Status",
        accessor: "status",
      },
      {
        Header: "Action",
        headerClassName: "col-action",
        accessor: "action",
        className: "col-action",
        Cell: (row) => (
          <span>
            {/* <button onClick={e=> handleEdit(row.row.original)}>Edit</button> */}
            <TableActionItems viewAction="true" editAction="true" feedbackAction="true" deleteAction="true"  arrowAction="true" rowData={actionData}/>
          </span>
        ),
      },
    ],
    [data]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  return (
    <>
      <div className="tableContainer" id="rpScrollableDiv">
        <InfiniteScroll
          dataLength={rows.length}
          next={updatePagination}
          hasMore={true}
          scrollableTarget="rpScrollableDiv"
          loader={<TableSpinner />}
        >
          <table {...getTableProps()} className="custom-tbl">
            <thead>
              {headerGroups.map((headerGroup) => (
                <tr {...headerGroup.getHeaderGroupProps()}>
                  {headerGroup.headers.map((column) => (
                    <th
                      {...column.getHeaderProps([
                        { className: column.headerClassName },
                      ])}
                    >
                      {column.render("Header")}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody {...getTableBodyProps()}>
              {rows.map((row) => {
                prepareRow(row);
                return (
                  <tr {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps([
                            { className: cell.column.className },
                          ])}
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          </table>
        </InfiniteScroll>
      </div>
    </>
  );
}

export default SubmittedCases;

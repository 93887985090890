import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import useAxios from "../../../axiosinstance";
import { useSelector } from "react-redux";
import useTranslation from "../../customHooks/translations";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
import Dropdown from "../../custom/dropdown/Dropdown";
import PatientListForModel from "./PatientListForModel";
import Button from "../../custom/button/Button";
import Message from "../../custom/toster/Message";
import InputField from "../../custom/inputfield/InputField";
import CreatableSelect from "react-select/creatable";
import MultiSelect from "./MultiSelect";
export default function ViewallModel({ modelOpen, setShowModal, viewdata }) {
  const [rule, setRule] = useState("");
  const [org, setOrg] = useState("");
  const handleClose = () => {
    setShowModal(false);
  };
  return (
    <>
      <Modal
        show={modelOpen}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-lg patient_notes_popup modal-dialog-centered"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">View</Modal.Title>

        </Modal.Header>
        <Modal.Body className="">
          <div className="p-3">
            <div className="row">
              <div className="col-sm-6 col-md-6 mb_20">
                <InputField
                  label="Rule Title"
                  value={viewdata.rule_name}
                  type="text"
                  placeholder=""
                  // onChange={handleRule}
                  styleClass="br_10 "
                  disabled={true}

                />
              </div>
              <div className="col-sm-6 col-md-6 ">
                <InputField
                  label="Sharing Organization"
                  value={viewdata.org_name}
                  type="text"
                  placeholder=""
                  // onChange={handleRule}
                  styleClass="br_10 "
                  disabled={true}
                />
                {/* <Dropdown
                    data={options}
                    label="Choose Organization*"
                    value={org}
                    // onChange={handleOrg}
                    styleClass="br_10 mxw_100 mb_20"
                    placeholder="Select"
                    
                  /> */}
              </div>
            </div>
            <div className="row">

              <div className="col-sm-6 col-md-6 ">
                <label className="form-label">
                  Resource type to share with
                </label>
                <MultiSelect
                  user_data={viewdata.allResources}
                  viewall="true"

                />
              </div>
              <div className="col-sm-6 col-md-6 ">
                <label className="form-label">HCP(s) to share with</label>
                <MultiSelect
                  user_data={viewdata.hcp_ids}
                  viewall="true"
                />
              </div>
            </div>
          </div>

        </Modal.Body>
      </Modal>
    </>
  )
}

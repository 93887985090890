const initialState = {
  painScore: [
    {
      elbowPainR: "0",
      elbowPainL: "0",
      decElbowPainR: "4",
      decElbowPainL: "4",
      rateElbow: "0",
      rateElbowL: "0",
      oftenR: "0",
      oftenL: "0",
      troubledL: "0",
      troubledR: "0",
      rateWorst: "0",
      rateWorstL: "0",
      rateRest: "0",
      rateRestL: "0",
      rateObj: "0",
      rateObjL: "0",
      rateMovements: "0",
      rateMovementsL: "0",
      rateNight: "0",
      rateNightL: "0",
      moment: "0",
      momentL: "0"
    },
  ],
  instabilityScore: [
    {
      ankleFeelL: "0",
      ankleFeelR: "0",
    },
  ],


  functionScore: [
    {
      funDescR: "5",
      funDescL: "5",
      funActivityR: "4",
      funActivityL: "4",
      funUselongR: "3",
      funUselongL: "3",
      tightR: "0",
      tightL: "0",
      // funHouseholdR: "0",
      // funHouseholdL: "0",
      funshoppingL: "0",
      funshoppingR: "0",
      funrubbishR: "0",
      funrubbishL: "0",
      funWashR: "0",
      funWashL: "0",
      funToiletinghR: "0",
      funToiletinghL: "0",
      funWashingR: "0",
      funWashingL: "0",
      funHygienegR: "0",
      funHygienegL: "0",
      funKnifeR: "0",
      funKnifeL: "0",
      funUtensilR: "0",
      funUtensilL: "0",
      funRecreationalR: "0",
      funRecreationalL: "0",
      funSleepingL: "0",
      funShirtR: "0",
      funShirtL: "0",
      funTopShirtR: "0",
      funTopShirtL: "0",
      funSleepingR: "0",
      funDressingR: "0",
      funDressingL: "0",
      funPutShoesR: "0",
      funPutShoesL: "0",
      funTieShoesR: "0",
      funTieShoesL: "0",
      funHeavyR: "0",
      funHeavyL: "0",
      funChairR: "0",
      funChairL: "0",
      householdR: "0",
      householdL: "0",
      funTurnR: "0",
      funTurnL: "0",
      funBallR: "0",
      funBallL: "0",
      funWorkR: "0",
      funWorkL: "0",
      funTechniqueR: "0",
      funTechniqueL: "0",
      funSpendingR: "0",
      funSpendingL: "0",
      funMusicalR: "0",
      funMusicalL: "0",
      funInstrumentR: "0",
      funInstrumentL: "0",
      funPlayingR: "0",
      funPlayingL: "0",
      funPractisingR: "0",
      funPractisingL: "0",
      funWorkAsWallR: "0",
      funWorkAsWallL: "0",
      funHairR: "0",
      funHairL: "0",
      funLeisureR: "0",
      funLeisureL: "0",
      funRegularR: "0",
      funRegularL: "0",
      funTextR: "",
      funTextL: "",
      checkR: "",
      checkL: "",
      funMusicaTextR: "",
      funMusicaTextL: "",
      checkMusicaL: "",
      checkMusicaR: "",
    },
  ],
  qolScore: [
    {
      elbowlifeR: "0",
      elbowlifeL: "0",
      elbowDuringL: "0",
      elbowDuringR: "0",
      problemR: "0",
      washingR: "0",
      activitiesR: "0",
      discomfortR: "0",
      anxietyR: "0",
      percentage: "0",
    },
  ],
  symptomsScore: [
    {
      HipPer: "100",
      HipPerL: "100",
      armR: "0",
      armL: "0",
      TinglingR: "0",
      TinglingL: "0",
    },
  ],

  satisfactionScore: [
    {
      rateElbowSatisfactionL: "",
      rateElbowSatisfactionR: "",
      applicableR: false,
      applicableL: false,
    }
  ],

  TotalScores: [
    {
      QuickDASHDisabilityRFSum: 0,
      QuickDASHDisabilityLFSum: 0,
      QuickDashWorkRFSum: 0,
      QuickDashWorkLFSum: 0,
      QuickDashSportRFSum: 0,
      QuickDashSportLFSum: 0,
      AsesPainSumF: 0,
      AsesPainSumFL: 0,
      AsesFunctionRFSum: 0,
      AsesFunctionLFSum: 0,
      AsesRFSum: 0,
      AsesLFSum: 0,
      MepsRFSum: 0,
      MepsLFSum: 0,
      HssElbowPainRSum: 0,
      HssElbowPainLSum: 0,
      OesPainRFSum: 0,
      OesPainLFSum: 0,
      OesFunRFSum: 0,
      OesFunLFSum: 0,
      OesSocialRFSum: 0,
      OesSocialLFSum: 0,
      VASRateElbowF: 0,
      VASRateElbowFL: 0,
      HipperT: 0,
      HipperTL: 0,
      EQproblem: 0,

      EQwashing: 0,

      EQactivities: 0,

      EQdiscomfort: 0,

      EQanxiety: 0,

      EQpercentage: 0,
    },
  ],
  doctorFeedback: [
    {
      RightFlexionR: null,
      RightFlexionL: null,
      LeftFlexionR: null,
      LeftFlexionL: null,
      RightPronationR: null,
      RightPronationL: null,
      LeftPronationR: null,
      LeftPronationL: null,
      RightSupinationR: null,
      RightSupinationL: null,
      LeftSupinationR: null,
      LeftSupinationL: null,
      RightPronationSupinationarcR: null,
      RightPronationSupinationarcL: null,
      LeftPronationSupinationarcR: null,
      LeftPronationSupinationarcL: null,
      ValgusR: "",
      ValgusL: "",
      VarusR: "",
      VarusL: "",
      PosterolateralR: "",
      PosterolateralL: "",
      flexionlR: "",
      flexionlL: "",
      ExtensionR: "",
      ExtensionL: "",
      PronationR: "",
      PronationL: "",
      SupinationR: "",
      SupinationL: "",
      strengthR: null,
      strengthL: null,
      elbowPainR: "",
      elbowPainL: "",
      ulnohumeralR: "",
      ulnohumeralL: "",
      RadiocapitellarR: "",
      RadiocapitellarL: "",
      MedialR: "",
      MedialL: "",
      LateralR: "",
      LateralL: "",
      MCLR: "",
      MCLL: "",
      PINR: "",
      PINL: "",
      OtherR: "",
      OtherL: "",
      otherText: "",
      otherTextL: "",
      otherFindingsR: "",
      otherFindingsL: "",
      ImpingementR: "",
      ImpingementL: "",
      ImpingementPainR: "",
      ImpingementPainL: "",
      wristR: "",
      wristL: "",
      wristfR: "",
      wristfL: "",
      fingerR: "",
      fingerL: "",
      fingerFR: "",
      fingerFL: "",
      wpronationR: "",
      wpronationL: "",
      wSupinationR: "",
      wSupinationL: "",
      crepitusR: "",
      crepitusL: "",
      RadiocapitellarCrR: "",
      RadiocapitellarCrL: "",
      DeformityR: "",
      DeformityL: "",
      scarsR: "",
      scarsL: "",
      scarsTextR: "",
      scarsTextL: "",
      AtrophyR: "",
      AtrophyL: "",
      AtrophyTextR: "",
      AtrophyTextL: "",
      contractureR: "",
      contracture1R: "",
      contracture2R: "",
      contracture3R: "",
      otherfieldL: "",
      otherfieldR: "",
      contractureL: "",
      contracture1L: "",
      contracture2L: "",
      contracture3L: "",
      TinelsR: "",
      TinelsL: "",
      StretchR: "",
      StretchL: "",
      FromentR: "",
      FromentL: "",
      JeanneR: "",
      JeanneL: "",
      WartenbergR: "",
      WartenbergL: "",
      MasseR: "",
      MasseL: "",
      ir4Right: "",
      ir4Left: "",
      rightpspecify: "",
      rightaspecify: "",
      leftpspecify: "",
      leftaspecify: "",
      OtherjointsLimitsL: "",
      OtherjointsLimitwL: "",
      OtherjointsLimitsR: "",
      OtherjointsLimitwR: "",
      shoulderinputL: "",
      WristinputL: "",
      shoulderinputR: "",
      wristinputR: "",

    }
  ],
  elbowPain: "1",
  elbowArea: "",
};

const elbowReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_ELBOW_DATA":
      return { ...state, elbowPain: action.data };

    case "UPDATE_ELBOW_PAIN_FIELD_DATA":
      const painFieldVal = action.data.value;
      const painFieldKey = action.data.key;
      const painNewArr = state.painScore.map((object) => {
        return { ...object, [painFieldKey]: painFieldVal };
      });
      return { ...state, painScore: painNewArr };

    case "UPDATE_ELBOW_PAIN_DATA":
      return { ...state, painScore: action.data };

    case "UPDATE_ELBOW_FUNCTION_FIELD_DATA":
      const painFieldVal1 = action.data.value;
      const painFieldKey1 = action.data.key;
      const painNewArr1 = state.functionScore.map((object) => {
        return { ...object, [painFieldKey1]: painFieldVal1 };
      });
      return { ...state, functionScore: painNewArr1 };

    case "UPDATE_ELBOW_FUNCTION_DATA":
      return { ...state, functionScore: action.data };

    case "UPDATE_ELBOW_SYMPTOMS_FIELD_DATA":
      const symptomsFieldVal1 = action.data.value;
      const symptomsFieldKey1 = action.data.key;
      const symptomsNewArr1 = state.symptomsScore.map((object) => {
        return { ...object, [symptomsFieldKey1]: symptomsFieldVal1 };
      });
      return { ...state, symptomsScore: symptomsNewArr1 };

    case "UPDATE_ELBOW_SYMPTOMS_DATA":
      return { ...state, symptomsScore: action.data };

    case "UPDATE_ELBOW_QOL_FIELD_DATA":
      const qolFieldVal1 = action.data.value;
      const qolFieldKey1 = action.data.key;
      const qolNewArr1 = state.qolScore.map((object) => {
        return { ...object, [qolFieldKey1]: qolFieldVal1 };
      });
      return { ...state, qolScore: qolNewArr1 };

    case "UPDATE_ELBOW_QOL_DATA":
      return { ...state, qolScore: action.data };

    case "UPDATE_ELBOW_SATISFACTION_FIELD_DATA":
      const satiFieldVal1 = action.data.value;
      const satiFieldKey1 = action.data.key;
      const satiNewArr1 = state.satisfactionScore.map((object) => {
        return { ...object, [satiFieldKey1]: satiFieldVal1 };
      });
      return { ...state, satisfactionScore: satiNewArr1 };

    case "UPDATE_ELBOW_SATISFACTION_DATA":
      return { ...state, satisfactionScore: action.data };

    case 'UPDATE_ELBOW_TOTAL_SCORE':
      return { ...state, TotalScores: action.data };

    case 'UPDATE_ELBOW_FEEDBACK_SCORE':
      return { ...state, doctorFeedback: action.data };

    case 'UPDATE_ELBOW_FEEDBACK_FIELD_DATA':
      const satf = action.data.value;
      const satK = action.data.key;
      const satNewarr = state.doctorFeedback.map((object) => {
        return { ...object, [satK]: satf };
      });
      return { ...state, doctorFeedback: satNewarr };
    default:
      return state;
  }
};
export default elbowReducer;

/* eslint-disable eqeqeq */
import React, { useState, useEffect, lazy, Suspense } from "react";
import { Routes, Route, useLocation, Navigate } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import "./App.css";
import "./css/style.css";

// const Header = lazy(() => import("../Component/Header"));
import { useDispatch, useSelector } from "react-redux";
import LeftSidebar from "./components/header/LeftSidebar";
import TopHeader from "./components/header/TopHeader";
import Notifications from "./components/Notifications/Notifications";
import useAxios from "./axiosinstance";
import { fetchUserIdSuccess } from "./redux/actions/index";

import { loginData } from "./redux/actions/index";
// import TopHeaderBefore from "./components/header/TopHeaderBefore";
import CasesList from "./components/cases/CasesList";
import Unauthorized from "./components/unauthorizedpage/Unauthorized";
import AuditTool from "./components/AuditTool/AuditTool";
import CompleteDataExport from "./components/data-export/CompleteDataExport";
import Reports from "./components/data-export/Reports";
import DataExportTableNew from "./components/data-export/DataExportTableNew";

const AuditToolTable = lazy(() => import("./components/AuditTool/AuditToolTable"))
const AkunahAnalyse = lazy(() => import("./components/custom/akunah-analyse/AkunahAnalyse"));
const MySetting = lazy(() => import("./components/settings/MySetting"));
const Schedule = lazy(() => import("./components/patients/Schedule"));
const ResearchDetails = lazy(() => import("./components/operative-notes/ResearchDetails"));
const Referrals = lazy(() => import("./components/referrals/Referrals"));
const Dashboard = lazy(() => import("./components/dashboard/Dashboard"));
const PatientsList = lazy(() => import("./components/patients/PatientsList"));

const Consents = lazy(() => import("./components/my-consents/Consents"));

const MermaidRadiology = lazy(() =>
  import("./components/templete-maker/provider/mermaid/MermaidRadiology")
);

const TreatementDetails = lazy(() =>
  import("./components/operative-notes/TreatmentDetails")
);

const ManagementPlanList = lazy(() =>
  import("./components/management-plans/ManagementPlanList")
);
const ManagementPlan = lazy(() =>
  import("./components/management-plans/management-plan/ManagementPlan")
);
const ManagementPlanView = lazy(() =>
  import("./components/management-plans/ManagementPlanView")
);
const ManagementPlanInitialView = lazy(() =>
  import("./components/management-plans/ManagementPlanInitialView")
);
const OperativeNotesList = lazy(() =>
  import("./components/operative-notes/OperativeNotesList")
);
const NotFoundPage = lazy(() =>
  import("./components/custom/notfoundpage/NotFoundPage")
);
// const DataExport = lazy(() => import("./components/data-export/DataExport"));
// const StartNewCase = lazy(() => import("./components/cases/start-case/StartNewCase"));
// const CaseUpload = lazy(() => import("./components/cases/newcase-upload/CaseUpload"));
// const NewCaseReview = lazy(() => import("./components/cases/newcase-review/NewCaseReview"));
// const ViewCase = lazy(() => import("./components/cases/view-case/ViewCase"));
// const DicomReports = lazy(() => import("./components/cases/view-case/dicom-history/dicom-reports/DicomReports"));
// const ThreeDReports = lazy(() => import("./components/cases/view-case/3d-models/threed-reports/ThreeDReports"));
// const CaseThankYou = lazy(() => import("./components/cases/case-thank-you/CaseThankYou"));
const PatientDetails = lazy(() =>
  import("./components/patients/patient-details/PatientDetails")
);

const ViewTreatmentForm = lazy(() =>
  import("./components/management-plans/view-treatment-form/ViewTreatmentForm")
);
const PatientDemographic = lazy(() =>
  import("./components/patients/patient-demographic/PatientDemographic")
);
// import PatientAssessmentStep1 = lazy(() => import("./components/patients/patient-assessment-from/PatientAssessmentStep1"));
// import PatientAssessmentStep2 = lazy(() => import("./components/patients/patient-assessment-from/PatientAssessmentStep2"));
const ViewOperativeNotes = lazy(() =>
  import("./components/operative-notes/view-operative-notes/ViewOperativeNotes")
);
const AddOperativeNotes = lazy(() =>
  import("./components/operative-notes/add-operative-notes/AddOperativeNotes")
);
const PostOperativeNote = lazy(() =>
  import("./components/operative-notes/saved-notes/PostOperativeNote")
);
const DoctorRegistration = lazy(() =>
  import("./components/doctor-registration/DoctorRegistration")
);
const DoctorMap = lazy(() => import("./components/doctor-map/DoctorMap"));

const RoleCheck = lazy(() => import("./components/role/RoleCheck"));
const Message = lazy(() => import("./components/custom/toster/Message"));

const PatientView = lazy(() => import("./components/patients/PatientView"));
const DocumentList = lazy(() =>
  import("./components/document-list/DocumentList")
);
const DocumentListTable = lazy(() =>
  import("./components/document-list/DocumentListTable")
);
const AddDocument = lazy(() =>
  import("./components/document-list/add-document/AddDocument")
);
const NewDocument = lazy(() =>
  import("./components/document-list/new-document/NewDocument")
);
const EditDocument = lazy(() =>
  import("./components/document-list/edit-document/EditDocument")
);
const CompleteDocument = lazy(() =>
  import("./components/document-list/complete-document/CompleteDocument")
);
const PatientOperativeList = lazy(() =>
  import(
    "./components/patients/patient-operative-notes/patient-document-list/PatientOperativeList"
  )
);
const NotesDetails = lazy(() => import("./components/notes/NotesDetails"));
const PatientOrgList = lazy(() =>
  import("./components/patients/patient-organization/PatientOrgList")
);
const DoctorScoring = lazy(() =>
  import("./components/patients/doctor-score-form/DoctorScoring")
);
const Template = lazy(() => import("./components/patients/template/Template"));
const QueTemplate = lazy(() => import("./components/templete-maker/Template"));
const Pathology = lazy(() => import("./components/pathology/Pathology"));
const Chat = lazy(() => import("./components/patients/akunah-chat/Chat"));
const MyCalendar = lazy(() => import("./schedular/MyCalendar"));
const Worklog = lazy(() =>
  import("./components/templete-maker/Document/Worklog")
);
const Pathologys = lazy(() =>
  import("./components/templete-maker/Document/Pathology")
);
const PdfM = lazy(() => import("./components/document-list/pdf-generate/PdfM"));
const MainContent = lazy(() =>
  import("./components/patients/template/MainContent")
);
const ViewGenaralOPNotes = lazy(() =>
  import("./components/operative-notes/view-operative-notes/ViewGenaralOPNotes")
);
const RadiologyPage = lazy(() =>
  import("./components/radiology/RadiologyPage")
);
const TopHeaderdocmap = lazy(() =>
  import("./components/doctor-map/TopHeaderdocmap")
);
// const QueenslandRadiology = lazy(() => import("./components/templete-maker/provider/qrs/QueenslandRadiology"));
const QueenslandRadiologySpecialists = lazy(() =>
  import(
    "./components/templete-maker/provider/qrs/QueenslandRadiologySpecialists"
  )
);
// const AddHipOperativeNote = lazy(() => import("./components/operative-notes/hip-add-operative-note/AddHipOperativeNote"));
const AddKneeOperativeNotes = lazy(() =>
  import(
    "./components/operative-notes/knee-add-operative-notes/AddKneeOperativeNotes"
  )
);
const HipOpView = lazy(() =>
  import(
    "./components/operative-notes/view-operative-notes/hip-op-view/HipOpView"
  )
);
const KneeOpView = lazy(() =>
  import(
    "./components/operative-notes/view-operative-notes/knee-op-view/KneeOpView"
  )
);
const Arthroscopypage = lazy(() =>
  import("./components/operative-notes/hip-add-operative-note/Arthroscopypage")
);
const ArthoPlastyPage = lazy(() =>
  import("./components/operative-notes/hip-add-operative-note/ArthoPlastyPage")
);
const FractureSurgery = lazy(() =>
  import("./components/operative-notes/hip-add-operative-note/FractureSurgery")
);
const OthersHip = lazy(() =>
  import("./components/operative-notes/hip-add-operative-note/OthersHip")
);
const OtherHipSurgery = lazy(() =>
  import("./components/operative-notes/hip-add-operative-note/OtherHipSurgery")
);
const Arthiritis = lazy(() =>
  import("./components/operative-notes/knee-add-operative-notes/Arthiritis")
);
const Instability = lazy(() =>
  import("./components/operative-notes/knee-add-operative-notes/Instability")
);
// const MeniscalProcedure = lazy(() => import("./components/operative-notes/knee-add-operative-notes/MeniscalProcedure"));
const SepticKnee = lazy(() =>
  import("./components/operative-notes/knee-add-operative-notes/SepticKnee")
);
const Fracture = lazy(() =>
  import("./components/operative-notes/knee-add-operative-notes/Fracture")
);
const PatellarInstability = lazy(() =>
  import(
    "./components/operative-notes/knee-add-operative-notes/PatellarInstability"
  )
);
const RevisionArthroplasty = lazy(() =>
  import(
    "./components/operative-notes/knee-add-operative-notes/RevisionArthroplasty"
  )
);
const Osteonecrosis = lazy(() =>
  import("./components/operative-notes/knee-add-operative-notes/Osteonecrosis")
);
const ChondralInjury = lazy(() =>
  import("./components/operative-notes/knee-add-operative-notes/ChondralInjury")
);
const Meniscalinjury = lazy(() =>
  import("./components/operative-notes/knee-add-operative-notes/Meniscalinjury")
);
const OthersKnee = lazy(() =>
  import("./components/operative-notes/knee-add-operative-notes/OthersKnee")
);
const Gallery = lazy(() => import("./components/patients/template/Gallery"));
const TemplateCreate = lazy(() =>
  import("./components/patients/template/TemplateCreate")
);
const RequestView = lazy(() =>
  import("./components/patients/template/RequestView")
);
const Arthritis = lazy(() =>
  import("./components/operative-notes/elbow-add-operative-notes/Arthritis")
);
// const Instability = lazy(() => import("./components/operative-notes/elbow-add-operative-notes/Instability"));
const ElbowFracture = lazy(() =>
  import("./components/operative-notes/elbow-add-operative-notes/Fracture")
);
const FailedArthoplasty = lazy(() =>
  import(
    "./components/operative-notes/elbow-add-operative-notes/FailedArthoplasty"
  )
);
const Ligament = lazy(() =>
  import("./components/operative-notes/elbow-add-operative-notes/Ligament")
);
const NerveEntrapment = lazy(() =>
  import(
    "./components/operative-notes/elbow-add-operative-notes/NerveEntrapment"
  )
);
const OthersElbow = lazy(() =>
  import("./components/operative-notes/elbow-add-operative-notes/OthersElbow")
);
const ViewElbowNote = lazy(() =>
  import(
    "./components/operative-notes/view-operative-notes/elbow-op-view/ViewElbowNote"
  )
);
// const elbow = lazy(() => import("./components/operative-notes/hip-add-operative-note/AddHipOperativeNote"));
const AddHipOperativeNote = lazy(() =>
  import(
    "./components/operative-notes/hip-add-operative-note/AddHipOperativeNote"
  )
);
const InstabilityElbow = lazy(() =>
  import(
    "./components/operative-notes/elbow-add-operative-notes/InstabilityElbow"
  )
);
const DefaultPrescription = lazy(() =>
  import("./components/patients/template/DefaultPrescription")
);
const FootFracture = lazy(() =>
  import("./components/operative-notes/foot-ankle-op-note/Fracture")
);
const ArthritisFoot = lazy(() =>
  import("./components/operative-notes/foot-ankle-op-note/ArthritisFoot")
);
const CharcotFoot = lazy(() =>
  import("./components/operative-notes/foot-ankle-op-note/CharcotFoot")
);
const Amputation = lazy(() =>
  import("./components/operative-notes/foot-ankle-op-note/Amputation")
);
const FootArthroscopy = lazy(() =>
  import("./components/operative-notes/foot-ankle-op-note/Arthroscopy")
);
const InstabilityFoot = lazy(() =>
  import("./components/operative-notes/foot-ankle-op-note/InstabilityFoot")
);
const AchillesTendon = lazy(() =>
  import("./components/operative-notes/foot-ankle-op-note/AchillesTendon")
);
const AvascularNecrosis = lazy(() =>
  import("./components/operative-notes/foot-ankle-op-note/AvascularNecrosis")
);
const FootopView = lazy(() =>
  import(
    "./components/operative-notes/view-operative-notes/foot-ankle-op-view/FootopView"
  )
);
const Deformity = lazy(() =>
  import("./components/operative-notes/foot-ankle-op-note/Deformity")
);
// const Other = lazy(() => import("./components/operative-notes/op-note-custom/Other"));
const Others = lazy(() =>
  import("./components/operative-notes/foot-ankle-op-note/Others")
);
// const AchillestendonView = lazy(() => import("./components/operative-notes/view-operative-notes/foot-ankle-op-view/AchillestendonView"));
const HandFracture = lazy(() =>
  import("./components/operative-notes/hand-and-wrist-opnotes/Fracture")
);
const HandInstability = lazy(() =>
  import("./components/operative-notes/hand-and-wrist-opnotes/Instability")
);
const HandTendon = lazy(() =>
  import("./components/operative-notes/hand-and-wrist-opnotes/TendonInjury")
);
const HandCompression = lazy(() =>
  import(
    "./components/operative-notes/hand-and-wrist-opnotes/CompressionNeuropathy"
  )
);
const NerveInjury = lazy(() =>
  import("./components/operative-notes/hand-and-wrist-opnotes/NerveInjury")
);
const HandArthritis = lazy(() =>
  import("./components/operative-notes/hand-and-wrist-opnotes/Arthritis")
);
const WristOthers = lazy(() =>
  import("./components/operative-notes/hand-and-wrist-opnotes/WristOthers")
);
const MyDocument = lazy(() => import("./components/My-Document/MyDocument"));
const HandandWristOpView = lazy(() =>
  import(
    "./components/operative-notes/view-operative-notes/Hand-and-Wrist-op-View/HandandWristOpView"
  )
);

const LetterNew = lazy(() =>
  import("./components/patients/template/letter/LetterNew")
);
const DataExportadvance = lazy(() =>
  import("./components/data-export/DataExportadvance")
);

const AdvancedataExport = lazy(() => import("./components/data-export/AdvancedataExport"));

function AppRouter() {
  const [isOpen, setIsOpen] = useState(false);
  let akToken = localStorage.getItem("AkTOKEN");
  const navigate = useNavigate();
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState("");
  const location = useLocation();
  const { pathname } = location;
  const splitLocation = pathname.split("/");
  const axiosInstance = useAxios();
  const dispatch = useDispatch();
  const getData = async () => {
    try {
      const response = await axiosInstance.current.post(
        "extapp/doctors/doc_signin"
      );
      dispatch(fetchUserIdSuccess(response.data));
    } catch (error) {
      setMessage(error.message);
      setMessageType("error");
      // console.log(error.message);
    }
  };
  useEffect(() => {
    if (splitLocation[1] !== "doctor-map") {
      getData();
    }
  }, []);
  useEffect(() => {
    if (splitLocation[1] === "doctor-map") {
      getData();
    }
  }, [splitLocation[1]]);
  const redirectToError = () => {
    navigate("/login-error", { replace: true });
    // window.location.reload(false);
  };

  // const noteRef = useSelector((state) => state.mainReducer.noteRefresh);
  // const noteRef = useSelector((state) => state.mainReducer.noteRefresh?.arr);
  const notreff = useSelector((state) => state.mainReducer.noteRef);
  useEffect(() => {
    const getData1 = async () => {
      try {
        const result = await axiosInstance.current.get(
          `extapp/doctors/myprofile?ak_id=${akToken}`
        );
        if (result.status == "200") {
          if (result.data.data.ing_acc_status == 1) {
            dispatch(loginData(result.data.data));
          } else {
            redirectToError();
          }
        }
      } catch (err) {
        // setMessage("Unauthorized Access  " || "Unexpected Error!");
        // setMessageType("error");
        localStorage.removeItem("AkTOKEN");
      } finally {
        console.log("api response finally");

      }
    };
    if (akToken !== null) {
      getData1();
    }
  }, [notreff]);
  const user = useSelector((state) => state.mainReducer.userDetails.arr);
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  return (
    <>
      {" "}
      <Suspense fallback={""}>
        {message && <Message message={message} type={messageType} />}
        {login != "" && user.isEmail === true && akToken !== null && (
          <div className="" style={{ height: "100%" }}>
            <div className={"App " + (isOpen ? "sidebar-collapsed" : "")}>
              {splitLocation[1] === "shoulder-treatment-form" ? (
                " "
              ) : (
                <>
                  <TopHeader />
                  <LeftSidebar onClick={() => setIsOpen(!isOpen)} />
                </>
              )}
              <div
                className={
                  splitLocation[1] === "new-document" ? "content__main2 App" : "content__main" +
                    (splitLocation[1] === "shoulder-treatment-form"
                      ? "hide_menus"
                      : "")
                }
              >
                <Routes>
                  <Route path="/dashboard" element={<Dashboard />} />
                  <Route
                    path="/templete-creates/:tId/:eId"
                    element={<LetterNew />}
                  />
                  <Route path="/patient-list" element={<PatientsList />} />
                  <Route path="/cases-list" element={<CasesList />} />

                  {/* <Route path="/start-new-case" element={<StartNewCase />} /> */}
                  {/* <Route path="/case-upload" element={<CaseUpload />} /> */}
                  {/* <Route path="/new-case-review" element={<NewCaseReview />} /> */}
                  {/* <Route path="/case-thank-you" element={<CaseThankYou />} /> */}
                  {/* <Route path="/view-case" element={<ViewCase />} /> */}
                  {/* <Route path="/dicom-reports" element={<DicomReports />} /> */}
                  {/* <Route
                  path="/threed-model-reports"
                  element={<ThreeDReports />}
                /> */}

                  <Route
                    path="/patient-details/:akid"
                    element={<PatientDetails />}
                  />
                  <Route
                    path="/patient-demographic/:akid"
                    element={<PatientDemographic />}
                  />
                  <Route
                    path="/patient-view/:akid/:dcid"
                    element={<PatientView />}
                  />
                  <Route path="/patient-view/:akid" element={<PatientView />} />
                  <Route
                    path="/management-plan-list"
                    element={<ManagementPlanList />}
                  />
                  <Route
                    path="/management-plan/:akid"
                    element={<ManagementPlan />}
                  />
                  <Route
                    path="/view-management-plan/:akid/:rId"
                    element={<ManagementPlanView />}
                  />
                  {/* <Route
                    path="/view-management-plan-initial/akId"
                    element={<ManagementPlanInitialView />}
                  /> */}
                  <Route
                    path="/operative-notes-list"
                    element={<OperativeNotesList />}
                  />
                  <Route
                    path="/add-operative-notes"
                    element={<AddOperativeNotes />}
                  />
                  <Route
                    path="/add-operative-notes/:rId/:ptId"
                    element={<AddOperativeNotes />}
                  />
                  <Route
                    path="/post-operative-note/:orgId/:ptId"
                    element={<PostOperativeNote />}
                  />
                  <Route path="/pathology" element={<Pathology />} />
                  <Route
                    path="/prescription-template/:akId"
                    element={<DefaultPrescription />}
                  />
                  <Route
                    path="/view-operative-notes/:akid/:rId"
                    element={<ViewOperativeNotes />}
                  />
                  <Route
                    path="/view-genaral-operative-notes/:akid/:rId"
                    element={<ViewGenaralOPNotes />}
                  />
                  {/* <Route path="/data-export" element={<DataExport />} /> */}
                  {/* <Route
                    path="/data-export-advance"
                    element={<DataExportadvance />}
                  /> */}
                  <Route
                    path="/advance-data-export"
                    element={<AdvancedataExport />}
                  />
                  <Route path="/" element={<Dashboard />} />
                  <Route path="/*" element={<NotFoundPage />} />
                  <Route
                    path="/document-list/:akId"
                    element={<DocumentList />}
                  />
                  <Route
                    path="/document-list-table"
                    element={<DocumentListTable />}
                  />
                  <Route path="/add-document/:akId" element={<AddDocument />} />
                  <Route path="/new-document/:akId" element={<NewDocument />} />
                  <Route path="/edit-document" element={<EditDocument />} />
                  <Route
                    path="/complete-document"
                    element={<CompleteDocument />}
                  />
                  <Route
                    path="/patient-operative-note-list/:akId"
                    element={<PatientOperativeList />}
                  />
                  <Route path="/notes/:akId" element={<NotesDetails />} />
                  <Route
                    path="/view-treatment-form"
                    element={<ViewTreatmentForm />}
                  />
                  {/* <Route path="/schedular" element={<Schedular />} /> */}
                  <Route path="/schedular" element={<MyCalendar />} />
                  <Route
                    path="/patient-org-list/:akId"
                    element={<PatientOrgList />}
                  />
                  <Route
                    path="/doctor-score-list/:akId"
                    element={<DoctorScoring />}
                  />
                  {/* <Route
                  path="/patient-assement-step1/:akId"
                  element={<PatientAssessmentStep1 />}
                />
                <Route
                  path="/patient-assement-step2/:akId"
                  element={<PatientAssessmentStep2 />}
                /> */}
                  <Route path="/template/:akId" element={<Template />} />
                  <Route path="/quetemplate/:akId" element={<QueTemplate />} />
                  {/* <Route path="/akunah-chat/:akId" element={<Chat />} /> */}
                  <Route path="/insurance/:akId" element={<Worklog />} />
                  <Route
                    path="/pathology-generate/:akId"
                    element={<Pathologys />}
                  />
                  <Route path="/akunah-connect/:akId" element={<Chat />} />
                  <Route path="/pdf-m" element={<PdfM />} />
                  <Route
                    path="/pdf-generate/:akId/:typeId"
                    element={<MainContent />}
                  />
                  <Route path="/radiology" element={<RadiologyPage />} />
                  <Route path="/gallery" element={<Gallery />} />
                  <Route path="/request-view/:tId" element={<RequestView />} />
                  <Route
                    path="/templete-create/:tId/:eId"
                    element={<TemplateCreate />}
                  />
                  <Route
                    path="/queensland-radiology"
                    element={<QueenslandRadiologySpecialists />}
                  />

                  <Route path="/my-consents" element={<Consents />} />

                  <Route path="/my-document" element={<MyDocument />} />
                  <Route
                    path="/hip-arthroscopy/:rId/:ptId"
                    element={<Arthroscopypage />}
                  />
                  <Route
                    path="/hip-arthoplasty/:rId/:ptId"
                    element={<ArthoPlastyPage />}
                  />
                  <Route
                    path="/hip-fracture-surgery/:rId/:ptId"
                    element={<FractureSurgery />}
                  />
                  <Route
                    path="/hip-preservation-surgery/:rId/:ptId"
                    element={<OtherHipSurgery />}
                  />
                  <Route path="/hip-other/:rId/:ptId" element={<OthersHip />} />

                  <Route
                    path="/knee-add-opnote"
                    element={<AddKneeOperativeNotes />}
                  />
                  <Route
                    path="/view-hip-operative-note/:akid/:rId"
                    element={<HipOpView />}
                  />
                  <Route
                    path="/view-knee-operative-note/:akid/:rId"
                    element={<KneeOpView />}
                  />

                  <Route
                    path="/knee-arthiritis/:rId/:ptId"
                    element={<Arthiritis />}
                  />
                  <Route
                    path="/knee-instability/:rId/:ptId"
                    element={<Instability />}
                  />
                  {/* <Route path="/knee-meniscalProcedure/:rId/:ptId" element={<MeniscalProcedure/>}/> */}
                  <Route
                    path="/knee-septicKnee/:rId/:ptId"
                    element={<SepticKnee />}
                  />
                  <Route
                    path="/knee-fracture/:rId/:ptId"
                    element={<Fracture />}
                  />
                  <Route
                    path="/knee-patellarInstability/:rId/:ptId"
                    element={<PatellarInstability />}
                  />
                  <Route
                    path="/knee-revision-arthroplasty/:rId/:ptId"
                    element={<RevisionArthroplasty />}
                  />
                  <Route
                    path="/knee-osteonecrosis/:rId/:ptId"
                    element={<Osteonecrosis />}
                  />
                  <Route
                    path="/knee-chondral-injury/:rId/:ptId"
                    element={<ChondralInjury />}
                  />
                  <Route
                    path="/knee-meniscal-injury/:rId/:ptId"
                    element={<Meniscalinjury />}
                  />
                  <Route
                    path="/knee-other/:rId/:ptId"
                    element={<OthersKnee />}
                  />
                  <Route path="/Fracture/:rId/:ptId" element={<Fracture />} />
                  <Route path="ElbowFrac" element={<AddKneeOperativeNotes />} />

                  {/* Elbow Route */}
                  <Route
                    path="/elbow-fracture/:rId/:ptId"
                    element={<ElbowFracture />}
                  />
                  <Route
                    path="/elbow-instability/:rId/:ptId"
                    element={<InstabilityElbow />}
                  />
                  <Route
                    path="/elbow-arthritis/:rId/:ptId"
                    element={<Arthritis />}
                  />
                  <Route
                    path="/elbow-failedarthoplasty/:rId/:ptId"
                    element={<FailedArthoplasty />}
                  />
                  <Route
                    path="/elbow-ligament/:rId/:ptId"
                    element={<Ligament />}
                  />
                  <Route
                    path="/elbow-entrapment/:rId/:ptId"
                    element={<NerveEntrapment />}
                  />
                  <Route
                    path="/elbow-otherselbow/:rId/:ptId"
                    element={<OthersElbow />}
                  />
                  <Route
                    path="/view-elbow-operative-note/:akid/:rId"
                    element={<ViewElbowNote />}
                  />
                  <Route path="/foot" element={<AddHipOperativeNote />} />

                  {/* Foot Ankle Route */}
                  <Route
                    path="/foot-fracture/:rId/:ptId"
                    element={<FootFracture />}
                  />
                  <Route
                    path="/foot-arthritis/:rId/:ptId"
                    element={<ArthritisFoot />}
                  />

                  <Route
                    path="/foot-charcotfoot/:rId/:ptId"
                    element={<CharcotFoot />}
                  />
                  <Route
                    path="/foot-amputation/:rId/:ptId"
                    element={<Amputation />}
                  />
                  <Route
                    path="/foot-arthroscopy/:rId/:ptId"
                    element={<FootArthroscopy />}
                  />
                  <Route
                    path="/foot-instabilityFoot/:rId/:ptId"
                    element={<InstabilityFoot />}
                  />
                  <Route
                    path="/foot-deformity/:rId/:ptId"
                    element={<Deformity />}
                  />

                  <Route
                    path="/foot-achillesTendon/:rId/:ptId"
                    element={<AchillesTendon />}
                  />
                  <Route
                    path="/foot/:rId/:ptId"
                    element={<AvascularNecrosis />}
                  />
                  <Route path="/foot-others/:rId/:ptId" element={<Others />} />

                  {/* view-foot-operative-note */}
                  <Route
                    path="/view-foot-operative-note/:akid/:rId"
                    element={<FootopView />}
                  />

                  {/* hand and wrist */}
                  <Route
                    path="/hand-fracture/:rId/:ptId"
                    element={<HandFracture />}
                  />
                  <Route
                    path="/hand-instability/:rId/:ptId"
                    element={<HandInstability />}
                  />
                  <Route
                    path="/hand-tendon/:rId/:ptId"
                    element={<HandTendon />}
                  />
                  <Route
                    path="/hand-compression/:rId/:ptId"
                    element={<HandCompression />}
                  />
                  <Route
                    path="/hand-nerve/:rId/:ptId"
                    element={<NerveInjury />}
                  />
                  <Route
                    path="/hand-arthritis/:rId/:ptId"
                    element={<HandArthritis />}
                  />
                  <Route
                    path="/hand-others/:rId/:ptId"
                    element={<WristOthers />}
                  />
                  <Route
                    path="/view-hand-operative-note/:akid/:rId"
                    element={<HandandWristOpView />}
                  />
                  <Route
                    path="/mermaid-radiology"
                    element={<MermaidRadiology />}
                  />

                  <Route
                    path="/treatement-details/:akid/:rId"
                    element={<TreatementDetails />}
                  />
                  <Route path="/unauthorized" element={<Unauthorized />} />
                  <Route path="/proms-scheduler/:akid" element={<Schedule />} />
                  <Route
                    path="/research-details/:akid/:rId"
                    element={<ResearchDetails />}
                  />
                  <Route
                    path="/referrals"
                    element={<Referrals />}
                  />
                  <Route
                    path="/my-settings"
                    element={<MySetting />}
                  />
                  <Route
                    path="insight"
                    element={<AkunahAnalyse />}
                  />

                  <Route
                    path="reports"
                    element={<Reports />}
                  />
                  <Route
                    path="Adutitool" element={<AuditTool />} />
                  <Route
                    path="Audit-tool-table" element={<AuditToolTable />} />
                  

                  <Route
                    path="data-export-complete"
                    element={<CompleteDataExport />}
                  />

                  <Route path="All-Notifications" element={<Notifications />} />


                </Routes>
              </div>
            </div>
          </div>
        )}
      </Suspense>
      {user.isEmail === false && (
        <div className="" style={{ height: "100%" }}>
          <Suspense fallback={<div className="text-center">{""}</div>}>
            <div className="App ">
              <div className="mb-5">
                <TopHeaderdocmap />
              </div>
              <div className="content__main_reg ">
                <Routes>
                  <Route
                    path="/doctor-registration"
                    element={<DoctorRegistration />}
                  />
                  <Route
                    path="/"
                    element={<Navigate to="/doctor-registration" />}
                  />
                  <Route path="/*" element={<NotFoundPage />} />
                </Routes>
              </div>
            </div>
          </Suspense>
        </div>
      )}
      {user.isEmail === true && login == "" && akToken === null && (
        <div className="" style={{ height: "100%" }}>
          <Suspense fallback={<div className="text-center">{""}</div>}>
            <div className="App ">
              {/* <TopHeaderBefore/> */}
              <div className="mb-5">
                <TopHeaderdocmap />
              </div>
              <div className="content__main_reg ">
                <Routes>
                  <Route path="/doctor-map" element={<DoctorMap />} />
                  <Route path="/" element={<Navigate to="/doctor-map" />} />
                  <Route
                    path="/doctor-registration"
                    element={<DoctorRegistration />}
                  />
                  <Route path="/*" element={<NotFoundPage />} />
                  <Route path="/login-error/:akId" element={<RoleCheck />} />
                </Routes>
              </div>
            </div>
          </Suspense>
        </div>
      )}
      {/* {user.isEmail === false && user.status === false &&(
        <div className="" style={{ height: "100%" }}>
          <div className="App ">
            <div className="content__main_reg ">
              <Routes>
                <Route path="/login-error" element={<RoleCheck />} />
              
                <Route path="/*" element={<NotFoundPage />} />
              </Routes>
            </div>
          </div>
        </div>
      )} */}
    </>
  );
}
export default AppRouter;

import React, { useEffect, useState } from "react";

import viewIcon from "../../../images/eye-icon.svg";
import Invite_Icon from "../../../images/Invite_Icon.svg";
import deleteIcon from "../../../images/delete-icon.svg";
import Stop_Icon from "../../../images/Remove.svg";
import active_icon from "../../../images/active_Icon.png";
import disable_icon from "../../../images/disable_icon.png";
import toggleIcon from "../../../images/dots-blue.svg";
import actionStyles from "../../custom/table-action-items/TableActionItems.module.css";
import { Modal } from "react-bootstrap";
import Button from "../../custom/button/Button";
import InputField from "../../custom/inputfield/InputField";
import Dropdown from "react-bootstrap/Dropdown";
import useAxios from "../../../axiosinstance";
import { useSelector, useDispatch } from "react-redux";
import { noteRefresh } from "../../../redux/actions";
import Message from "../../custom/toster/Message";
import { useSearchParams } from "react-router-dom";
export default function PromsAction({
  label,
  title,
  resource_id,
  speciality,
  Status,
  non_surgicalinput,
  Others_inputfield,
  treatment_category,
  alert,
  overdue,
  reference_date,
  treatment_Note,
  schedules,
  setActiveTab,
  activeTab,
}) {
  const axiosInstance = useAxios();
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  let resId = searchParams.get("rId");
  const [showModal, setShowModal] = useState(false);
  const [showModals, setShowModals] = useState(false);
  const [showModalStop, setShowModalStop] = useState(false);
  const [showInviteIcon, setShowInviteIcon] = useState(true);
  const [stopIconClicked, setStopIconClicked] = useState(true);
  const [messageType, setMessageType] = useState();
  const [message, setMessage] = useState("");
  const [show, setShow] = useState(false);
  const [reff, setReff] = useState(false);

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleShowModals = () => {
    setShowModals(true);
  };

  const handleCloseModals = () => {
    setShowModals(false);
  };
  const handleShowStop = () => {
    if (stopIconClicked) {
      setShowModalStop(true);
    }
  };

  const handleCloseStop = () => {
    setShowModalStop(false);
  };

  const handleDelete = async () => {
    try {
      const result = await axiosInstance.current.delete(
        `/extapp/doctors/proms_clinicians_schedulerDelete?ak_dc=${login.ing_ak_id}&resource_id=${resource_id}`
      );
      if (result.data.status) {
        setReff(true);
        setShowModals(false);
        setShow(true);
        setMessageType("success");
        setMessage("Schedule Deleted Successfully");
        setActiveTab(activeTab);
      }
    } catch (err) {
      console.log(err);
      setShow(true);
      setMessageType("error");
      setMessage("An error occurred while deleting the scheduler");
    } finally {
      console.log("finally api responsed");
    }
  };

  useEffect(() => {
    if (reff) {
      setReff(false);
    }
  }, [reff]);

  setTimeout(() => {
    if (reff) {
      dispatch(noteRefresh(new Date().getMilliseconds()));
    } else {
    }
  }, 2000);

  const handleStop = async () => {
    try {
      const result = await axiosInstance.current.put(
        `extapp/doctors/proms_clinicians_schedulerConDis?ak_dc=${login.ing_ak_id}&resource_id=${resource_id}&n=0`
      );
      if (result) {
        setShow(true);
        setMessageType("success");
        setMessage("Schedule Stopped Successfully");
        setShowModalStop(false);
        setShowInviteIcon(false);
        setStopIconClicked(false);
      }
    } catch (err) {
      console.log(err);
    } finally {
      console.log("finally api responsed");
    }
  };
  const handleShowModal = async () => {
    setShowModal(true);
  };
  useEffect(() => {
    if (Status === "0") {
      setStopIconClicked(false);
    }
    if (Status === "0") {
      setShowInviteIcon(false);
      setStopIconClicked(false);
    }
  }, [Status]);

  return (
    <>
      {show && <Message message={message} type={messageType} />}
      <div
        className={`action_large_dev custom_sort_dropdown ${actionStyles.action_large_dev}`}
      >
        <ul>
          <li>
            <img
              className={`action_icons`}
              src={viewIcon}
              alt="Icon"
              onClick={handleShowModal}
              data-bs-toggle="tooltip"
              title="View"
              style={{ cursor: "pointer" }}
            />
          </li>

          <li>
            <img
              className={`action_icons`}
              src={stopIconClicked ? active_icon : disable_icon}
              alt="Icon"
              onClick={handleShowStop}
              data-bs-toggle="tooltip"
              title="Stop"
              style={{ height: "20px", width: "20px", cursor: "pointer" }}
            />
          </li>

          <li>
            <img
              className={`action_icons`}
              src={deleteIcon}
              alt="Icon"
              onClick={handleShowModals}
              data-bs-toggle="tooltip"
              title="Delete"
              style={{ cursor: "pointer" }}
            />
          </li>
        </ul>
      </div>

      <div
        className={`action_small_dev text-end custom_sort_dropdown ${actionStyles.action_small_dev}`}
      >
        <Dropdown
          className={`custom_dropdown ${actionStyles.drop_down} text-start`}
        >
          <Dropdown.Toggle
            className={actionStyles.dropdown_button}
            variant="default"
            id="dropdown-basic"
          >
            {" "}
            <span>
              <img
                className={`toggle_dots_img`}
                src={toggleIcon}
                alt="toggle"
              />
            </span>
          </Dropdown.Toggle>
          <Dropdown.Menu>
            <Dropdown.Item>
              <div onClick={handleShowModal} style={{ cursor: "pointer" }}>
                <img
                  className={`action_icons`}
                  src={viewIcon}
                  data-bs-toggle="tooltip"
                  title="View"
                  alt="Icon"
                />
                View
              </div>
            </Dropdown.Item>
            <Dropdown.Item>
              <img
                className={`action_icons`}
                src={stopIconClicked ? active_icon : disable_icon}
                alt="Icon"
                onClick={handleShowStop}
                data-bs-toggle="tooltip"
                title="Stop"
                style={{ height: "20px", width: "20px", cursor: "pointer" }}
              />
              <span onClick={handleShowStop} style={{ cursor: "pointer" }}>
                Stop
              </span>
            </Dropdown.Item>
            <Dropdown.Item>
              <img
                className={`action_icons`}
                src={deleteIcon}
                alt="Icon"
                onClick={handleShowModals}
                data-bs-toggle="tooltip"
                title="Delete"
                style={{ cursor: "pointer" }}
              />
              <span onClick={handleShowModals} style={{ cursor: "pointer" }}>
                Delete
              </span>
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>
      </div>

      <Modal
        show={showModal}
        onHide={handleCloseModal}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-dialog-centered max_width_model"
        className="settings_ppup_main "
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center text-capitalize">
            {title}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="row">
            <div className="col-6">
              <div className="row">
                <div className="col-6">
                  <>
                    <InputField
                      label="PROMs schedule title*"
                      value={title}
                      type="text"
                      styleClass="text-capitalize"
                      placeholder="Enter Title"
                      disabled={true}
                      readOnly={true}
                    />
                  </>
                </div>
              </div>

              <div className="row">
                <div className="col-6">
                  <InputField
                    label="Organization*"
                    value={label}
                    type="text"
                    placeholder="Select Organization"
                    disabled={true}
                    readOnly={true}
                  />
                </div>
                <div className="col-6">
                  <InputField
                    label="Specialty*"
                    value={
                      speciality === "5"
                        ? "Knee"
                        : speciality === "4"
                          ? "Shoulder"
                          : speciality === "6"
                            ? "Hip"
                            : speciality === "21"
                              ? "Foot and ankle"
                              : speciality === "22"
                                ? "Elbow"
                                : speciality === "23"
                                  ? "Hand and Wrist"
                                  : speciality === "33"
                                    ? "General"
                                    : ""
                    }
                    type="text"
                    placeholder="Select Specialty"
                    disabled={true}
                    readOnly={true}
                  />
                </div>
              </div>
              <div className="row">
                <div className="col-6">
                  <InputField
                    label="Treatment Category*"
                    value={treatment_category}
                    type="text"
                    placeholder="Select Treatment category"
                    disabled={true}
                    readOnly={true}
                  />
                </div>
                <div className="col-6">
                  <InputField
                    label="Primary Diagnosis"
                    value={non_surgicalinput}
                    type="text"
                    placeholder="Primary Diagnosis"
                    disabled={true}
                    readOnly={true}
                  />
                  {non_surgicalinput === "Other" && (
                    <div className="col-12">
                      <InputField
                        // label="Other"
                        value={Others_inputfield}
                        type="text"
                        placeholder="Specify"
                        disabled={true}
                        readOnly={true}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div className="row">
                {/* <div className="col-6">
                  <InputField
                    label="Alert"
                    value={alert}
                    type="text"
                    placeholder="Select Alert"
                    disabled={true}
                    readOnly={true}
                  />
                </div> */}
                <div className="col-6">
                  <InputField
                    label="Alert for Overdue Forms"
                    value={overdue}
                    type="text"
                    placeholder="Select Alert for Overdue Forms"
                    disabled={true}
                    readOnly={true}
                  />
                </div>
              </div>
            </div>
            <div className="col-6">
              <div className="row">
                <div className="col-1">
                  <div class="horizontal-line"></div>
                </div>
                <div className="col-10">
                  <div className="row">
                    <div className="col-6">
                      <InputField
                        label="Reference Date*"
                        value={reference_date}
                        type="text"
                        placeholder="Select Reference Date"
                        disabled={true}
                        readOnly={true}
                      />
                    </div>
                  </div>
                  {/* <div className="row">
                    <div className="col-6">
                      <InputField
                        label="Treatment Note"
                        value={treatment_Note}
                        type="text"
                        placeholder="Treatment Note"
                        disabled={true}
                        readOnly={true}
                      />
                    </div>
                  </div> */}

                  <label>Schedule*</label>
                  <div
                    className="scrollbar_vert"
                    style={{ height: "250px", paddingRight: "10px" }}
                  >
                    {schedules.length !== 0 ? (
                      <div className="row">
                        {schedules[0].value && (
                          <div className="row">
                            <div className="col-6">
                              <InputField
                                value={schedules[0].varient}
                                type="text"
                                placeholder="Specify"
                                styleClass="mw-100 rounded-pill"
                                disabled={true}
                                readOnly={true}
                              />
                            </div>
                            <div className="col-6">
                              <InputField
                                value={schedules[0].value}
                                type="text"
                                placeholder="Specify"
                                disabled={true}
                                readOnly={true}
                                styleClass="mw-100 rounded-pill"
                              />
                            </div>
                          </div>
                        )}
                        {schedules[1].value && (
                          <div className="row">
                            <div className="col-6">
                              <InputField
                                value={schedules[1].varient}
                                type="text"
                                placeholder="Specify"
                                styleClass="mw-100 rounded-pill"
                                disabled={true}
                                readOnly={true}
                              />
                            </div>
                            <div className="col-6">
                              <InputField
                                value={schedules[1].value}
                                type="text"
                                placeholder="Specify"
                                disabled={true}
                                readOnly={true}
                                styleClass="mw-100 rounded-pill"
                              />
                            </div>
                          </div>
                        )}
                        {schedules[2].value && (
                          <div className="row">
                            <div className="col-6">
                              <InputField
                                value={schedules[2].varient}
                                type="text"
                                placeholder="Specify"
                                styleClass="mw-100 rounded-pill"
                                disabled={true}
                                readOnly={true}
                              />
                            </div>
                            <div className="col-6">
                              <InputField
                                value={schedules[2].value}
                                type="text"
                                placeholder="Specify"
                                disabled={true}
                                readOnly={true}
                                styleClass="mw-100 rounded-pill"
                              />
                            </div>
                          </div>
                        )}
                        {schedules[3].value && (
                          <div className="row">
                            <div className="col-6">
                              <InputField
                                value={schedules[3].varient}
                                type="text"
                                placeholder="Specify"
                                styleClass="mw-100 rounded-pill"
                                disabled={true}
                                readOnly={true}
                              />
                            </div>
                            <div className="col-6">
                              <InputField
                                value={schedules[3].value}
                                type="text"
                                placeholder="Specify"
                                disabled={true}
                                readOnly={true}
                                styleClass="mw-100 rounded-pill"
                              />
                            </div>
                          </div>
                        )}
                        {schedules[4].value && (
                          <div className="row">
                            <div className="col-6">
                              <InputField
                                value={schedules[4].varient}
                                type="text"
                                placeholder="Specify"
                                styleClass="mw-100 rounded-pill"
                                disabled={true}
                                readOnly={true}
                              />
                            </div>

                            <div className="col-6">
                              <InputField
                                value={schedules[4].value}
                                type="text"
                                placeholder="Specify"
                                disabled={true}
                                readOnly={true}
                                styleClass="mw-100 rounded-pill"
                              />
                            </div>
                          </div>
                        )}
                        {schedules[5]?.value ? (
                          <div className="row">
                            <div className="col-6">
                              <InputField
                                value={schedules[5]?.varient}
                                type="text"
                                placeholder="Specify"
                                styleClass="mw-100 rounded-pill"
                                disabled={true}
                                readOnly={true}
                              />
                            </div>
                            <div className="col-6">
                              <InputField
                                value={schedules[5]?.value}
                                type="text"
                                placeholder="Specify"
                                disabled={true}
                                readOnly={true}
                                styleClass="mw-100 rounded-pill"
                              />
                            </div>
                          </div>
                        ) : (
                          ""
                        )}
                        {schedules[6]?.value && (
                          <div className="row">
                            <div className="col-6">
                              <InputField
                                value={schedules[6]?.varient}
                                type="text"
                                placeholder="Specify"
                                styleClass="mw-100 rounded-pill"
                                disabled={true}
                                readOnly={true}
                              />
                            </div>
                            <div className="col-6">
                              <InputField
                                value={schedules[6]?.value}
                                type="text"
                                placeholder="Specify"
                                disabled={true}
                                readOnly={true}
                                styleClass="mw-100 rounded-pill"
                              />
                            </div>
                          </div>
                        )}
                        {schedules[7]?.value && (
                          <div className="row">
                            <div className="col-6">
                              <InputField
                                value={schedules[7]?.varient}
                                type="text"
                                placeholder="Specify"
                                styleClass="mw-100 rounded-pill"
                                disabled={true}
                                readOnly={true}
                              />
                            </div>
                            <div className="col-6">
                              <InputField
                                value={schedules[7]?.value}
                                type="text"
                                placeholder="Specify"
                                disabled={true}
                                readOnly={true}
                                styleClass="mw-100 rounded-pill"
                              />
                            </div>
                          </div>
                        )}
                        {schedules[8]?.value && (
                          <div className="row">
                            <div className="col-6">
                              <InputField
                                value={schedules[8]?.varient}
                                type="text"
                                placeholder="Specify"
                                styleClass="mw-100 rounded-pill"
                                disabled={true}
                                readOnly={true}
                              />
                            </div>
                            <div className="col-6">
                              <InputField
                                value={schedules[8]?.value}
                                type="text"
                                placeholder="Specify"
                                disabled={true}
                                readOnly={true}
                                styleClass="mw-100 rounded-pill"
                              />
                            </div>
                          </div>
                        )}
                        {schedules[9]?.value && (
                          <div className="row">
                            <div className="col-6">
                              <InputField
                                value={schedules[9]?.varient}
                                type="text"
                                placeholder="Specify"
                                styleClass="mw-100 rounded-pill"
                                disabled={true}
                                readOnly={true}
                              />
                            </div>
                            <div className="col-6">
                              <InputField
                                value={schedules[9]?.value}
                                type="text"
                                placeholder="Specify"
                                disabled={true}
                                readOnly={true}
                                styleClass="mw-100 rounded-pill"
                              />
                            </div>
                          </div>
                        )}
                      </div>
                    ) : (
                      ""
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal.Body>
      </Modal>

      <Modal
        centered
        show={showModals}
        onHide={handleCloseModals}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-s patient_notes_popup"
      >
        <Modal.Header>
          <Modal.Title className="text-center">
            <b>{"Delete PROMs Schedule"}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="">
          <div className="text-center">
            <p>
              <strong>You are about to delete the PROMs schedule for</strong>{" "}
            </p>
            <p>
              <strong className="text-capitalize">{title}</strong>
            </p>
            <p>
              <strong>All patient notifications will be discontinued</strong>
            </p>
            <p>
              <strong>Click "Yes" if you would like to proceed</strong>
            </p>
          </div>
        </Modal.Body>
        {/* <br></br> */}
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <div className="row">
            <div className="col-6">
              <Button
                value="No"
                radius="20px"
                width="140px"
                fontSize="14px"
                // buttonStyle="btn_outline f_600"
                border=""
                color=""
                minHeight="40px"
                height="40px"
                borderRadius="50px"
                background="transparent"
                onClick={handleCloseModals}
              />
            </div>

            <div className="col-6">
              <Button
                value="Yes"
                radius="20px"
                width="140px"
                fontSize="14px"
                // buttonStyle="btn_outline f_600"
                border=""
                color=""
                minHeight="40px"
                height=""
                onClick={handleDelete}
              />
            </div>
          </div>
        </Modal.Footer>
      </Modal>
      <Modal
        centered
        show={showModalStop}
        onHide={handleCloseStop}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-s patient_notes_popup"
      >
        <Modal.Header>
          <Modal.Title className="text-center">
            {"Stop PROMs Schedule"}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="text-center">
            <p>
              <strong>You are about to stop the PROMs schedule for </strong>
            </p>
            <p>
              <strong className="text-capitalize">{title}</strong>
            </p>
            <p>
              {" "}
              <strong>All patient notifications will be discontinued</strong>
            </p>
            <p>
              <strong>Click "Yes" if you would like to proceed</strong>
            </p>
          </div>
        </Modal.Body>
        <Modal.Footer
          style={{
            display: "flex",
            justifyContent: "center",
            gap: "10px",
          }}
        >
          <div className="row">
            <div className="col-6">
              <Button
                value="No"
                radius="20px"
                width="140px"
                fontSize="14px"
                buttonStyle="btn_outline f_600"
                border=""
                color=""
                minHeight="40px"
                height=""
                onClick={handleCloseStop}
              />
            </div>
            <div className="col-6">
              <Button
                value="Yes"
                radius="20px"
                width="140px"
                fontSize="14px"
                buttonStyle="btn_outline f_600"
                border=""
                color=""
                minHeight="40px"
                height=""
                // setShowModals={true}
                onClick={handleStop}
              />
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </>
  );
}

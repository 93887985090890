import React from "react";
import PropTypes from "prop-types";
const ButtonLoader = ({
  value,
  border,
  color,
  // children,
  height,
  onClick,
  radius,
  width,
  minHeight,
  fontSize,
  buttonStyle,
  minWidth,
  disabled,
  opacity,
  cursor,
  margin
}) => {
  return (
    <button
      disabled={disabled}
      onClick={(event) => onClick(event)}
      style={{
        backgroundColor: color,
        border,
        borderRadius: radius,
        height,
        minHeight,
        width,
        fontSize,
        minWidth,
        opacity,
        cursor,
        marginRight:margin,
        alignItems:"center",
        justifyContent:"center",
        display:"flex"
      }}
      className={buttonStyle}
    >
      {disabled=== true ? <div class="spinner-border " role="status" style={{"margin":'3px' , width: '1.5rem',
    height: '1.5rem'}}>
   
      </div> : value}
   

    </button>
  );
};

ButtonLoader.propTypes = {
  styleClass: PropTypes.string,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

ButtonLoader.defaultProps = {
  buttonStyle: "btn_fill",
};

export default ButtonLoader;

import React from "react";
import PropTypes from "prop-types";
const Button = ({
  value,
  border,
  color,
  // children,
  height,
  onClick,
  radius,
  width,
  minHeight,
  fontSize,
  buttonStyle,
  minWidth,
  disabled,
  opacity,
  cursor,
  margin,
  marginLeft,
  marginRight,
  marginTop
}) => {
  return (
    <button
      disabled={disabled}
      onClick={(event) => onClick(event)}
      style={{
        backgroundColor: color,
        border,
        borderRadius: radius,
        height,
        minHeight,
        width,
        fontSize,
        minWidth,
        opacity,
        cursor,
        marginRight:marginRight,
        marginLeft:marginLeft,
        marginTop:marginTop,
      }}
      className={buttonStyle}
    >
      {/* {disabled=== true ? <div class="spinner-border " role="status" style={{"margin":'3px'}}>
   
      </div> : value} */}
      {value}

    </button>
  );
};

Button.propTypes = {
  styleClass: PropTypes.string,
  value: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
};

Button.defaultProps = {
  buttonStyle: "btn_fill",
};

export default Button;

import { FETCH_SUBID_SUCCESS, FETCH_SUBID_FAIL } from "../actions/actionTypes";
  
  const initialState = { 
    arr : ''
}
  
  const userIdReducer = (state = initialState, action) => {
    switch (action.type) {
      case FETCH_SUBID_SUCCESS:
        return { ...state, arr: action.data };

      case FETCH_SUBID_FAIL:
        return { ...state, arr: action.data };

      default:
        return state;
    }
  }

  export default userIdReducer;
import React, { useState, useRef } from "react";
import { LoadScript, StandaloneSearchBox } from "@react-google-maps/api";

function GoogleAddress({
  onAddressChange,
  label,
  valid,
  value,
  styleClass,
  handleCity,
  handlePostCode,
  readOnly,
  placeholder
}) {
  const [location, setLocation] = useState("");
  const [addressError, setAddressError] = useState(valid);
  const inputRef = useRef(null);
  let YOUR_API_KEY = "AIzaSyAb4s2rhkg_dDms-SKZx16Ka5zDcU6aEIs";
  console.log(location, addressError);
  function handlePlaceChanged() {
    const place = inputRef.current.getPlaces()[0];
    if (place) {
      setLocation(place.formatted_address);
      onAddressChange(place.formatted_address);

      place.address_components.map((ele) => {
        if (ele.types.includes("postal_code")) {
          // console.log(ele.long_name);
          handlePostCode(ele.long_name);
        }
      });
      place.address_components.map((ele) => {
        if (ele.types.includes("locality")) {
          // console.log(ele.long_name);
          handleCity(ele.long_name);
        }
      });
    }
  }

  function handleLocation(value) {
    setAddressError(false);
    onAddressChange(value);
    setLocation(value);
  }

  return (
    <>
      {label && <label htmlFor="app-input-field">{label} </label>}
      {/* {window.google && ( */}
        <LoadScript
          googleMapsApiKey={YOUR_API_KEY}
          libraries={["places"]}
          onLoad={() => console.log("LoadScript loaded")}
        >
          <StandaloneSearchBox
            onLoad={(ref) => (inputRef.current = ref)}
            onPlacesChanged={handlePlaceChanged}
          >
            <input
              type="text"
              className={`form-control mxw_100 br_10 ${styleClass}`}
              placeholder={placeholder ? placeholder :"Enter Address " }
              value={value}
              readOnly={readOnly}
              onChange={(e) => handleLocation(e.target.value)}
            />
          </StandaloneSearchBox>
        </LoadScript>
  
      {value === "" && (
        <>
          {valid && (
            <p className="text-danger" style={{ marginLeft: "15px" }}>
              This Field required
            </p>
          )}
        </>
      )}
    </>
  );
}
export default GoogleAddress;

const initialState = { 
    arr : {}
}
  
  const featuresReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_FEATURES':
        return { ...state, arr: action.data };

      default:
        return state;
    }
  }

  export default featuresReducer;

const initialState = { 
    arr : {}
}
  
  const AddPatientReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'SET_PATIENT_DATA':
        return { ...state, arr: action.data };

 

      default:
        return state;
    }
  }

  export default AddPatientReducer;
import React, { useEffect, useState, useRef } from "react";
import InputField from "../custom/inputfield/InputField";
import Dropdown from "../custom/dropdown/Dropdown";
// import minus from "../../../src/images/minus.png";
import plusIcon from "../../images/plus.png";
import deleteIcon from "../../images/delete-icon.svg";

function DropdownList({
  setFormData,
  setDropdownValues,
  submitdata,
  setVisibleInput,
  droperrorchange,
}) {
  const scrollRef = useRef(null);
  const [typeData, setTypeData] = useState("");
  const [dropdowns, setDropdowns] = useState([
    {
      id: 1,
      visible: true,
      value: "",
      newValue: "",
      errorMessage: "",
      newValueErrorMessage: "",
    },
  ]);

  const addDropdown = () => {
    if (dropdowns.length < 10) {
      setVisibleInput((prevInput) => prevInput + 1);
      setDropdowns((prevDropdowns) => [
        ...prevDropdowns,
        {
          id: prevDropdowns.length + 1,
          visible: true,
          value: "",
          newValue: "",
          errorMessage: "",
          newValueErrorMessage: "",
        },
      ]);
      scrollRef.current.scrollIntoView({ behavior: "smooth" });
    }
  };

  const removeDropdown = (id) => {
    if (dropdowns.length > 1) {
      setVisibleInput((prevInput) => prevInput - 1);
      setDropdowns((prevDropdowns) =>
        prevDropdowns.filter((dropdown) => dropdown.id !== id)
      );
    }
  };

  const handleDropdownChange = (id, value) => {
    setTypeData(value);
    setDropdowns((prevDropdowns) =>
      prevDropdowns.map((dropdown) =>
        dropdown.id === id ? { ...dropdown, value } : dropdown
      )
    );
  };

  const handleInputChange = (id, inputValue) => {
    let newValue, newValueErrorMessage;
    if (inputValue === "0") {
      newValue = "";
      newValueErrorMessage = "This field is required";
    } else {
      newValueErrorMessage = "";
      if (typeData === "Days") {
        newValue = inputValue.replace(/[^0-9]/g, "");
        newValue = Math.min(parseInt(newValue, 10) || 0, 365).toString();
      } else if (typeData === "Weeks") {
        newValue = inputValue.replace(/[^0-9]/g, "");
        newValue = Math.min(parseInt(newValue, 10) || 0, 52).toString();
      } else if (typeData === "Months") {
        newValue = inputValue.replace(/[^0-9]/g, "");
        newValue = Math.min(parseInt(newValue, 10) || 0, 12).toString();
      } else if (typeData === "Years") {
        newValue = inputValue.replace(/[^0-9]/g, "");
        newValue = Math.min(parseInt(newValue, 10) || 0, 5).toString();
      }
    }
    if (inputValue === "") {
      newValue = "";
      newValueErrorMessage = "This field is required";
    }
    setDropdowns((prevDropdowns) =>
      prevDropdowns.map((dropdown) =>
        dropdown.id === id
          ? { ...dropdown, newValue, newValueErrorMessage }
          : dropdown
      )
    );
  };

  const handleSubmit = () => {
    let isValid = true;
    const updatedDropdowns = dropdowns.map((dropdown) => {
      let errorMessage = "";
      if (!dropdown.value) {
        isValid = false;
        errorMessage = "This field is required";
      }
      return { ...dropdown, errorMessage };
    });

    const updatedInputFields = updatedDropdowns.map((dropdown) => {
      let newValueErrorMessage = "";
      if (!dropdown.newValue) {
        isValid = false;
        newValueErrorMessage = "This field is required";
      }
      return { ...dropdown, newValueErrorMessage };
    });

    setDropdowns(updatedInputFields);

    if (isValid) {
      const formData = {};
      const dropdownValues = {};

      dropdowns.forEach((dropdown) => {
        formData[`field${dropdown.id}`] = dropdown.newValue;
        dropdownValues[`dropdown${dropdown.id}`] = dropdown.value;
      });
    } else {
      return;
    }
  };
  useEffect(() => {
    if (submitdata) {
      handleSubmit();
    }
  }, [submitdata]);



  useEffect(() => {
    setFormData({
      field1: dropdowns[0]?.newValue ? dropdowns[0]?.newValue : "",
      field2: dropdowns[1]?.newValue ? dropdowns[1]?.newValue : "",
      field3: dropdowns[2]?.newValue ? dropdowns[2]?.newValue : "",
      field4: dropdowns[3]?.newValue ? dropdowns[3]?.newValue : "",
      field5: dropdowns[4]?.newValue ? dropdowns[4]?.newValue : "",
      field6: dropdowns[5]?.newValue ? dropdowns[5]?.newValue : "",
      field7: dropdowns[6]?.newValue ? dropdowns[6]?.newValue : "",
      field8: dropdowns[7]?.newValue ? dropdowns[7]?.newValue : "",
      field9: dropdowns[8]?.newValue ? dropdowns[8]?.newValue : "",
      field10: dropdowns[9]?.newValue ? dropdowns[9]?.newValue : "",
    });

    // Set specific dropdowns with values, set others to blank
    setDropdownValues({
      dropdown1: dropdowns[0]?.value ? dropdowns[0]?.value : "",
      dropdown2: dropdowns[1]?.value ? dropdowns[1]?.value : "",
      dropdown3: dropdowns[2]?.value ? dropdowns[2]?.value : "",
      dropdown4: dropdowns[3]?.value ? dropdowns[3]?.value : "",
      dropdown5: dropdowns[4]?.value ? dropdowns[4]?.value : "",
      dropdown6: dropdowns[5]?.value ? dropdowns[5]?.value : "",
      dropdown7: dropdowns[6]?.value ? dropdowns[6]?.value : "",
      dropdown8: dropdowns[7]?.value ? dropdowns[7]?.value : "",
      dropdown9: dropdowns[8]?.value ? dropdowns[8]?.value : "",
      dropdown10: dropdowns[9]?.value ? dropdowns[9]?.value : "",
    });
  }, [dropdowns])

useEffect(() => {
if(droperrorchange){
  setDropdowns([
    {
      id: 1,
      visible: true,
      value: "",
      newValue: "",
      errorMessage: "",
      newValueErrorMessage: "",
    },
  ])
}
}, [droperrorchange])



  return (
    <div>
      <div
        className="scrollbar_vert"
        style={{ height: dropdowns.length < 5 ? "" : "250px", paddingRight: "10px" }}

      >
        {dropdowns.map((dropdown, index) => (
          <div key={dropdown.id}>
            {dropdown.visible && (
              <>
                <div style={{ display: "flex" }}>
                  <div className="col-5">
                    <Dropdown
                      data={[
                        { value: "Days", label: "Days" },
                        { value: "Weeks", label: "Weeks" },
                        { value: "Months", label: "Months" },
                        { value: "Years", label: "Years" },
                      ]}
                      value={dropdown.value}
                      onChange={(e) => handleDropdownChange(dropdown.id, e)}
                      placeholder="Select"
                    />
                    {dropdown.errorMessage && (
                      <>
                        {!dropdown.value && (
                          <span className="text-danger">
                            {dropdown.errorMessage}
                          </span>
                        )}
                      </>
                    )}
                  </div>
                  <div
                    className="col-6 d-flex"
                    style={{ flexDirection: "column", paddingLeft: "6px" }}
                  >
                    <InputField
                      value={dropdown.newValue}
                      type="text"
                      placeholder="0"
                      onChange={(value) => handleInputChange(dropdown.id, value)}
                      styleClass="mw-100 rounded-pill"
                    />
                    {dropdown.newValueErrorMessage && (
                      <span className="text-danger">
                        {dropdown.newValueErrorMessage}
                      </span>
                    )}
                  </div>
                  {index > 0 && (
                    <span
                      className="span.add_btn1 img mt-2 ms-3"
                      onClick={() => removeDropdown(dropdown.id)}
                    >
                      <img
                        src={deleteIcon}
                        className="icon_style"
                        data-bs-toggle="tooltip"
                        data-bs-placement="top"
                        title="Remove"
                        alt="Remove"
                        style={{ cursor: 'pointer' }}
                      />
                    </span>
                  )}
                </div>
                <div ref={scrollRef}></div>
              </>
            )}
          </div>
        ))}
      </div>
      {/* <div ref={scrollRef}></div> */}
      {dropdowns.length !== 10 && (
        <span className="add_btn1" onClick={() => addDropdown()} style={{marginTop: dropdowns.length < 5 ? "" : "13px",}}>
          <img
            src={plusIcon}
            className="icon_style"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Add more"
            alt="Add-clinical"
          />
        </span>
      )}
    </div>
  );
}

export default DropdownList;

/* eslint-disable import/no-anonymous-default-export */
export default {
  home: "Home",
  patients: "Patient List",
  addPatient: "Add  Patient",
  First_Name: "First Name",
  Last_Name: "Last Name",
  Sex: "Sex",
  patient_profile: "Patient Profile",
  OperativeNotes: "Treatment Notes",
  REFLECTComplex: "REFLECT Complex",
  settings: "Settings",
  DataExport: "Data Export",
  AppVersion: "App Version",
  notifications: "Notifications",
  language: "Language & Region",
  account: "Account",
  myClinics: "My Clinics",
  select: "Select the language you’d like to use",
  timezone: "Timezone",
  Timeformat: "Time format",
  dateFormet: "Date format",
  PatientData: "Patient Data",
  checkthis: "Check this to hide patients' identities",
  scores: "Scores",
  Welcome: "Welcome to akunah,",
  let: "Let’s have a great day",
  searchPatients: "Search Patients",
  searchNextPatients: "Search for your next patient",
  startcase: "Start new case",
  startsegmenetation: "Start your new segmenetation case now",
  contactus: "Contact us",
  contactfeedback: "Contact us if you have any feedback or questions",
  contact: "Contact",
  tasks: "Tasks",
  addtask: "Add task",
  char: "characters remaining",
  recentPatients: "Recent Patients",
  profile: "Profile",
  Edit: "Edit Profile",
  name: "Name",
  editNote: "Edit Note",
  akunah: "Akunah ID",
  phone: "Phone",
  email: "Email",
  localTime: "Local time",
  logOut: "Log out",
  chat: "Chat with Akunah",
  uploadimg: "Upload an Image",
  removephoto: "Remove photo",
  advancedSearch: "Advanced Search",
  dob: "Date of Birth",
  listofPatient: "List of Patients",
  listofOPnotes: "List of Operative Notes",
  operativeNotes: "Operative Notes",
  newNote: "New Note",
  addNotes: "Add Notes",
  forms: "PROMs",
  viewForm: "View More Forms",
  viewForm_TEMP: "Generate a Template",
  viewForm_insurance: "Generate work cover",
  viewForm_precp: "Generate a Prescription",
  viewForm_akunah: "Connect with Us",
  viewForm_radio: "Request a Radiology",
  viewForm_patho: "Request a Pathology",
  view_doctor: "View Doctor Scoring List",
  viewConnect: "Connect",
  viewMore: "View More",
  viewRequest: "Request",
  viewGenerate: "Generate",
  managementPlan: "Journey",
  viewManagement: "View More Journey",
  notes: "Notes",
  viewNotes: "View More Notes",
  viewOPnotes: "View more operative notes",
  document: "Documents",
  viewDocument: "View More Documents",
  dob1: "DOB",
  sex: "Sex",
  address: "Address",
  mobile: "Mobile",
  lastVisit: "Last Visit",
  medicareCardNumber: "Medicare Card Number",
  dVAConcessionCard: "DVA Concession Card",
  privateHealthFund: "Private Health Fund",
  // lastVisit: "Last Visit",
  AKPTID: "AKPT ID",
  accountStatus: "Account Status",
  nextofKin: "Next of Kin",
  relationship: "Relationship",
  GPDetails: "GP Details",
  GPName: "GP Name",
  practice: "Practice",
  medicare: "Medicare",
  ref: "Ref",
  expiry: "Expiry",
  private: "Private",
  healthFund: "Health Fund",
  membership: "Membership Number",
  other: "Other",
  claimType: "Claim Type",
  workCover: "Work Cover",
  employer: "Employer",
  thirdPartyClaimNumber: "Third Party Claim Number",
  thirdPartyName: "Third Party Name",
  DVA: "DVA",
  DVANumber: "DVA Number",
  DefencePersonnel: "Defence Personnel",
  viewall: "View All",
  managementPlans: "Journey",
  rangeofMotion: "Range of Motion",
  initial: "Initial",
  recent: "Recent",
  activeForwardFlexion: "Active Forward Flexion",
  none: "None",
  lateralElevation: "Lateral Elevation",
  eR1Scores: "ER1 Scores",
  eR2Scores: "ER2 Scores",
  iR1Scores: "IR1 Scores",
  iR2Scores: "IR2 Scores",
  VAS: "VAS",
  WOSI: "WOSI",
  pleaseSelectOrganization: "Please Select Organization",
  fromDate: "From Date",
  toDate: "To Date",
  listofNotes: "List of Notes",
  result: "Result",
  addNote: "Add Note",
  operativeNotesList: "Operative Notes List",
  newnote: "New Note",
  listofDocuments: "List of Documents",
  documents: "Documents",
  addFile: "Add File",
  listofManagementPlans: "List of Journey",
  calender: "My Scheduler",
  patientorg: "Patient Organization",
  // listofPatient:"List Of Patients ",
  viewOrg: "View more Organization",
  patientAssesment: "Clinical Exam",
  doctorScoreList: "Doctor Scoring List",
  template: "Template",
  No_data_found: "No Record Found",
  country: "Country",
  Radiology: "Radiology",
  Pathology: "Pathology",
  akmed: "Akunah Med",
  prescription: "Prescription",
  akunah_connect: "Akunah Connect",
  pending_form: "Overdue Forms",
  insurance: "Insurance",
  document_templates: "My Templates",

  ///
  MyDocument: "My Document",
  List_Documents: "List of Documents",
  document_name: "Document Name",
  PromesScheduler: "PROMs Scheduler",
  addScheduler: "Add ",
};

import React, { useState, useEffect } from "react";
import checkIcon from "./svg/check.svg";
import errorIcon from "./svg/error.svg";
import infoIcon from "./svg/info.svg";
import warningIcon from "./svg/warning.svg";

import Toast from "./Toast";
const Message = ({ message, type, store }) => {
  const [list, setList] = useState([]);
  useEffect(() => {
    if (message !== "") {
      if (type === "success") {
        setList([
          ...list,
          {
            id: Math.floor(Math.random() * 101 + 1),
            title: message,
            description: "",
            backgroundColor: "#1DCE00",
            icon: checkIcon,
          },
        ]);
      } else if (type === "warning") {
        setList([
          ...list,
          {
            id: Math.floor(Math.random() * 101 + 1),
            title: message,
            description: "",
            backgroundColor: "#ffc107",
            icon: warningIcon,
          },
        ]);
      } else if (type === "error") {
        setList([
          ...list,
          {
            id: Math.floor(Math.random() * 101 + 1),
            title: message,
            description: "",
            backgroundColor: "#FF0000",
            icon: errorIcon,
          },
        ]);
      } else if (store === "Keep Both") {
        setList([
          ...list,
          {
            id: Math.floor(Math.random() * 101 + 1),
            title: message,
            description: "",
            backgroundColor: "#1DCE00",
            icon: checkIcon,
            width:310,
          },
        ]);
      } else if (store === "Replace") {
        setList([
          ...list,
          {
            id: Math.floor(Math.random() * 101 + 1),
            title: message,
            description: "",
            backgroundColor: "#1DCE00",
            icon: checkIcon,
            height:150,
            width:400,
          },
        ]);
      } else {
        setList([
          ...list,
          {
            id: Math.floor(Math.random() * 101 + 1),
            title: message,
            description: "",
            backgroundColor: "#17a2b8",
            icon: infoIcon,
          },
        ]);
      }
    }
  }, [message, type]);

  return (
    <>
      <Toast
        toastList={list}
        position={"top-right"}
        autoDelete={true}
        autoDeleteTime={12000}
        // autoDeleteTime={900000}
      />
    </>
  );
};

export default Message;

import React, { useState, useEffect } from 'react'
import { Modal, Button } from "react-bootstrap";
import { useKeycloak } from "@react-keycloak/web";
import useAxios from "../../axiosinstance";
import { useSelector } from "react-redux";
import platform from "platform";
export default function LogoutModel({
    showModal,
    hideModal,

}) {
    const axiosInstance = useAxios();
    const { keycloak } = useKeycloak();
    const logout_url = window.location.origin;
    let logdata = localStorage.getItem("AkTOKEN");
    const login = useSelector((state) => state.mainReducer.loginData.arr);
    const [doctor] = useState(login.ing_ak_id);

    const [browser, setBrowser] = useState("");
    useEffect(() => {
        const deviceInfo = platform;
        setBrowser(deviceInfo.description.toLowerCase().replace(/\s/g, ""));
    }, []);
    const confirmModalLogout = async () => {
        if (logdata) {
            try {
                const result = await axiosInstance.current.put(
                    `extapp/Logout?ak_id=${doctor}&browser=${browser}`,
                    {
                        headers: {
                            "Content-Type": "application/json",
                        },
                    }
                );
                if (result.data.status) {
                    localStorage.removeItem("AkTOKEN");
                    sessionStorage.removeItem("AkTOKEN");
                    keycloak.logout({ redirectUri: logout_url });
                }
            } catch (err) {
                // setMessage(err.message);
                // setMessageType("error");
            } finally {
                console.log("api response finally");
            }
        } else {
            localStorage.removeItem("AkTOKEN");
            sessionStorage.removeItem("AkTOKEN");
            keycloak.logout({ redirectUri: logout_url });
        }
        //      localStorage.removeItem("AkTOKEN");
        // keycloak.logout({ redirectUri: logout_url });

    }
    return (
        <div>
            <Modal
                show={showModal}
                onHide={hideModal}
                className={`logout_modal_main`}
                centered
               
            >
                <Modal.Body>
                    <div className="text-center">
                       <div className='areyoulog'> Are you sure you want to log out?</div>
                    </div>
                    <div  className="text-center">
                        Any unsaved changes may be lost. Please save your work and close all other Akunah tabs before logging out.
                    </div>
                </Modal.Body>
                <Modal.Footer className="text-center m-auto">
                    <Button variant="default" className="btn_outline" onClick={hideModal}>
                        Cancel
                    </Button>
                    <Button
                        variant=""
                        className="btn_green"
                        onClick={() => confirmModalLogout()}
                    >
                        Confirm
                    </Button>
                </Modal.Footer>
            </Modal>
        </div>
    )
}

import React, { useState } from 'react'
import "../data-export/SandOtp.css"

import Button from "../custom/button/Button";

import {  useSelector } from "react-redux";

import OtpInput from '../data-export/OtpInput';

export default function AuditOtp({ statusmodel, handleExport,setOtp,validationerr }) {


    const login = useSelector((state) => state.mainReducer.loginData.arr);
    const [onetimepassword, setOneTimepassword] = useState("");
   
    const [Message, setMessage] = useState("")
  
    const [resetotp, setResetotp] = useState("")

    const handleCallback = (childData) => {
        setOneTimepassword(String(childData.join('')))
        setOtp(String(childData.join('')))

    }

    const handleReset = async () => {
        setResetotp(new Date().getMilliseconds())
        setMessage("");
    }

   

    return (
        <div >
            {/* {isLoading ? (
        <LoadingSpinner />
      ) : ( */}

            <div class="d-flex justify-content-center align-items-center continer m-0">
                <div class="position-relative">
                    {statusmodel ? (
                        <div class="card p-2 text-center">

                            <div> <span>We have sent an OTP at <b>{login.ing_email} </b><br /> The OTP expires in 5 minutes. </span> </div>
                            <OtpInput handleCallback={handleCallback} resetotp={resetotp} />
                            {Message && <span className='text-danger'>{Message}</span>}
                            {onetimepassword.length === 6 ? null : (
                                <>
                                    {validationerr && <span className='text-danger'>Please enter OTP</span>}
                                </>
                            )}

                            <div class="mt-4">
                                <Button buttonStyle="btn_fill mr_20" value='Reset' onClick={() => handleReset()} style={{ cursor: "pointer" }}></Button>
                              
                                    <Button
                                        onClick={() => handleExport()}
                                        value="Submit"
                                        buttonStyle="btn_outline  f_600"
                                        width="160px"
                                    />
                               
                            </div>
                        </div>
                    ) : (
                        <>
                            <div class="card p-2 ">
                                <h6>Please wait for 5 minutes before attempting to request a new one time password to download your data.</h6>
                            </div>
                        </>
                    )}
                </div>
            </div>
        </div>
    )
}

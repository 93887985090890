import React from "react";
import { useState, useEffect } from "react";
import DataExportRoute from "./DataExportRoute";
import { useNavigate } from "react-router-dom";
import Audittool from "../../images/Audittool.png";
import radiology from "../../images/dasboardicon/Radiology.svg";
import DataExportTableNew from "./DataExportTableNew";
import dashboardStyles from "../dashboard/Dashboard.module.css";
import userIcon from "../../images/user-plus.svg";
import Button from "../custom/button/Button";
import file_icon from "../../images/file_icon.png";
import active from "../../images/tick.png";
import AuditTool from "../AuditTool/AuditTool";
import breadcrumbStyles from "../custom/akunah-analyse/DocumentInnerBreadcrumb.module.css";
import { Tabs, Tab } from "react-bootstrap";
import { useSelector } from "react-redux";
import useAxios from "../../axiosinstance";
import AuditToolData from "../AuditTool/AuditToolData";
import Message from "../custom/toster/Message";
export default function Reports() {
  const axiosInstance = useAxios();
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const [exportData, setExportData] = useState([]);
  const [activeloadings, setactiveLoadings] = useState(true);
  const [completedloading, setcompletedLoading] = useState(true);
  const [auditData, setAuditData] = useState([]);
  const [limit] = useState(10);
  const [offset, setOffset] = useState(10);
  const [reff, setReff] = useState("");
  const [messageType, setMessageType] = useState();
  const [message, setMessage] = useState("");

  const navigate = useNavigate();
  const [key, setKey] = useState("audit");
  const [keyID, setKeyID] = useState(1);
  
  const handleSelect = (key) => {
    // if (key === "export") {
    //   setKey(key);
    //   setKeyID(0);
    // } else 
    if (key === "audit") {
      setKey(key);
      setKeyID(1);
    }
  };

  const handleDataExport = () => {
    navigate("/advance-data-export");
  };

  const handleAuditExport = () => {
    navigate("/Adutitool");
  };

  const handleClickHome = () => {
    navigate("/reports");
  };

  const handleAuditData = (e) => {
    setAuditData(e);
  };

  const getDataExportData = async () => {
    try {
      const response = await axiosInstance.current.get(
        `extapp/doctors/get_job_status/${login.ing_ak_id}?limit=${limit}&offset=0`
      );
      if (response.status === 200) {
        setExportData(response.data.data);
        // const inProgressOrQueue = response.data.data.some(
        //   (item) => item.status === "inprogress" || item.status === "queue"
        // );

        // if (inProgressOrQueue) {
        //   setMessageType("success");
        //   setMessage("Export is being Processed");
        // }
        setactiveLoadings(false);
      }
      setTimeout(() => {
        setMessage(false);
      }, 3000);
    } catch (err) {
      setactiveLoadings(false);
      //   setHasMore(false);
    }
  };

  useEffect(() => {
    getDataExportData();
  }, [reff]);

  const addMoreData = async () => {
    setOffset(offset + 10);
    const response = await axiosInstance.current.get(
      `extapp/doctors/get_job_status/${login.ing_ak_id}?limit=${limit}&offset=${offset}`
    );
    const resdata = response.data.data;
    setTimeout(() => {
      setExportData(exportData.concat(resdata));
    }, 500);
  };

  const fetchMoreData = () => {
    addMoreData();
  };


  return (
    <>
      {message && <Message message={message} type={messageType} />}
      <div className="row">
        <div className="col-md-6 col-sm-6">
          <ul className={`${breadcrumbStyles.breadcrumb} mt-4`}>
            <li className="c_pointer" onClick={() => handleClickHome()}>
              Home
            </li>
            <li className="c_pointer" onClick={() => handleClickHome()}>
              Reports
            </li>
          </ul>
        </div>
      </div>
      <div class="row page_header">
        <div class="col-sm-6 col-md-6">
          <h4 class="ph_title">Reports</h4>
        </div>
      </div>
      <div className="start_new_case mb_100">
        <div
          className={`row ${dashboardStyles.card_individual_sec}`}
          style={{ paddingTop: "26px" }}
        >
          {/* <div className={`col-sm-6`}>
            <div className={`whitebox text-center ${dashboardStyles.whitebox}`}>
              <div className="box-content">
                <img
                  src={file_icon}
                  alt="user-logo"
                  style={{ height: "39px" }}
                />
                <h4 className="f_700">Advanced Data Export</h4>
                <p className="f_30 f_400" style={{ fontSize: "14px" }}>
                  Export data using specific filters for data <br /> collection
                  purpose or research
                </p>
                <Button
                  border=""
                  color=""
                  minHeight="40px"
                  height=""
                  onClick={handleDataExport}
                  radius="20px"
                  width="140px"
                  fontSize="14px"
                  buttonStyle="btn_outline f_600"
                  value="New Export "
                >
                  New Export
                </Button>
              </div>
            </div>
          </div> */}
          
          <div className="col mx-auto" style={{ display: "flex", justifyContent: "center" }}>
            <div className={`whitebox text-center ${dashboardStyles.whitebox}`} style={{width:"51%"}}>
              <div className="box-content">
                <img src={Audittool} alt="user-logo" />
                <h4 className="f_700">Audit Tool</h4>
                <p className="f_30 f_400" style={{ fontSize: "14px" }}>
                  Export a list of events, treatment notes, and <br /> PROMs for
                  a specific time period
                </p>
                <Button
                  border=""
                  color=""
                  minHeight="40px"
                  height=""
                  onClick={handleAuditExport}
                  radius="20px"
                  width="140px"
                  fontSize="14px"
                  buttonStyle="btn_outline f_600"
                  value="Generate"
                >
                  Generate
                </Button>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div style={{ marginTop: "-54px" }}>
        <div className="my_visit_screen org_tab mb_100">
          <Tabs
            id="case-list-tabs"
            activeKey={key}
            onSelect={handleSelect}
            className="border-0"
          
          >
            <Tab eventKey="exp" title={<span style={{ color: 'grey'}}>Advanced Data Exports</span>}
           
           >
              {keyID === 0 && (
                <>
                  {activeloadings && (
                    <div class="d-flex justify-content-center"
                    >
                      <div
                        class="spinner-border"
                        role="status"
                        style={{ color: "#3f2783" }}
                      >
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  )}
                  {!activeloadings && exportData.length > 0 && (
                    <div className="panel_new pt-lg-0">
                      <p
                        style={{
                          paddingTop: 10,
                          paddingLeft: 20,
                          fontWeight: 500,
                        }}
                      >
                        All your past and recent Advanced Data exports are available for download here.
                      </p>
                      <br></br>

                      <DataExportTableNew
                        data={exportData}
                        setMessageType={setMessageType}
                        setMessage={setMessage}
                        reff={"true"}
                        setReff={setReff}
                        // setExportData={setExportData}
                        updatePagination={fetchMoreData}
                        setOffset={setOffset}
                      />
                    </div>
                  )}
                  {!activeloadings && exportData.length === 0 && (
                    <div className="panel_new pt-lg-0">
                      <p
                        style={{
                          paddingTop: 10,
                          paddingLeft: 20,
                          fontWeight: 500,
                        }}
                      >
                        All your past and recent Advanced Data exports are available for download here.
                      </p>
                      <div
                        style={{
                          backgroundColor: "#fff",
                          borderRadius: "17px",
                          marginBottom: "-13px",
                          marginTop: "-15px",
                          minHeight: "80px",
                        }}
                      >
                        <div className="no_data">{"No data found"}</div>
                      </div>
                    </div>
                  )}
                </>
              )}
            </Tab>
            <Tab eventKey="audit" title={"Audit Tool Exports"}>
              {/* {keyID === 1 && (
                <>
                 {activeloadings && (
                    <div class="d-flex justify-content-center text-primary">
                      <div
                        class="spinner-border"
                        role="status"
                        style={{ color: "#3f2783" }}
                      >
                        <span class="sr-only"></span>
                      </div>
                    </div>
                  )}
                  {!activeloadings &&( */}
              {/* {auditData.length !== 0 ? (
                <> */}
              <div className="panel_new pt-lg-0">
                <p
                  style={{
                    paddingTop: 10,
                    paddingLeft: 20,
                    fontWeight: 500,
                  }}
                >
                  All your past and recent Audit Tool exports are available for download here.
                </p>
                <br></br>
                <AuditToolData keyID={keyID}/>
              </div>
              {/* </>
              ) : (
                <>
                  <div
                    style={{
                      backgroundColor: "#fff",
                      borderRadius: "17px",
                      marginBottom: "-13px",
                      marginTop: "-15px",
                      minHeight: "80px",
                    }}
                  >
                    <div className="no_data">{"No data found"}</div>
                  </div>
                </>
              )} */}
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
}

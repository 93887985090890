import React, { useState ,useEffect} from 'react';
import "./SandOtp.css";

function OtpInput({handleCallback,resetotp}) {
  const [otp, setOtp] = useState(['', '', '', '', '', '']);
  // const [disable, setDisable] = useState(true);

   useEffect(()=>{
    handleCallback(otp)
   },[otp])
   useEffect(()=>{
    setOtp(['', '', '', '', '', ''])
   },[resetotp])
  const handleChange = (value, index) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };

  const handleSubmit = (event) => {
    // props.parentCallback(otp[0]);
    // const data = new FormData(event.target);

    event.preventDefault();
  };

  const inputFocus = (event) => {
    if (event.key === "Delete" || event.key === "Backspace") {
      const next = event.target.tabIndex - 2;
      if (next > -1) {
        event.target.form.elements[next].focus();
      }
    } else {
      console.log("next");
      const next = event.target.tabIndex;
      if (next < 6) {
        event.target.form.elements[next].focus();
      }
    }
  };

  return (
    <form onSubmit={handleSubmit}>
      <div id="otp" className="inputs d-flex flex-row justify-content-center mt-2">
        {otp.map((value, index) => (
          <input
            key={index}
            name={`otp${index + 1}`}
            type="text"
            autoComplete="off"
            className="m-2 text-center rounded"
            value={value}
            maxLength="1"
            tabIndex={index + 1}
            onChange={(event) => handleChange(event.target.value, index)}
            onKeyUp={inputFocus}
          />
        ))}
      </div>
    </form>
  );
}

export default OtpInput;

import React,{useState} from 'react'
import InputField from "../inputfield/InputField";
import Modal from "react-bootstrap/Modal";
import useAxios from '../../../axiosinstance';
import Button from '../button/Button';
import { useDispatch , useSelector } from 'react-redux';
import { noteRefresh } from '../../../redux/actions';
import { Validators} from "../../../utilities/Validator"
export default function Editnote({showModal,handleClose,message,notetitle,
  notedescription}) {
  const axiosInstance = useAxios();
  const dispatch =useDispatch()
    const [noteTitle, setNoteTitle] = useState(notetitle);
    const dataRefe = useSelector((state) => state.mainReducer.noteRef.arr);
    const [addNotes, setAddNotes] = useState(notedescription);
    // const[show,setShow]=useState(showModal)
    const titleChange = (event) => {
        setNoteTitle(event);
      };
      const descriptionChange = (event) => {
        setAddNotes(event);
      };
  // const handleClose = () => setShowModal(false);
  const handleSave = () => {
    const json = JSON.stringify({
      note_title: noteTitle,
      note_content: addNotes,
    });
    axiosInstance.current
      .put(
        `extapp/forms/note?resource_id=${message}`,
        json,
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      )
      .then((res) => {
        // setShowModal(false);
        dispatch(noteRefresh(!dataRefe))
        handleClose(false)
      });
  };
  return (
    <div>
          <Modal
            show={showModal}
            onHide={handleClose}
            backdrop="static"
            // keyboard={false}
            dialogClassName="modal-md patient_notes_popup"
          >
            <Modal.Header closeButton>
              <Modal.Title className="text-center">Edit Note</Modal.Title>
            </Modal.Header>
            <Modal.Body className="">
              <InputField
                label="Note Title"
                value={noteTitle}
                type="text"
                placeholder="Enter note title here"
                styleClass="br_10 mxw_100 mb_20"
                onChange={titleChange}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                  {
                    check: Validators.maxText,
                    message: "Only 100 Character Allow",
                  },
                  {
                    check: Validators.minText,
                    message: "Title should be minimum three characters",
                  },
                ]}
              />
              <InputField
                label="Add Notes"
                value={addNotes}
                type="textarea"
                placeholder="Enter your note here"
                rows="4"
                cols="20"
                styleClass="br_10"
                onChange={descriptionChange}
                validators={[
                  {
                    check: Validators.required,
                    message: "This field is required",
                  },
                  {
                    check: Validators.minText,
                    message: "Notes should be minimum three characters",
                  },
                ]}
              />
            </Modal.Body>
            <Modal.Footer style={{ justifyContent: "flex-start" }}>
              {/* <Button
                onClick={handleClose}
                value="Cancel"
                buttonStyle="btn_nill mr_20"
              /> */}
              <Button
                value="Save"
                width="120px"
                buttonStyle="btn_outline mr_20"
                onClick={()=>handleSave()}
              />
            </Modal.Footer>
          </Modal>
    </div>
  )
}

import React from 'react'
import { useNavigate } from "react-router-dom";
import breadcrumbStyles from "../radiology/document-inner-breadcrumb/DocumentInnerBreadcrumb.module.css"
export default function DataExportRoute({title,reports}) {
    const navigate = useNavigate();

  const handleClickHome = () => {
    navigate("/")
  }
  const handleClickReports = () => {
    navigate("/Reports")
  }
  const handleClickExports = () => {
    navigate("/advance-data-export")
  }
  return (
    <div className="">
    <div className="row">
      <div className="col-md-6 col-sm-6">
        <ul className={`${breadcrumbStyles.breadcrumb} mt-4`}>
          <li className="c_pointer" onClick={()=>handleClickHome()}>Home</li>
          {!reports &&(
            <>
            <li className="c_pointer" onClick={()=>handleClickReports()}>Reports</li>
          <li className="c_pointer" onClick={()=>handleClickExports()}>Advanced Data Export</li>
          </>)}
          <li className="c_pointer f_600" >{title}</li>
        </ul>
      </div>



    </div>
  </div>
  )
}

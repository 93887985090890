import React from 'react'
import { useNavigate } from "react-router-dom";


export default function Unauthorized() {
    const navigate = useNavigate()
    
    return (
        <div className="whitebox">
            <div className="col-md-12 col-sm-12 col-12">
                <section className="main-page">
                    <div className="content mt-1">
                        <div className="body-content inner-body-content" style={{ "padding": " 90px 70px", "textAlign": "center" }}>
                        <div className="error-page"
                                style={{
                                    "textAlign": "start",
                                    "padding": "30px",
                                    "maxWidth": "630px",
                                    "border": "1px solid #ccc",
                                    "borderRadius": "10px",
                                    "margin": "auto"
                                }}>
                                <h4 className='mb-2' style={{ "color": "#dc3545" }}>Alert: Multiple logins detected</h4>
                                <p className='mb-2' style={{ "color": "black", "fontWeight": "500" }}>To safeguard your data privacy and security, the webpage has timed out.</p>
                                <p className='mb-4' style={{ "color": "black", "fontWeight": "500" }}>Kindly close all other Akunah tabs and press “Back to home” to continue.</p>
                                <p className='mb-2' style={{ "color": "black", "fontWeight": "500" }}><i>Akunah Team is dedicated to protecting your data.</i></p>
                            </div>
                            <span
                                onClick={() => navigate("/")}
                                className="c_pointer"
                                style={{
                                    'width': "180px",
                                    'height': "46px",
                                    'border': "2px solid #3F2783",
                                    'borderRadius': "15px",
                                    'fontSize': "18px",
                                    'color': " #fff",
                                    'display': "inline-block",
                                    'lineHeight': "41px",
                                    'textAlign': "center",
                                    'background': "#3F2783",
                                    "marginTop": "20px"
                                }}

                            >
                                Back to home
                            </span>
                        </div>

                    </div>
                </section>
            </div >
        </div >
    )
}

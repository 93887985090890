
const initialState = {
    LebalTear:[
        {
  item1: [],
  item2: [],
  item3: []
        }
    ]
};

const LabralTearReducer = (state = initialState, action) => {
  switch (action.type) {
    case 'UPDATE_LABRAL_TEAR':
        const satf = action.data.value;
        const satK = action.data.key;
        const satNewarr = state.LebalTear.map((object) => {
          return { ...object, [satK]: satf };
        });
        return { ...state, LebalTear: satNewarr };
    default:
      return state;
  }
};



export default LabralTearReducer;

const initialState = {
    painScore: [
        {
            painTodayRating: "0",
            painHandR: "0",
            painHandL: "0",
            painTodayRatingL: "0",
            howBadThisMomentR: "0",
            howBadThisMomentL: "0"
        },
    ],
    symptomsScore: [
        {
            severeR: "0",
            severeL: "0",
            sensationR: "0",
            sensationL: "0",
            apperanceR: "0",
            apperanceL: "0",
            percent: "100",
            percentL: "100"
        }
    ],
    functionScore: [
        {
            overallR: "0",
            overallL: "0",
            oftenR: "0",
            oftenL: "0",
            longerR: "0",
            longerL: "0",
            tightR: "0",
            tightL: "0",
            fryingR: "0",
            fryingL: "0",
            buttonR: "0",
            buttonL: "0",
            householdR: "0",
            householdL: "0",
            carryR: "0",
            carryL: "0",
            washR: "0",
            washL: "0",
            knifeR: "0",
            knifeL: "0",
            activitiesR: "0",
            activitiesL: "0",
            sleepR: "0",
            sleepL: "0",
            usualR: "0",
            usualL: "0",
            workR: "0",
            workL: "0",
            spendingR: "0",
            spendingL: "0",
            instrumentR: "0",
            instrumentL: "0",
            playingR: "0",
            playingL: "0",
            spendR: "0",
            spendL: "0",
            socialR: "0",
            socialL: "0",
            limitR: "0",
            limitL: "0",
            SpecifyR: "0",
            SpecifyL: "0",
            checkR: false,
            checkL: false,
            textR: "",
            textL: "",
            SpecifysportR: "0",
            SpecifysportL: "0",
            checksportR: false,
            checksportL: false,
            textsportR: "",
            textsportL: "",
            applicableR: "",
            applicableL: "",
        }
    ],
    qolScore: [
        {
            problem: "",
            washing: "",
            act: "",
            pain: "",
            depression: "", 
            handpercent: "100",
        }
    ],
    satisfactionScore: [
        {
            motionR: "4",
            motionL: "4",
            wristR: "4",
            wristL: "4",
            handR: "4",
            handL: "4",
            wristsurR: "",
            wristsurL: "",
            checkR: false,
            checkL: false,
            applicableR: false,
            applicableL: false,
            applicable1R: false,
            applicable1L: false,
            applicable2R: false,
            applicable2L: false,
            applicable3R: false,
            applicable3L: false,
        }
    ],
    bctq: [
        {
            severeR: "0",
            severeL: "0",
            oftenR: "0",
            oftenL: "0",
            typicallyR: "0",
            typicallyL: "0",
            daytimeR: "0",
            daytimeL: "0",
            averageR: "0",
            averageL: "0",
            numbnessR: "0",
            numbnessL: "0",
            weaknessR: "0",
            weaknessL: "0",
            tinglingR: "0",
            tinglingL: "0",
            nightR: "0",
            nightL: "0",
            duringR: "0",
            duringL: "0",
            DifficultyR: "0",
            DifficultyL: "0",
            WritingR: "0",
            WritingL: "0",
            ButtoningR: "0",
            ButtoningL: "0",
            HoldingR: "0",
            HoldingL: "0",
            GrippingR: "0",
            GrippingL: "0",
            OpeningR: "0",
            OpeningL: "0",
            HouseholdR: "0",
            HouseholdL: "0",
            CarryingR: "0",
            CarryingL: "0",
            BathingR: "0",
            BathingL: "0",
        }
    ],
    uramScore: [
        {
            flannelR: "0",
            flannelL: "0",
            faceR: "0",
            faceL: "0",
            bottleR: "0",
            bottleL: "0",
            shakeR: "0",
            shakeL: "0",
            strokeR: "0",
            strokeL: "0",
            clapR: "0",
            clapL: "0",
            spreadR: "0",
            spreadL: "0",
            leeanR: "0",
            leeanL: "0",
            smallR: "0",
            smallL: "0",
        }
    ],
    TotalScores: [
        {
            quickdisabilityTR: 0,
            quickdisabilityTL: 0,
            quickworkTR: 0,
            quickworkTL: 0,
            quicksportTR: 0,
            quicksportTL: 0,
            bmhqTR: 0,
            bmhqTL: 0,
            vas: 0,
            vasL: 0,
            sane: 0,
            saneL: 0,
            eqvas: 0,
            // eqvasL : 0,
            BctqSymptomTR: 0,
            BctqSymptomTL: 0,
            BctqFunctionTR: 0,
            BctqFunctionTL: 0,

            eq5dproblem: 0,
            // eq5dproblemL : 0,
            eq5dwashing: 0,
            // eq5dwashingL : 0,
            eq5dact: 0,
            // eq5dactL : 0,
            eq5dpain: 0,
            // eq5dpainL : 0,
            eq5ddepression: 0,
            // eq5ddepressionL : 0,
            uramR: 0,
            uramL: 0,
            wristsurR: "",
            wristsurL: "",
        }
    ],
    doctorFeedback: [
        {
            ScarsL: "",
            ScarsLText: "",
            ScarsR: "",
            ScarsRtext: "",
            AtrophyL: "",
            AtrophyLText: "",
            AtrophyR: "",
            AtrophyRText: "",
            DeformityL: "",
            deformityTextLI: [],
            deformityLI: "",
            DeformityR: "",
            deformityRI: [],
            deformityTextRI: "",
            Weinstein1R: "",
            Weinstein2R: "",
            Weinstein3R: "",
            Weinstein4R: "",
            Weinstein5R: "",
            Weinstein6R: "",
            Weinstein7R: "",
            discrimination1R: "",
            discrimination2R: "",
            discrimination3R: "",
            discrimination4R: "",
            discrimination5R: "",
            discrimination6R: "",
            discrimination7R: "",
            Weinstein1L: "",
            Weinstein2L: "",
            Weinstein3L: "",
            Weinstein4L: "",
            Weinstein5L: "",
            Weinstein6L: "",
            Weinstein7L: "",
            discrimination1L: "",
            discrimination2L: "",
            discrimination3L: "",
            discrimination4L: "",
            discrimination5L: "",
            discrimination6L: "",
            discrimination7L: "",
            deformityR: 0,
            deformityL: 0,
            deformityTextR: "",
            deformityTextL: "",
            wristflexionR: "",
            wristflexionL: "",
            wristextensionR: "",
            wristextensionL: "",
            wristflexionPR: "",
            wristflexionPL: "",
            wristextensionPR: "",
            wristextensionPL: "",
            radialDeviationR: "",
            radialDeviationL: "",
            radialDeviationPR: "",
            radialDeviationPL: "",
            ulnarR: "",
            ulnarL: "",
            ulnarPR: "",
            ulnarPL: "",
            actPalmerR: "",
            actPalmerL: "",
            pasPalmerR: "",
            pasPalmerL: "",
            actRadialR: "",
            actRadialL: "",
            pasRadialR: "",
            pasRadialL: "",
            MCPR: "",
            MCPL: "",
            actMCPR: "",
            actMCPL: "",
            passLeftMCPR: "",
            passLeftMCPL: "",
            LeftIPR: "",
            LeftIPL: "",
            actIPR: "",
            actIPL: "",
            passLeftIPR: "",
            passLeftIPL: "",
            IPR: "",
            IPL: "",
            ExtIndexmcpR: "",
            ExtIndexmcpL: "",
            LeftExtIndexmcpR: "",
            LeftExtIndexmcpL: "",
            actIndexdipR: "",
            actIndexdipL: "",
            actIndexpipR: "",
            actIndexpipL: "",
            passLeftIndexpipR: "",
            passLeftIndexpipL: "",
            passLeftIndexdipR: "",
            passLeftIndexdipL: "",
            LeftMCPR: "",
            LeftMCPL: "",
            LeftIPR: "",
            LeftIPL: "",
            IndexmcpR: "",
            IndexmcpL: "",
            IndexpipR: "",
            IndexpipL: "",
            IndexdipR: "",
            IndexdipL: "",
            LeftIndexmcpR: "",
            LeftIndexmcpL: "",
            LeftIndexpipR: "",
            LeftIndexpipL: "",
            LeftIndexdipR: "",
            LeftIndexdipL: "",
            MiddlemcpR: "",
            MiddlemcpL: "",
            MiddlepipR: "",
            MiddlepipL: "",
            MiddledipR: "",
            MiddledipL: "",
            LeftMiddlemcpR: "",
            LeftMiddlemcpL: "",
            LeftMiddlepipR: "",
            LeftMiddlepipL: "",
            LeftMiddledipR: "",
            LeftMiddledipL: "",
            RingmcpR: "",
            RingmcpL: "",
            RingpipR: "",
            RingpipL: "",
            RingdipR: "",
            RingdipL: "",
            LeftRingmcpR: "",
            LeftRingmcpL: "",
            LeftRingpipR: "",
            LeftRingpipL: "",
            LeftRingdipR: "",
            LeftRingdipL: "",
            SmallmcpR: "",
            SmallmcpL: "",
            SmallpipR: "",
            SmallpipL: "",
            SmalldipR: "",
            SmalldipL: "",
            LeftSmallmcpR: "",
            LeftSmallmcpL: "",
            LeftSmallpipR: "",
            LeftSmallpipL: "",
            LeftSmalldipR: "",
            LeftSmalldipL: "",
            wristR: "",
            wristL: "",
            ThumbR: "",
            ThumbL: "",
            FingerR: "",
            FingerL: "",
            ThumbpalmarR: "",
            ThumbpalmarL: "",
            ThumbsmallR: "",
            ThumbsmallL: "",
            FingerflexionR: "",
            FingerflexionL: "",
            FingerabductionR: "",
            FingerabductionL: "",
            OksingR: "",
            OksingL: "",
            GripR: "",
            GripL: "",
            SpecialtestsR: "",
            SpecialtestsTextR: "",
            SpecialtestsL: "",
            SpecialtestsTextL: "",
            Grind1LeftVal: "",
            Grind2LeftVal: "",
            Grind3LeftVal: "",
            Grind4LeftVal: "",
            Grind5LeftVal: "",
            Grind6LeftVal: "",
            Grind7LeftVal: "",
            Grind8LeftVal: "",
            Grind9LeftVal: "",
            Grind10LeftVal: "",
            Grind11LeftVal: "",
            Grind12LeftVal: "",
            Grind13LeftVal: "",
            Grind14LeftVal: "",
            Grind15LeftVal: "",
            Grind16LeftVal: "",
            Grind17LeftVal: "",
            Grind18LeftVal: "",
            Grind19LeftVal: "",
            Grind20LeftVal: "",
            Grind21LeftVal: "",
            Grind22LeftVal: "",
            Grind23LeftVal: "",
            Grind24LeftVal: "",
            Grind25LeftVal: "",
            Grind26LeftVal: "",
            Grind27LeftVal: "",
            Grind28LeftVal: "",
            Grind29LeftVal: "",
            Grind30LeftVal: "",
            Grind31LeftVal: "",
            Grind32LeftVal: "",
            Grind33LeftVal: "",
            Grind34LeftVal: "",
            Grind35LeftVal: "",
            Grind36LeftVal: "",
            Grind37LeftVal: "",
            Grind38LeftVal: "",
            Grind39LeftVal: "",
            Grind40LeftVal: "",
            Grind41LeftVal: "",
            Grind42LeftVal: "",
            Grind43LeftVal: "",
            Grind44LeftVal: "",


            ActMiddlemcpR: "",
            ActMiddlemcpL: "",
            PasLeftMiddlemcpR: "",
            PasLeftMiddlemcpL: "",
            ActMiddledipR: "",
            ActMiddledipL: "",
            pasLeftMiddledipR: "",
            pasLeftMiddledipL: "",
            ActMiddlepipR: "",
            ActMiddlepipL: "",
            pasLeftMiddlepipR: "",
            pasLeftMiddlepipL: "",

            ActRingmcpR: "",
            ActRingmcpL: "",
            PasLeftRingmcpR: "",
            PasLeftRingmcpL: "",
            Act1RingdipR: "",
            Act1RingdipL: "",
            Pas2LeftRingdipR: "",
            Pas2LeftRingdipL: "",
            ActRingpipR: "",
            ActRingpipL: "",
            PasLeftRingpipR: "",
            PasLeftRingpipL: "",

                    ActSmallmcpR: "",
            ActSmallmcpL: "",
            PasLeftSmallmcpR: "",
            PasLeftSmallmcpL: "",
            ActSmalldipR: "",
            ActSmalldipL: "",
            PasLeftSmalldipR: "",
            PasLeftSmalldipL: "",
            ActSmallpipR: "",
            ActSmallpipL: "",
            PasLeftSmallpipR: "",
            PasLeftSmallpipL: "",
        }
    ],
    evaluated: "1",
}

const HandWristReducer = (state = initialState, action) => {
    switch (action.type) {
        case "UPDATE_HAND_WRIST_DATA":
            return { ...state, evaluated: action.data };


        case 'UPDATE_HAND_WRIST_PAIN_FIELD_DATA':
            const painFieldVal = action.data.value;
            const painFieldKey = action.data.key;
            const painNewArr = state.painScore.map((object) => {
                return { ...object, [painFieldKey]: painFieldVal };
            });
            return { ...state, painScore: painNewArr };

        case 'UPDATE_HAND_WRIST_PAIN_DATA':
            return { ...state, painScore: action.data };

        case 'UPDATE_HAND_WRIST_SYMPTOMS_FIELD_DATA':
            const fieldVal = action.data.value;
            const fieldKey = action.data.key;
            const newArr = state.symptomsScore.map((object) => {
                return { ...object, [fieldKey]: fieldVal };
            });
            return { ...state, symptomsScore: newArr };

        case 'UPDATE_HAND_WRIST_SYMPTOMS_DATA':
            return { ...state, symptomsScore: action.data };

        case 'UPDATE_HAND_WRIST_FUNCTION_FIELD_DATA':
            const fieldValF = action.data.value;
            const fieldKeyF = action.data.key;
            const newArrF = state.functionScore.map((object) => {
                return { ...object, [fieldKeyF]: fieldValF };
            });
            return { ...state, functionScore: newArrF };

        case 'UPDATE_HAND_WRIST_FUNCTION_DATA':
            return { ...state, functionScore: action.data };

        case 'UPDATE_HAND_WRIST_QOL_FIELD_DATA':
            const qolVal = action.data.value;
            const qolKey = action.data.key;
            const qolNewArr = state.qolScore.map((object) => {
                return { ...object, [qolKey]: qolVal };
            });
            return { ...state, qolScore: qolNewArr };

        case 'UPDATE_HAND_WRIST_QOL_DATA':
            return { ...state, qolScore: action.data };

        case 'UPDATE_HAND_WRIST_SATISFACTION_FIELD_DATA':
            const satVal = action.data.value;
            const satKey = action.data.key;
            const satNewArr = state.satisfactionScore.map((object) => {
                return { ...object, [satKey]: satVal };
            });
            return { ...state, satisfactionScore: satNewArr };

        case 'UPDATE_HAND_WRIST_SATISFACTION_DATA':
            return { ...state, satisfactionScore: action.data };

        case 'UPDATE_HAND_WRIST_BCTQ_FIELD_DATA':
            const satbctq = action.data.value;
            const satbct = action.data.key;
            const satNewArrbctq = state.bctq.map((object) => {
                return { ...object, [satbct]: satbctq };
            });
            return { ...state, bctq: satNewArrbctq };

        case 'UPDATE_HAND_WRIST_BCTQ_DATA':
            return { ...state, bctq: action.data };

        case 'UPDATE_HAND_WRIST_URAM_FIELD_DATA':
            const saturamq = action.data.value;
            const saturam = action.data.key;
            const satNewArruramq = state.uramScore.map((object) => {
                return { ...object, [saturam]: saturamq };
            });
            return { ...state, uramScore: satNewArruramq };

        case 'UPDATE_HAND_WRIST_URAM_DATA':
            return { ...state, uramScore: action.data };


        case 'UPDATE_HAND_WRIST_TOTAL_SCORE':
            return { ...state, TotalScores: action.data };

        case 'UPDATE_HAND_FEEDBACK_FIELD_DATA':
            const satf = action.data.value;
            const satK = action.data.key;
            const satNewarr = state.doctorFeedback.map((object) => {
                return { ...object, [satK]: satf };
            });
            return { ...state, doctorFeedback: satNewarr };
        case 'UPDATE_HAND_FEEDBACK_DATA':
            return { ...state, doctorFeedback: action.data };
        default:
            return state;
    }
}
export default HandWristReducer;
import React from "react";
import { useLanguageContext } from "./contexts/LanguageContext";
import Dropdownno from "./custom/dropdown/Dropdownno";
export default function LanguageHandler() {
  const { language, changeLanguage } = useLanguageContext();

  return (
    <>
      <div className="">
        <Dropdownno
          data={[
            { value: "en", label: "En- English" },
            // { value: 'fr', label: "Fr- France" },
          ]}
          label={''}
          value={language}
          styleClass="language_drp_dwn"
          onChange={changeLanguage}

        />
        {/* <select value={language} onChange={(e) => changeLanguage(e.target.value)} className="form-control form-select">
           <option value="en">En- English </option>
           <option value="fr">Fr- France </option>
         </select> */}
      </div>

      {/* <div className="lang_ d-block d-lg-none">
        <select value={language} onChange={(e) => changeLanguage(e.target.value)}>
          <option value="en">En </option>
          <option value="fr">Fr </option>
        </select>
      </div> */}

    </>
  );
}

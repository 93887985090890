import React, { useState, useEffect } from "react";
import { useTable } from "react-table";

import { useSelector, useDispatch } from "react-redux";

import useAxios from "../../../axiosinstance";
import Message from "../../custom/toster/Message";
import { noteRefresh } from "../../../redux/actions";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../custom/loader/LoadingSpinner";
import useTranslation from "../../customHooks/translations";
import DeleteConfirmation from "../../custom/delete-confirmation/DeleteConfirmation";
const HcpIncoming = ({ data,setReffs }) => {
  const translation = useTranslation();
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const reff = useSelector((state) => state.mainReducer.noteRef.arr);
  const { akid } = useParams();
  const dispatch = useDispatch();
  const axiosInstance = useAxios();
  const [show, setShow] = useState(false);
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState();
  const [isLoading, setIsLoading] = useState(true);
  const [localData, setLocalData] = useState(data);
  // const [data, setData] = useState([]);
  const [displayConfirmationModalDelete, setDisplayConfirmationModalDelete] =
    useState(false);
  const [resource_id, setresource_id] = useState("");
  const [org_id, setOrg_id] = useState("");
  const handleRequest = async (action, id, org_id) => {
    setresource_id(id);
    setOrg_id(org_id);
    if (action === "accept") {
      try {
        const response = await axiosInstance.current.put(
          `/extapp/doctors/updateIncomingReq?ak_id=${login.ing_ak_id}&akou_id=${org_id}&accept=1&rule_id=${id}`
        );

        if (response.status === 200) {
          setLocalData((prevData) =>
            prevData.map((item) =>
              item.rule_id === id ? { ...item, accept: 1 } : item
            )
          );
          setReffs(new Date().getMilliseconds());
          setMessageType("success");
          setMessage("Request approved");
        }
        setTimeout(() => {
          setMessage(false);
        }, 3000);
      } catch (error) {
      } finally {
      }
    } else {
      setDisplayConfirmationModalDelete(true);
    }
  };

  const hideConfirmationModal = () => {
    setDisplayConfirmationModalDelete(false);
  };

  const submitDelete = async () => {
    try {
      const response = await axiosInstance.current.put(
        `/extapp/doctors/rejectIncomingReq?ak_id=${login.ing_ak_id}&akou_id=${org_id}&accept=-1&rule_id=${resource_id}`
      );

      if (response.status === 200) {
        setLocalData((prevData) =>
          prevData.map((item) =>
            item.rule_id === resource_id ? { ...item, accept: -1 } : item
          )
        );
        setReffs(new Date().getMilliseconds());
        setMessageType("success");
        setMessage("Request rejected");
        setDisplayConfirmationModalDelete(false);
      }
      setTimeout(() => {
        setMessage(false);
      }, 3000);
    } catch (error) {
    } finally {
    }
  };
  useEffect(() => {
    setTimeout(() => {
      setIsLoading(false);
    }, 1000);
  }, []);
  const columns = React.useMemo(
    () => [
      {
        Header: "HCP (s)",
        accessor: "doctor_name",
      },
      {
        Header: "From Organization",
        accessor: "org_name_from",
      },
      {
        Header: "To Organization",
        accessor: "org_name",
      },
      
      {
        Header: "Action",
        headerClassName: "col-action",
        accessor: "action",
        className: "col-action d-flex",
        Cell: (row) => (
          <>
         
            {row.row.original.accept === 0 && (
              <>
                <button
                  className="btn_green me-4"
                  onClick={() =>
                    handleRequest(
                      "accept",
                      row.row.original.rule_id,
                      row.row.original.org_id
                    )
                  }
                  style={{ width: "113px" }}
                >
                  Accept
                </button>
                <button
                  className="btn_danger"
                  onClick={() =>
                    handleRequest(
                      "reject",
                      row.row.original.rule_id,
                      row.row.original.org_id
                    )
                  }
                  style={{
                    width: "113px",
                
                    marginLeft: "5px",
                  }}
                >
                  Reject
                </button>
              </>
            )}
           
             <span>
            {row.row.original.accept === -1 && (
            <button
            className="btn_danger_1 clk_btn_disbled"
       
           disabled={true}
            style={{
              width: "113px",
              marginLeft: "5px",
            }}
          >
            Rejected
          </button>
            )}
            {row.row.original.accept === 1 && (
              <>
               <div style={{ display: "flex", gap: "2px" }}>
            <button
            className="btn_green_1 me-4 clk_btn_disbled"
            // onClick={() =>
            //   handleRequest(
            //     "accept",
            //     row.row.original.rule_id,
            //     row.row.original.org_id
            //   )
            // }
            disabled
            style={{ width: "113px" }}
          >
           Accepted 
          </button>
              <button
                className="btn_danger"
           
                onClick={() => handleRequest("reject",row.row.original.rule_id,row.row.original.org_id)}
                style={{
                  width: "113px",
                  marginLeft: "5px",
                }}
              >
                Reject
              </button>
              </div>
              </>
            )}
          </span>
        
          </>
        ),
      },
    ],
    [localData]
  );

  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } =
    useTable({
      columns,
      data:localData,
    });
  return (
    <>
      {isLoading ? (
        <LoadingSpinner />
      ) : (
        <>
          {message && <Message message={message} type={messageType} />}
          <div className="tableContainer" id="rpScrollableDiv">
            <table {...getTableProps()} className="custom-tbl">
              <thead>
                {headerGroups.map((headerGroup) => (
                  <tr {...headerGroup.getHeaderGroupProps()}>
                    {headerGroup.headers?.map((column) => (
                      <th
                        {...column.getHeaderProps([
                          { className: column.headerClassName },
                        ])}
                      >
                        {column.render("Header")}
                      </th>
                    ))}
                  </tr>
                ))}
              </thead>
              <tbody {...getTableBodyProps()}>
                {rows.map((row) => {
                  prepareRow(row);
                  return (
                    <tr {...row.getRowProps()}>
                      {row.cells.map((cell) => {
                        return (
                          <td
                            {...cell.getCellProps([
                              { className: cell.column.className },
                            ])}
                          >
                            {cell.render("Cell")}
                          </td>
                        );
                      })}
                    </tr>
                  );
                })}
              </tbody>
            </table>
            {/* <div className="no_data">
                          {translation.No_data_found}
                        </div> */}
          </div>
        </>
      )}
      <DeleteConfirmation
        showModal={displayConfirmationModalDelete}
        confirmModal={submitDelete}
        hideModal={hideConfirmationModal}
        message={""}
        reject={true}
      />
    </>
  );
};

export default HcpIncoming;

const regex = {
    email: new RegExp(
        '^(([^<>()\\[\\]\\\\.,;:\\s@]+(\\.[^<>()\\[\\]\\\\.,;:\\s@]+)*)|(.+))@((\\[[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}\\.[0-9]{1,3}])|(([a-zA-Z\\-0-9]+\\.)+[a-zA-Z]{2,}))$',
    ),
    password: new RegExp(
        '^(?=.*[A-Za-z])(?=.*\\d)[A-Za-z\\d]{2,}$',
    ),
    number: new RegExp('^[0-9]+$'),
};

export class Validators {
    static email(value, message) {
        if (value) {
            const result = regex.email.test(value);
            if (!result) return { error: true, message };
        }
        // return false;

        if (!value || !value.toString().trim().length) {
            return { error: true, message };
        }
        return false;
    }
    static password(value, message) {
        if (value) {
            const result = regex.password.test(value);
            if (!result) return { error: true, message };
        }
        // return false;

        if (!value || !value.toString().trim().length) {
            return { error: true, message };
        }
        return false;
    }

    static required(value, message) {
        if (!value || !value.toString().trim().length) {
            return { error: true, message };
        }
        return false;
    }
    static spacial(value, message) {
        if (/[^a-zA-Z0-9\-\/ ]/.test(value)) {
          return { setErrorValR: true, message };
        }
        
        return false;
      }
    static spacialName(value, message) {
        if (/[^a-zA-Z0-9\- ,_'"]/g.test(value)) {
          return { setErrorValR: true, message };
        }
        return false;
      }

      static betweennumber(value, message) {
        if (value.toString()>100) {
          return { setErrorValR: true, message };
        }
        return false;
      }
      static betweennumber2(value, message) {
        if (value.toString().trim().length > 2) {
            return { error: true, message };
        }
        return false;
      }
    static requiredR(value, message) {
        if (!value || !value.toString().trim().length) {
            return { setErrorValR: true, message };
        }
        return false;
    }
    static requiredL(value, message) {
        if (!value || !value.toString().trim().length) {
            return { setErrorValL: true, message };
        }
        return false;
    }

    static number(value, message) {
        const length = value ? value.toString().length : 0;

        if (length > 0) {
            const result = regex.number.test(value);
            if (!result) {
                return { error: true, message };
            }
        }

        return false;
    }

    static negativenumber(value, message) {
        const length = value ? value.toString().length : 0;
    
        if (length > 0) {
            const result = /^-?\d*\.?\d+$/.test(value); // Modified regex to allow negative numbers
            if (!result) {
                return { error: true, message };
            }
        }
    
        return false;
    }

    static max_refnumber(value, message) {
        if (value.toString().trim().length > 1) {
          return { error: true, message };
        }
        return false;
      }

    static max(value, message) {

        if (value.toString().trim().length >= 14) {
            return { error: true, message };
        }
        return false;
    }

    static min(value, message) {

        if (value.toString().trim().length <= 14) {
            return { error: true, message };
        }
        return false;
    }
    static maxmobile(value, message) {

        if (value.toString().trim().length >= 11) {
            return { error: true, message };
        }
        return false;
    }
    static minmobile(value, message) {

        if (value.toString().trim().length <= 9) {
            return { error: true, message };
        }
        return false;
    }
    static postcode(value, message) {

        if (value.toString().trim().length >= 11) {
            return { error: true, message };
        }
        return false;
    }
    static maxText(value, message) {

        if (value.toString().trim().length >= 101) {
            return { error: true, message };
        }
        return false;
    }
    // static phoneNumber(value, message) {
    //     if (/\./.test(value)) {
    //         return { error: true, message };
    //     }
    //     return false;
    // }
    static phoneNumber(value, message) {
        // Convert value to string before checking
        const stringValue = value.toString();
        if (/\./.test(stringValue)) {
            return { error: true, message };
        }
        return false;
    }
    static maxTextArea(value, message) {

        if (value.toString().trim().length >= 501) {
            return { error: true, message };
        }
        return false;
    }
    static minText(value, message) {

        if (value.toString().trim().length <= 2) {
            return { error: true, message };
        }
        return false;
    }
    static minTextArea(value, message) {

        if (value.toString().trim().length <= 14) {
            return { error: true, message };
        }
        return false;
    }
    static plussign(value, message) {

        var RegExpression = new RegExp(/^\+\d+$/);
        const length = value ? value.length : 0;
    
        if (length > 0) {
          const result = RegExpression.test(value);
          if (!result) {
            return { error: true, message };
          }
        }
        return false;
    }


    static textNum(value, message) {
        var RegExpression = new RegExp(/^[a-zA-Z\s\-\/,\/'\/_]*$/);
        const length = value ? value.length : 0;
    
        if (length > 0) {
          const result = RegExpression.test(value);
          if (!result) {
            return { error: true, message };
          }
        }
    
        return false;
      }
     static maxfifty(value, message) {
         if (value.toString().trim().length >= 50) {
          return { error: true, message };
        }
        return false;
      }
}

export const validateInput = (validators, value) => {
    if (validators && validators.length) {
        for (let i = 0; i < validators.length; i++) {
            const error = validators[i].check(value, validators[i].message);
            if (error) {
                return error;
            }
        }
    }
    return false;
};

export const validateDropdown = (validators, value) => {
    if (validators && validators.length) {
        for (let i = 0; i < validators.length; i++) {
            const error = validators[i].check(value, validators[i].message);
            if (error) {
                return error;
            }
        }
    }
    return false;
};

export const validateCheckbox = (validators, value) => {
    if (validators && validators.length) {
        for (let i = 0; i < validators.length; i++) {
            const error = validators[i].check(value, validators[i].message);
            if (error) {
                return error;
            }
        }
    }
    return false;
};

export const validateRadioSwitch = (validators, value) => {
    if (validators && validators.length) {
        for (let i = 0; i < validators.length; i++) {
            const error = validators[i].check(value, validators[i].message);
            if (error) {
                return error;
            }
        }
    }
    return false;
};

export const spacialCharReplace =(e)=>{
  return  e.replace(/-|,|_/g, " ")
}
import React, { useState, useEffect } from "react";
import settingsStyle from "../Settings.module.css";
import { useSelector } from "react-redux";
import Message from "../../custom/toster/Message";
import useAxios from "../../../axiosinstance";
import Dropdown from "../../custom/dropdown/Dropdown";


function Account() {
  const axiosInstance = useAxios();
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  // const [firstName, setFirstName] = useState(login.ing_firstname);
  // const [lastName, setLastName] = useState(login.ing_lastname);
  // const [doctor, setDoctor] = useState(login.ing_ak_id);
  const [message, setMessage] = useState(false);
  const [messageType, setMessageType] = useState();
  // const firstNameRef = useRef();
  // const lastNameRef = useRef();
  // const translation = useTranslation();

  // const handleSubmit = () => {
  //   // if (!firstName) {
  //   //   firstNameRef.current.errUpdate();
  //   // }
  //   // if (!lastName) {
  //   //   lastNameRef.current.errUpdate();
  //   // }
  //   // if (!firstName || !lastName) {
  //   //       setMessage("Field Required");
  //   //       setMessageType("warning");
  //   // } else {
  //   //   const json = JSON.stringify({
  //   //     firstname: firstName,
  //   //     lastname: lastName,
  //   //   });
  //   //   axiosInstance.current
  //   //     .put(`extapp/doctors/doctor_update_profile?ak_id=${doctor}`, json, {
  //   //       headers: {
  //   //         "Content-Type": "application/json",
  //   //       },
  //   //     })
  //   //     .then((res) => {
  //   //       setMessage(res.data.data.message);
  //   //       setMessageType("success");
  //   //     })
  //   //     .catch((err) => {
  //   //       setMessage(err.message);
  //   //       setMessageType("error");
  //   //     });
  //   // }
  // };

  const handleOnChange = async (index, status) => {
    setMessage(false);
    const response = await axiosInstance.current.put(
      `/extapp/doctors/showHideScoreWidgets/${login.ing_ak_id}?type_id=${formType}&widget=${index}&status=${status}`
    );
    const response1 = await axiosInstance.current.get(
      `extapp/doctors/getSpecialityScoresSettingsOfUser/${login.ing_ak_id}?type_id=${formType} `
    );
    setScoreData(response1.data.data);
    if (response) {
      if (status === "1") {
        setMessage("Specialty is activated");
        setMessageType("success");
      } else {
        setMessage("Specialty is deactivated");
        setMessageType("success");
      }
      setStatus(response?.status);
      setTimeout(() => {
        setMessage(false);
      }, 2000);
    }
  };

  const [form, setItems2] = useState([]);
  const [formType, setformType] = useState("");
  const [scoredata, setScoreData] = useState([]);
  const [status, setStatus] = useState(false);

  const handleFormType = (e) => {
    setformType(e);
  };

  useEffect(() => {

    const getForm = async () => {
      try {
        const response = await axiosInstance.current.get(
          `extapp/doctors/getSpecialityScoresSettingsOfUser/${login.ing_ak_id}?type_id=${formType} `
        );
        setScoreData(response.data.data);
      } catch (error) {
        setScoreData([])
      }

    };
    getForm();
  }, [formType, status]);

  // console.log(scoredata, ".................");

  useEffect(() => {
    const getForm = async () => {
      const response = await axiosInstance.current.get(
        `extapp/resource_type?ak_id=${login.ing_ak_id}&all=${1}`
      );
      setItems2(response.data);
    };
    getForm();
  }, [login]);

  let a = form.filter((items) => items.type_id);
  const options = a.map((item) => {
    return { value: item.type_id, label: item.alias };
  });

  return (
    <div className="">
      <h4 class={`${settingsStyle.content_main_title}`}>{"Visible scores"}</h4>

      {message && <Message message={message} type={messageType} />}
      <div className="row mt-3">
        <div className="col-sm-6">
          <Dropdown
            data={options}
            label=""
            value={formType}
            onChange={(e) => handleFormType(e)}
            styleClass="br_10 mb_20"
            placeholder="Select "
          />
        </div>
      </div>

      <div className="row mb_15">
        {
          // scoredata.length === 0 ? ( <div className="no_data">No data Found</div>) : (
            scoredata.map((ele) => {
              return (
                <div className="col-md-4">
                  <div className="form-group custom_check_box tick">
                    <input
                      className="mr-2"
                      type="checkbox"
                      checked={ele.status}
                      name={ele.score_type}
                      value={ele.status ? 0 : 1}
                      onClick={(e) =>
                        handleOnChange(e.target.name, e.target.value)
                      }
                      defaultValue=""
                      id={ele.score_id}
                      defaultChecked=""
                    />
                    <label className="form-check-label" For={ele.score_id}>
                      {ele.score_name}
                    </label>
                  </div>
                </div>
              );
            })
          // )
        }
      </div>
      <hr></hr>

      {/* <Button
        onClick={handleSubmit}
        value="Edit Profile"
        buttonStyle="btn_outline"
        width="140px"
      /> */}
    </div>
  );
}

export default Account;

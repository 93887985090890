import React from 'react'
import { useState, useCallback, useEffect } from 'react';
import { Form } from "react-bootstrap";
import DatePicker from "react-datepicker";
import useAxios from "../../axiosinstance";
import ButtonLoader from "../custom/button/ButtonLoader";
import { getDateFormat } from "../../utilities/DateFormate";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CreatableSelect from "react-select/creatable";
import Dropdown from "../custom/dropdown/Dropdown";
import Checkbox from "../custom/checkbox/Checkbox";
import Modal from "react-bootstrap/Modal";
import InputField from "../custom/inputfield/InputField";
import Button from "../custom/button/Button";
import breadcrumbStyles from "../custom/akunah-analyse/DocumentInnerBreadcrumb.module.css";
import AuditOtp from './AuditOtp';
import Message from '../custom/toster/Message';

function AuditTool() {

    const login = useSelector((state) => state.mainReducer.loginData.arr);
    let dateFormat = login.ing_date_formate;
    const axiosInstance = useAxios();
    const [edate, setEdate] = useState("");
    const [sdate, setSdate] = useState("");
    const [error, setError] = useState("");
    const [orgvalue, setOrgvalue] = useState([]);
    const [orgdata2, setOrgdata2] = useState([]);
    const [orgerr, setOrgerr] = useState(false);
    const [trendGraph, settrendGraph] = useState("")
    const [isApiPending, setIsApiPending] = useState(false);
    const [lastTypingTime, setLastTypingTime] = useState(null);
    const [offset, setOffset] = useState(10);
    const [orgdata, setOrgdata] = useState([]);
    const [Selectspecialty, setSelectspecialty] = useState("");
    const [options, setOption] = useState([]);
    const [Operativecheck, setOperativecheck] = useState(false);
    const [Treatmentvalue, setTreatmentvalue] = useState("");
    const [eventCheckbox, seteventCheckbox] = useState(false);
    const [promsCheckbox, setpromsCheckbox] = useState(false);
    const [hideCheckbox, setHideCheckbox] = useState(false);
    const [reffress2, setRefress2] = useState("");
    const [showmodelexport, setShowmodelexport] = useState(false);
    const [noteTitle, setnoteTitle] = useState("");
    const [status, setStatus] = useState(false);
    const [submitdisable, setsubmitdisable] = useState(false);
    const [showmodelexport2, setShowmodelexport2] = useState(false);

    const [showModal, setshowModal] = useState(false);
    const [validationerr, setValidationerr] = useState(false);
    const [Otp, setOtp] = useState("");
    const [message, setMessage] = useState("");
    const [messageType, setMessageType] = useState();
    const [show, setShow] = useState(false);
    const navigate = useNavigate()

    const handleOrg = useCallback((inputValue) => {
        setOrgvalue(inputValue);
    });
console.log(orgvalue,"orgvalueorgvalue")
    const handleSdate = (e) => {
        setLastTypingTime(new Date().getTime());
        setSdate(e);
        setOffset(20);
    };

    const handleEdate = (e) => {
        setLastTypingTime(new Date().getTime());
        setEdate(e);
        setOffset(20);
    };

    const AuditToolexport = () => {
    };

    const HandleSelectspecialty = (e) => {
        setSelectspecialty(e);
    };

    const handleOperativecheck = () => {
        setOperativecheck((prevCheck) => !prevCheck);
    };

    const handleTreatmentvalue = (e) => {
        setTreatmentvalue(e);
    };

    const handleeventCheckbox = (e) => {
        seteventCheckbox((prevState) => !prevState);
        setError("");
    };

    const handlepromsvalue = (e) => {
        setpromsCheckbox((prevState) => !prevState);
    };

    const handlehideCheckbox = (e) => {
        setHideCheckbox((prevState) => !prevState)
    }

    const handleCloseexport = () => {
        setShowmodelexport(false);
        setnoteTitle("");
        setsubmitdisable(false);
    };

    const titleChange = (e) => {
        setnoteTitle(e);
    };

    const handleCloseexport2 = () => {
        setShowmodelexport2(false);
    };
    const[resId,setResId]=useState('')
    const handleSendOtp=async()=>{
        // setShowmodelexport(true)
        setIsApiPending(true)
        try {
            const response = await axiosInstance.current.get(
              `extapp/doctors/audit_tool_otp/${login.ing_ak_id}`
            );
            setResId(response.data.resource_id)
            setMessage("OTP sent successfully");
            setMessageType("success");
            setshowModal(true)
            setIsApiPending(false)
          
          } catch (err) {
            setMessage("Invalid OTP");
            setMessageType("error");
            setShow(true);
            setIsApiPending(false)
            setTimeout(() => {
              setShow(false);
              setMessage("");
            }, 3000);
            console.error(err);
          }
     
    }
    const handleSubmitExport2 = async () => {
        // setShowmodelexport(true);
        setShow(true)
        setShowmodelexport2(false);
        handleSendOtp()
    };

    useEffect(() => {
        const getOrgData = async () => {
            try {
                const response = await axiosInstance.current.get(
                    `extapp/doctors/auditReportOrgs?doctor_id=${login.ing_ak_id}`
                );
                setOrgdata(response.data.data);
                setOrgdata2(response.data.data);
            } catch (err) {
                //console.log(err);
            } finally {
                setRefress2(new Date().getMilliseconds());
            }
        };
        getOrgData();
    }, [login, axiosInstance]);

    useEffect(() => {
        if(orgdata?.length>0){
        setOrgdata([
          { value: "Select all", label: "Select all Organization" },
          ...orgdata,
        ]);
        setOrgdata2([
          { value: "Select all", label: "Select all Organization" },
          ...orgdata,
        ]);
      }else{
        setOrgdata([])
        setOrgdata2([])
      }
      }, [reffress2]);
    
      let ddd = orgvalue.filter((item) => item.value === "Select all");
      const [orgvalue2,setOrgvalue2]=useState([])
      useEffect(() => {
        if (ddd.length !== 0) {
          setOrgvalue(orgdata);
          setOrgdata2([])
          setOrgvalue2(ddd)
        } if(ddd.length === 0){
          setOrgvalue(orgvalue);
          setOrgvalue2(orgvalue)
          setOrgdata2(orgdata)
        }
      }, [ddd.length,orgvalue.length]);

    // useEffect(() => {
    //     setOrgdata([
    //         { value: "Select all", label: "Select all Organization" },
    //         ...orgdata,
    //     ]);
    // }, [reffress2]);

    // let ddd = orgvalue.filter((item) => item.value === "Select all");
    // useEffect(() => {
    //     if (ddd.length !== 0) {
    //         setOrgvalue(orgdata);
    //     } else {
    //         setOrgvalue([]);
    //     }
    // }, [ddd.length]);

    useEffect(() => {
        const getSpeciality = async () => {
            try {
                const response = await axiosInstance.current.get(
                    `/extapp/doctors/getOpNoteSpecialities/${login.ing_ak_id}`
                )
                const data = response.data.data.findIndex(
                    (item) => item.label === "Knee"
                )
                if (data !== -1) {
                    response.data.data[data].label = "Knee";
                }
                setOption(response.data.data);
            } catch (err) { }
        };
        getSpeciality();
    }, [login]);

    let akouid = orgvalue
        .filter(option => option.value.startsWith('AKOU'))
        .map(option => option.value)
        .join(',');

    const export_type = [
        eventCheckbox ? 2 : null,
        Operativecheck ? 1 : null,
        promsCheckbox ? 3 : null,
    ]
        .filter(value => value !== null)
        .join(',');

    const handleauditData = () => {
        navigate("/Audit-tool-table")
        setIsApiPending(false);
    };

    const handleExportExcel = () => {
        if (orgvalue.length === 0) {
            setOrgerr(true)
        } if (!sdate || !edate) {
            setError("Please specify start and end dates");
        } if ([eventCheckbox,
            Operativecheck,
            promsCheckbox].every((item) => item === false)) {
            setOrgerr(true)
        } if (orgvalue.length > 0 && sdate && edate && ([eventCheckbox,
            Operativecheck,
            promsCheckbox].some((item) => item === true))) {
            setShowmodelexport(true)
            
        }
    }

    const handleSubmitExport = async () => {
        if(!noteTitle){
            setStatus(true)
        }else{
            setShowmodelexport2(true)
            setShowmodelexport(false)
       
    }

    };

    const handleTrends = (e) => {
        settrendGraph(e)
    }

    const handleClickHome = () => {
        navigate("/reports")
    };

    const handleClose = () => {
        setshowModal(false);
        setValidationerr(false);
      };

      const handleExport = async () => {
        setIsApiPending(true);
        if (Otp.length < 6) {
          setValidationerr(true);
        } else {
          try {
            const response = await axiosInstance.current.get(
              `extapp/doctors/downloadauditexport?resId=${resId}&otp=${Otp}&ak_id=${login.ing_ak_id}`
            );
          
           
            
            if (noteTitle) {
                const response = await axiosInstance.current.get(
                    `extapp/doctors/auditToolsExport?sDate=${sdate}&eDate=${edate}&org_id=${akouid}&speciality=${Selectspecialty}&doctor_id=${login.ing_ak_id}&export_type=${export_type}&treatment_category=${Treatmentvalue}&hide=${hideCheckbox ? 1 : ""}&title=${noteTitle}&otp_resource_id=${resId}`
                );
                if (response.status === 200) {
                    handleauditData()
                    handleClose();
                }
            }
          } catch (err) {
            setMessage("Invalid OTP");
            setMessageType("error");
            setIsApiPending(false);
            setShow(true);
            setTimeout(() => {
              setShow(false);
              setMessage("");
            }, 3000);
            console.error(err);
          }
        }
      };
    return (
        <>
         {show && <Message message={message} type={messageType} />}
            <div className="row">
                <div className="col-md-6 col-sm-6">
                    <ul className={`${breadcrumbStyles.breadcrumb} mt-4`}>
                        <li className="c_pointer" onClick={() => handleClickHome()}>
                            Home
                        </li>
                        <li className="c_pointer" onClick={() => handleClickHome()}>
                            Reports
                        </li>
                        <label className="c_pointer f_600">Audit Tool</label>
                    </ul>
                </div>
            </div>

            <div class="row page_header">
                <div class="col-sm-6 col-md-6">
                    <h4 class="ph_title">Audit Tool</h4>
                </div>
            </div>
            <div className="start_new_case mb_100">
                <div className="row ps-4 panel pt_30 pb-3 mh_420 border">
                    <h4>Select Organisation, time interval & specialty</h4>
                    <hr style={{ marginLeft: "-12px" }} />
                  
                    <div className="row ">
                        <div className="col-xl-4  col-lg-6 col-md-6 col-sm-12 ">
                            <label className="form-label">Organization*</label>
                            <div className='row'>
                                <div className='col-sm-4'>
                                    <CreatableSelect
                                        options={orgdata2}
                                        value={orgvalue2}
                                        isMulti
                                        onChange={handleOrg}
                                        labelledBy="Select Organizations"
                                        className="multi_select_drp"
                                        defaultMenuIsOpen={false}
                                        isValidNewOption={() => false}
                                    // name="anonimize"
                                    // hasSelectAll={orgdata.length > 1 ? true : false}
                                    // overrideStrings={{
                                    //     selectSomeItems: 'Select',
                                    //     allItemsAreSelected: orgdata.length > 1 ? "All Organizations are selected." : orgdrop,
                                    //     selectAll: 'Select All',
                                    //     search: 'Search',
                                    // }}
                                    />
                                </div>
                            </div>
                            {orgvalue.length === 0 ? (
                                <>
                                    {orgerr === true ? (
                                        <span className="text-danger pt-2">
                                            This field is required
                                        </span>
                                    ) : (
                                        <span></span>
                                    )}
                                </>
                            ) : null}
                        </div>

                        <div className="col-xl-4  col-lg-6 col-md-6 col-sm-12  ">
                            <Form.Group
                                className="mb-3"
                                controlId="fromDate"
                                style={{ maxWidth: "", position: "" }}
                            >
                                <Form.Label>Time Interval*</Form.Label>
                                <div className="">
                                    <div className="row">
                                        <div className="col-md-5 col-sm-5 mb-2"><DatePicker
                                            className="form-control mb_0"
                                            selected={sdate}
                                            maxDate={edate}
                                            placeholderText="From"
                                            onChange={(date) => handleSdate(date)}
                                            showMonthDropdown
                                            showYearDropdown
                                            adjustDateOnChange
                                            dropdownMode="select"
                                            dateFormat={getDateFormat(dateFormat)}
                                        /></div>
                                        <div className="col-md-5 col-sm-5"><DatePicker
                                            className="form-control"
                                            selected={edate}
                                            minDate={sdate}
                                            placeholderText="To"
                                            onChange={(date) => handleEdate(date)}
                                            showMonthDropdown
                                            showYearDropdown
                                            adjustDateOnChange
                                            dropdownMode="select"
                                            dateFormat={getDateFormat(dateFormat)}
                                        /></div>
                                    </div>
                                    {error && (
                                        <>
                                            {(!sdate || !edate) && <span className="text-danger">{error}</span>}
                                        </>
                                    )}
                                </div>
                             
                            </Form.Group>
                        </div>

                        <div className="col-xl-4  col-lg-6 col-md-6 col-sm-12  d-flex  bd-highlight">
                            <div className="col-8 col-md-6">
                                <Dropdown
                                    data={options}
                                    label="Specialty"
                                    value={Selectspecialty}
                                    onChange={(e) => HandleSelectspecialty(e)}
                                    styleClass="br_10 mxw_100 mb_20 "
                                    placeholder="Select "
                                    className="br_20 "
                                />
                                {/* {!Selectspecialty && (
                                    <>
                                        {error && (
                                            <span className="text-danger">
                                                This field is required
                                            </span>
                                        )}
                                    </>
                                )} */}
                            </div>
                        </div>
                    </div>
                </div>

                <div className="row ps-4 panel pt_30 pb-3 mh_420 border">
                    <h4>Select list to export</h4>
                    <div className="col-md-4 col-sm-6">
                        <Checkbox
                            label="Events"
                            value={eventCheckbox}
                            onChange={() => handleeventCheckbox()}
                            styleClass="custom_check_box tick f_400 large custom_check_box_audit"
                            id="Eventexport"
                        />
                        {orgerr && (
                            <>
                                {[eventCheckbox,
                                    Operativecheck,
                                    promsCheckbox].every((item) => item === false) && (
                                        <span className="text-danger pt-2">
                                            Please Select atleast one checkbox
                                        </span>
                                    )}
                            </>
                        )}
                    </div>

                    <div className="col-md-4 col-sm-6">
                        <Checkbox
                            label="Treatment Notes"
                            value={Operativecheck}
                            onChange={() => handleOperativecheck()}
                            styleClass="custom_check_box tick f_400 large custom_check_box_audit"
                            id="Operativecheck"
                        // disabled={Eventdisable}
                        />
                        {Operativecheck && (
                            <>
                                <div className="pt-3">
                                    <Dropdown
                                        data={[
                                            { label: "Surgical", value: "surgical" },
                                            { label: "Non-Surgical", value: "non_surgical" },
                                            { label: "Research", value: "research" },
                                            { label: "All", value: "all" },
                                        ]}
                                        label="Treatment Category"
                                        styleClass=""
                                        value={Treatmentvalue}
                                        placeholder="Select"
                                        onChange={handleTreatmentvalue}
                                    />
                                </div>
                            </>
                        )}
                    </div>

                    <div className="col-md-4 col-sm-6">
                        <Checkbox
                            label="PROMs"
                            value={promsCheckbox}
                            onChange={() => handlepromsvalue()}
                            styleClass="custom_check_box tick f_400 large custom_check_box_audit"
                            id="promsexport"
                        />
                    </div>
                    <div>
                        <hr style={{ marginLeft: "-12px" }} />
                    </div>

                    <div className="text-center mb-3">
                        <Checkbox
                            label="Hide patient's identity in export"
                            value={hideCheckbox}
                            onChange={() => handlehideCheckbox()}
                            styleClass="custom_check_box tick f_400 large"
                            id="hideidentity"
                        />
                    </div>

                    <div className="d-flex justify-content-center">
                        <Button
                            width="155px"
                            value="Export List"
                            onClick={handleExportExcel}
                            buttonStyle="btn_fill mr_15"
                            style={{ marginBottom: "10px" }}
                            disabled={isApiPending}
                        />
                        {/* <Button
                            width="155px"
                            value="Show Trend"
                            onClick={handleTrends}
                            buttonStyle="btn_fill"
                            style={{ marginBottom: "10px" }}
                        // disabled={isApiPending}
                        /> */}
                    </div>
                </div>
            </div>

            <Modal
                show={showmodelexport}
                onHide={handleCloseexport}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-md patient_notes_popup modal-dialog-centered"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Add Export Title</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <InputField
                        label="Add Title"
                        value={noteTitle}
                        type="text"
                        placeholder="Enter title here"
                        styleClass="br_10 mxw_100 mb_20"
                        onChange={titleChange}
                    />
                    {status && (
                        <>
                            {noteTitle === "" && (
                                <span className="text-danger">This field is required</span>
                            )}
                        </>
                    )}
                </Modal.Body>
                <Modal.Footer>
                    <div className="text-end">
                        <ButtonLoader
                            value="Save"
                            width="120px"
                            disabled={isApiPending}
                            buttonStyle={
                                isApiPending
                                    ? "btn_outline mr_20 opacity-75"
                                    : "btn_outline mr_20"
                            }
                            onClick={handleSubmitExport}
                        />
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal
                show={showmodelexport2}
                onHide={handleCloseexport2}
                backdrop="static"
                keyboard={false}
                dialogClassName="modal-md patient_notes_popup modal-dialog-centered"
            >
                <Modal.Header closeButton>
                    <Modal.Title className="text-center">Confirmation</Modal.Title>
                </Modal.Header>
                <Modal.Body className="">
                    <div>
                        You are about to export highly confidential and sensitive patient
                        data. By clicking the “Confirm” button, you agree that it is your
                        utmost responsibility to protect patient data and adhere to privacy
                        and security regulations. You agree not to hold Akunah responsible
                        for any data breach that could happen after the data has been
                        exported out of the platform. For more information on the terms and
                        conditions, you may visit this link:{" "}
                        <a href="https://akunah.com/termsofservices" target="_blank">
                            https://akunah.com/termsofsevices.
                        </a>
                    </div>
                </Modal.Body>
                <Modal.Footer>
                    <div className="text-end">
                        <Button
                            value="Confirm"
                            width="120px"
                            buttonStyle="btn_outline mr_20"
                            onClick={handleSubmitExport2}
                        />
                    </div>
                </Modal.Footer>
            </Modal>

            <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-md patient_notes_popup modal-dialog-centered"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            Check your email for a code to export your data.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <AuditOtp
            statusmodel={true}
            handleExport={handleExport}
            setOtp={setOtp}
            validationerr={validationerr}
          />
        </Modal.Body>
      </Modal>
        </>
    )
}

export default AuditTool

const initialState = {
  painScore: [
    {
      painRating: "0",
      painRatingL: "0",
      descripL: "0",
      descripR: "0",
      oftenL: "0",
      oftenR: "0",
      restL: "0",
      restR: "0",
      normalL: "0",
      normalR: "0",
      firstL: "0",
      firstR: "0",
      twistedL: "0",
      twistedR: "0",
      straightL: "0",
      straightR: "0",
      bendingL: "0",
      bendingR: "0",
      walkingL: "0",
      walkingR: "0",
      goingL: "0",
      goingR: "0",
      nightL: "0",
      nightR: "0",
      sittingL: "0",
      sittingR: "0",
      standingL: "0",
      standingR: "0",
      Hindfootb: "0",
      Midfoot: "0",
      Hallux: "0",
      Lessertoes: "0",
      genPainR: "0",
      genPainL: "0",
    },
  ],
  instabilityScore: [
    {
      ankleFeelL: "0",
      ankleFeelR: "0",
      ankleDiagnosedL: "0",
      ankleDiagnosedR: "0",
      doesUneffectedR: "1",
    },
  ],
  comberland: [
    {
      descripR: "0",
      descripL: "0",
      unstableR: "0",
      unstableL: "0",
      sharpR: "0",
      sharpL: "0",
      stairsR: "0",
      stairsL: "0",
      standR: "0",
      standL: "0",
      feelsR: "0",
      feelsL: "0",
      feelsankR: "0",
      feelsankL: "0",
      twistR: "4",
      twistL: "4",
      roleR: "4",
      roleL: "4",
    },
  ],
  symptomsScore: [
    {
      swellingL: "0",
      swellingR: "0",
      catchingL: "0",
      catchingR: "0",
      grindingL: "0",
      grindingR: "0",
      stiffnessL: "0",
      stiffnessR: "0",
      stiffnessSittingL: "0",
      stiffnessSittingR: "0",
    },
  ],
  functionScore: [
    {
      footActivityL: "0",
      footActivityR: "0",
      footwearL: "0",
      footwearR: "0",
      walkingL: "0",
      walkingR: "0",
      footStraightL: "0",
      footStraightR: "0",
      footBendingL: "0",
      footBendingR: "0",
      desStairL: "0",
      desStairR: "0",
      ascStairL: "0",
      ascStairR: "0",
      risingL: "0",
      risingR: "0",
      standingL: "0",
      standingR: "0",
      bendingFloorL: "0",
      bendingFloorR: "0",
      walkingFlatL: "0",
      walkingFlatR: "0",
      maxWalkingL: "0",
      maxWalkingR: "0",
      walkingWithoutShoesL: "0",
      walkingWithoutShoesR: "0",
      walkingHillsL: "0",
      walkingHillsR: "0",
      walkingDownHillsL: "0",
      walkingDownHillsR: "0",
      walkingUnevenL: "0",
      walkingUnevenR: "0",
      steppingUpL: "0",
      steppingUpR: "0",
      comingUpL: "0",
      comingUpR: "0",
      walkIntiallyL: "0",
      walkIntiallyR: "0",
      walkFiveL: "0",
      walkFiveR: "0",
      walkTenL: "0",
      walkTenR: "0",
      walkFifteenL: "0",
      walkFifteenR: "0",
      gettingCarL: "0",
      gettingCarR: "0",
      goingShoppingL: "0",
      goingShoppingR: "0",
      puttingSocksL: "0",
      puttingSocksR: "0",
      takingOffL: "0",
      takingOffR: "0",
      risingBedL: "0",
      risingBedR: "0",
      lyingL: "0",
      lyingR: "0",
      getBathL: "0",
      getBathR: "0",
      sittingL: "0",
      sittingR: "0",
      getToiletL: "0",
      getToiletR: "0",
      homeL: "0",
      homeR: "0",
      activitiesL: "0",
      activitiesR: "0",
      personalCareL: "0",
      personalCareR: "0",
      heavyWorkL: "0",
      heavyWorkR: "0",
      lightWorkL: "0",
      lightWorkR: "0",
      moderateWorkL: "0",
      moderateWorkR: "0",
      recreationalL: "0",
      recreationalR: "0",
      squattingL: "0",
      squattingR: "0",
      twistingR: "0",
      twistingL: "0",
      kneelingL: "0",
      kneelingR: "0",
      runningL: "0",
      runningR: "0",
      jumpingL: "0",
      jumpingR: "0",
      landingL: "0",
      landingR: "0",
      stoppingL: "0",
      stoppingR: "0",
      cuttingL: "0",
      cuttingR: "0",
      lowActL: "0",
      lowActR: "0",
      abilityPerformL: "0",
      abilityPerformR: "0",
      abilityParticipateL: "0",
      abilityParticipateR: "0",
      footPercent: "100",
      footPercentL: "100",
      Hindfoot: "0",
      Midfoot: "0",
      Hallux: "0",
      LesserToes: "0",
      Midfootb: "0",
      Halluxb: "0",
      LesserToesb: "0",
      Hindfootc: "0",
      Midfootc: "0",
      sleepingR: "0",
      sleepingL: "0",
      runSelectR: false,
      runSelectL: false,
      jumpSelectR: false,
      jumpSelectL: false,
      landingSelectR: false,
      landingSelectL: false,
      startingSelectR: false,
      startingSelectL: false,
      cuttingSelectR: false,
      cuttingSelectL: false,
      impactSelectR: false,
      impactSelectL: false,
      performSelectR: false,
      performSelectL: false,
      participateSelectR: false,
      participateSelectL: false,
      highestActR: "0",
      highestActL: "0",
      topLevelR: "0",
      topLevelL: "0",
      lowLevelR: "0",
      lowLevelL: "0",
      recreatR: "0",
      recreatL: "0",
      idoSpotR: "0",
      idoSpotL: "0",
      noSpotR: "0",
      noSpotL: "0",
    },
  ],
  qolScore: [
    {
      oftenAnkleR: "0",
      oftenAnkleL: "0",
      lifestyleR: "0",
      lifestyleL: "0",
      troubledR: "0",
      troubledL: "0",
      difficultyR: "0",
      difficultyL: "0",
      anxiousR: "0",
      anxiousL: "0",
      healthToday: "100",
    },
  ],
  satisfactionScore: [
    {
      symptomR: "4",
      symptomL: "4",
      improveR: "4",
      improveL: "4",
      forgetR: "4",
      forgetL: "4",
      applicableR: false,
      applicableL: false,
      applicable1R: false,
      applicable1L: false,
      applicable2R: false,
      applicable2L: false,
    },
  ],
  TotalScores: [
    {
      FAAMADRScrore: 0,
      FAAMADLScrore: 0,
      SatisfactionForgetL: 0,
      FAAMSRScrore: 0,
      FAAMSLScrore: 0,
      TotalFADILS: 0,
      TotalFADIRS: 0,
      FADISPORTRS: 0,
      FADISPORTLS: 0,
      FAOSTPAINR: 0,
      FAOSTPAINL: 0,
      FAOSTSYMPTOMSR: 0,
      FAOSTSYMPTOMSL: 0,
      FAOSTADLR: 0,
      FAOSTADLL: 0,
      FAOSTSPORTR: 0,
      FAOSTSPORTL: 0,
      FAOSTQOLR: 0,
      FAOSTQOLL: 0,

      HindfootScaleR: 0,
      HindfootScaleL: 0,
      MidfootScaleR: 0,
      MidfootScaleL: 0,
      HalluxScaleR: 0,
      HalluxScaleL: 0,
      LesserToeScaleR: 0,
      LesserToeScaleL: 0,

      TotalSATISFACTION: 0,
      SatisfactionImproveL: 0,
      // SatisfactionSymptomL:0,
      EQDWalkingR: 0,
      EQDWalkingL: 0,
      EqdPersonalCareR: 0,
      EqdPersonalCareL: 0,
      EqdActivitiesR: 0,
      EqdActivitiesL: 0,
      EqdsanxiousR: 0,
      EqdsanxiousL: 0,
      HealthToday: 0,
      FootPercent: 0,
      FootPercentL: 0,
      VasPainRating: 0,
      VasPainRatingL: 0,
      EQPain: 0,
      EQPainL: 0,
      CumberlandRT: 0,
      CumberlandLT: 0,
      AASR: 0,
      AASL: 0
    },
  ],
  doctorFeedback: [
    {
      RDEFORMITY: "",
      LDEFORMITY: "",
      value: [],
      value2: [],
      RightGAIT: "1",
      LeftGAIT: "1",
      RightABNORMAL: "0",
      LeftABNORMAL: "0",
      RABNORMALF: "",
      LABNORMALF: "",
      RightALIGNMENT: "",
      LeftALIGNMENT: "",
      ir4Right: "",
      ir4Left: "",
      ContraR: "",
      ContraL: "",
      SpecifyR: "",
      SpecifyL: "",
      CallusR: "",
      CallusL: "",
      doraR: "",
      doraL: "",
      plantR: "",
      plantL: "",
      inverR: "",
      inverL: "",
      everR: "",
      everL: "",
      halluxR: "",
      halluxL: "",
      hallux2R: "",
      hallux2L: "",
      hallux3R: "",
      hallux3L: "",
      hallux4R: "",
      hallux4L: "",
      hallux5R: "",
      hallux5L: "",
      halluxRpi: "",
      halluxLpi: "",
      hallux2Rpi: "",
      hallux2Lpi: "",
      hallux3Rpi: "",
      hallux3Lpi: "",
      hallux4Rpi: "",
      hallux4Lpi: "",
      hallux5Rpi: "",
      hallux5Lpi: "",
      halluxRdo: "",
      halluxLdo: "",
      hallux2Rdo: "",
      hallux2Ldo: "",
      hallux3Rdo: "",
      hallux3Ldo: "",
      hallux4Rdo: "",
      hallux4Ldo: "",
      hallux5Rdo: "",
      hallux5Ldo: "",
      ankleR: "0",
      ankleL: "0",
      mtpR: "0",
      mtpL: "0",
      silfR: "",
      silfL: "",
      toesR: "",
      toesL: "",
      peekR: "",
      peekL: "",
      antR: "",
      antL: "",
      postR: "",
      postL: "",
      inversionR: "",
      inversionL: "",
      eversionR: "",
      eversionL: "",
      calfR: "",
      calfL: "",
      externalR: "",
      externalL: "",
      colemanR: "",
      colemanL: "",
      doubleR: "",
      doubleL: "",
      singleR: "",
      singleL: "",
      thompR: "",
      thompL: "",
      mulderR: "",
      mulderL: "",
      crunchR: "",
      crunchL: "",
      laxityR: "0",
      laxityL: "0",
      FootR: "0",
      FootL: "0",
      footcallusR: "",
      footcallusL: "",
      mtpipR: "0",
      mtpipL: "0",
      EvaluatedR: "",
      EvaluatedL: "",
      RightForefoot: [],
      LeftForefoot: [],
      AnkleR: "",
      AnkleL: "",
      MotionR: "",
      MotionRtext: "",
      MotionL: "",
      MotionLtext: "",
      Motion3R: "",
      Motion3Rtext: "",
      Motion4R: "",
      Motion4Rtext: "",
      MotionLRtext: "",
      MotionLLtext: "",
      Motion3RRtext: "",
      Motion3RLtext: "",
      StabilityR: "",
      StabilityL: "",
      Stability2R: "",
      Stability2L: "",
      Stability3R: "",
      Stability3L: "",
      TalarR: "",
      TalarL: "",
      SyndesmosisR: "",
      SyndesmosisL: "",
      Syndesmosis1R: "",
      Syndesmosis1L: "",
      ImpingementR: "",
      ImpingementL: "",
      ImpingementdropR: "",
      ImpingementLdrop: "",
      Impingement1R: "",
      Impingement1L: "",
      Impingement3R: "",
      Impingement3L: "",
      Impingement4R: "",
      Impingement4L: "",
      AchillesR: "",
      AchillesL: "",
      Achilles2R: "",
      Achilles2L: "",
      Achilles4R: "",
      Achilles4L: "",
      Achilles6R: "",
      Achilles6L: "",
      Pal1RtextL: "",
      Pal1LtextL: "",
      Pal1Rtext: "",
      Pal1Ltext: "",
      DeformityR: "",
      DeformityL: "",
      Deformity2R: "",
      Deformity2L: "",
      Deformity4R: "",
      Deformity4L: "",
      Deformity6R: "",
      Deformity6L: "",
      Deformity8R: "",
      Deformity8L: "",
      AlignmentR: "",
      AlignmentL: "",
      AlignmentradioL: "",
      Alignmentdrop: "",
      Alignmen1L: "",
      Alignmen1drop: "",
      AlignmeninputR: 0,
      AlignmeninputL: 0,
      Alignmen2R: "",
      Alignmen2L: "",
      LesserR: "",
      LesserR2: "",
      LesserL: "",
      LesserL2: "",
      LesserradioL: "",
      LesserradioL2: "",
      LesserrLdrop: "",
      LesserrLdrop2: "",
      LesserradioR: "",
      LesserradioR2: "",
      LesserrRdrop: "",
      LesserrRdrop2: "",
      LesserrSpecifyR: 0,
      LesserrSpecifyL: 0,
      LesserrDegreeR: 0,
      LesserrDegreeL: 0,
      SpecifyLradio: "",
      SpecifyLradio2: "",
      SpecifyRradio: "",
      SpecifyRradio2: "",
      LigamentousR: "",
      LigamentousL: "",
      LigamentousRtext: "",
      LigamentousLtext: "",
      AlignmeninputIPJOINTR: 0,
      AlignmeninputIPJOINTL: 0,
      MidfootR: "",
      MidfootL: "",
      AnkleHR: false,
      AnkleHL: false,
      MidfootHR: false,
      MidfootHL: false,
      ForefootR: false,
      ForefootL: false,


      LesserL23rd: "",
      LesserR23rd: "",
      LesserradioL23rd: "",
      LesserrLdrop23rd: "",
      LesserradioR23rd: "",
      LesserrRdrop23rd: "",
      LesserrDegreeL3rd: "",
      LesserrDegreeR3rd: "",
      SpecifyLradio23rd: "",
      SpecifyRradio23rd: "",

      LesserL23rd2: "",
      LesserR23rd2: "",
      LesserradioL23rd2: "",
      LesserrLdrop23rd2: "",
      LesserradioR23rd2: "",
      LesserrRdrop23rd2: "",
      LesserrDegreeL3rd2: "",
      LesserrDegreeR3rd2: "",
      SpecifyLradio23rd2: "",
      SpecifyRradio23rd2: "",


      LesserL24th: "",
      LesserR24th: "",
      LesserradioL24th: "",
      LesserrLdrop24th: "",
      LesserradioR24th: "",
      LesserrRdrop24th: "",
      LesserrDegreeL4th: "",
      LesserrDegreeR4th: "",
      SpecifyLradio24th: "",
      SpecifyRradio24th: "",

      LesserL24th2: "",
      LesserR24th2: "",
      LesserradioL24th2: "",
      LesserrLdrop24th2: "",
      LesserradioR24th2: "",
      LesserrRdrop24th2: "",
      LesserrDegreeL4th2: "",
      LesserrDegreeR4th2: "",
      SpecifyLradio24th2: "",
      SpecifyRradio24th2: "",


      LesserL25th: "",
      LesserR25th: "",
      LesserradioL25th: "",
      LesserrLdrop25th: "",
      LesserradioR25th: "",
      LesserrRdrop25th: "",
      LesserrDegreeL5th: "",
      LesserrDegreeR5th: "",
      SpecifyLradio25th: "",
      SpecifyRradio25th: "",

      LesserL25th2: "",
      LesserR25th2: "",
      LesserradioL25th2: "",
      LesserrLdrop25th2: "",
      LesserradioR25th2: "",
      LesserrRdrop25th2: "",
      LesserrDegreeL5th2: "",
      LesserrDegreeR5th2: "",
      SpecifyLradio25th2: "",
      SpecifyRradio25th2: "",
      Hallux: "",
      Toe2nd: "",
      Toe3rd: "",
      Toe4th: "",
      Toe5th: "",
      Talar2R: "",
      Talar2L: "",
      Motion2Rtext: "",
      Motion2Ltext: "",
      Motion2LRtext: "",
      Motion2LLtext: "",
      Motion23Rtext: "",
      Motion24Rtext: "",
      Motion23RRtext: "",
      Motion23RLtext: "",

      Motion2ndRtext: "",
      Motion2ndLtext: "",
      Motion2ndLRtext: "",
      Motion2ndLLtext: "",
      Motion2nRtext: "",
      Motion2nlRtext: "",
      Motion2ndRRtext: "",
      Motion2ndRLtext: "",

      Motion3ndRtext: "",
      Motion3ndLtext: "",
      Motion3ndLRtext: "",
      Motion3ndLLtext: "",
      Motion3nRtext: "",
      Motion3nlRtext: "",
      Motion3ndRRtext: "",
      Motion3ndRLtext: "",

      Motion4ndRtext: "",
      Motion4ndLtext: "",
      Motion4ndLRtext: "",
      Motion4ndLLtext: "",
      Motion4nRtext: "",
      Motion4nlRtext: "",
      Motion4ndRRtext: "",
      Motion4ndRLtext: "",

      Motion5ndRtext: "",
      Motion5ndLtext: "",
      Motion5ndLRtext: "",
      Motion5ndLLtext: "",
      Motion5nRtext: "",
      Motion5nlRtext: "",
      Motion5ndRRtext: "",
      Motion5ndRLtext: "",
    },
  ],
  anklePain: "1",
  footAnkleArea: "",
};

const FootAnkleReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_FOOTANKLE_DATA":
      return { ...state, anklePain: action.data };

    case "UPDATE_FOOT_ANKLE_PAIN_FIELD_DATA":
      const painFieldVal = action.data.value;
      const painFieldKey = action.data.key;
      const painNewArr = state.painScore.map((object) => {
        return { ...object, [painFieldKey]: painFieldVal };
      });
      return { ...state, painScore: painNewArr };

    case "UPDATE_FOOT_ANKLE_PAIN_DATA":
      return { ...state, painScore: action.data };

    case "UPDATE_FOOT_ANKLE_INSTABILITY_DATA_SINGLE":
      const fieldVal = action.data.value;
      const fieldKey = action.data.key;
      const newArr = state.instabilityScore.map((object) => {
        return { ...object, [fieldKey]: fieldVal };
      });
      return { ...state, instabilityScore: newArr };
    case "UPDATE_FOOT_INSTABILITY_DATA":
      return { ...state, instabilityScore: action.data };

    case "UPDATE_FOOT_ANKLE_COMBERLAND_DATA_SINGLE":
      const fieldValcom = action.data.value;
      const fieldKeycom = action.data.key;
      const newArrcom = state.comberland.map((object) => {
        return { ...object, [fieldKeycom]: fieldValcom };
      });
      return { ...state, comberland: newArrcom };
    case "UPDATE_FOOT_COMBERLAND_DATA":
      return { ...state, comberland: action.data };

    case "UPDATE_FOOT_ANKLE_SYMPTOMS_DATA_SINGLE":
      const fieldValS = action.data.value;
      const fieldKeyS = action.data.key;
      const SymptomsNewArr = state.symptomsScore.map((object) => {
        return { ...object, [fieldKeyS]: fieldValS };
      });
      return { ...state, symptomsScore: SymptomsNewArr };
    case "UPDATE_FOOT_SYMPTOMS_DATA":
      return { ...state, symptomsScore: action.data };

    case "UPDATE_FOOT_ANKLE_FUNCTION_DATA_SINGLE":
      const functionFieldVals = action.data.value;
      const functionFieldKeyS = action.data.key;
      const functionNewArr = state.functionScore.map((object) => {
        return { ...object, [functionFieldKeyS]: functionFieldVals };
      });
      return { ...state, functionScore: functionNewArr };
    case "UPDATE_FOOT_FUNCTION_DATA":
      return { ...state, functionScore: action.data };

    case "UPDATE_FOOT_ANKLE_QOL_DATA_SINGLE":
      const qolFieldVals = action.data.value;
      const qolFieldKeyS = action.data.key;
      const qolNewArr = state.qolScore.map((object) => {
        return { ...object, [qolFieldKeyS]: qolFieldVals };
      });
      return { ...state, qolScore: qolNewArr };
    case "UPDATE_FOOT_QOL_DATA":
      return { ...state, qolScore: action.data };

    case "UPDATE_FOOT_ANKLE_SATISFACTION_DATA_SINGLE":
      const saFieldVals = action.data.value;
      const saFieldKeyS = action.data.key;
      const saNewArr = state.satisfactionScore.map((object) => {
        return { ...object, [saFieldKeyS]: saFieldVals };
      });
      return { ...state, satisfactionScore: saNewArr };
    case "UPDATE_FOOT_SATISFACTION_DATA":
      return { ...state, satisfactionScore: action.data };

    case "UPDATE_TOTAL_FIELD_DATA":
      return { ...state, TotalScores: action.data };
    case "UPDATE_AREA_DATA":
      return { ...state, footAnkleArea: action.data };
    case "UPDATE_FEEDBACK_FUNCTION_DATA":
      const satf = action.data.value;
      const satK = action.data.key;
      const satNewarr = state.doctorFeedback.map((object) => {
        return { ...object, [satK]: satf };
      });
      return { ...state, doctorFeedback: satNewarr };
    case "UPDATE_FOOT_FEEDBACK_FIELD_DATA":
      return { ...state, doctorFeedback: action.data };

    default:
      return state;
  }
};

// UPDATE_AREA_DATA

export default FootAnkleReducer;

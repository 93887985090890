import React, {
  useState,
  useEffect,
  forwardRef,
  useImperativeHandle,
} from "react";
import PropTypes from "prop-types";
import { validateInput } from "../../../utilities/Validator";
import SpeechRecognition, {
  useSpeechRecognition,
} from "react-speech-recognition";
import microphone from "../../../images/mic.png";
import microphone_red from "../../../images/microphone_red.svg";
const InputField = forwardRef(
  (
    {
      value,
      label,
      placeholder,
      validators,
      type,
      styleClass,
      onChange,
      formGroupClass,
      rows,
      cols,
      disabled,
      readOnly,
      maxLength,
      mic,
      id,
      read,
      onStartListening,
      onStopListening,
      isActive
    },
    ref
  ) => {
    const [error, setError] = useState(false);
    const [localListening, setLocalListening] = useState(false); // Local state for the microphone
    const {
      transcript,
      resetTranscript,
    } = useSpeechRecognition();
    const [testvalue, setTextvalue] = useState("")
    const handleChange = (event) => {
      const { value } = event.target;
      setTextvalue(value)
      SpeechRecognition.stopListening();
      // resetTranscript();
      setError(validateInput(validators, value));
      onChange(value, validateInput(validators, value));
    };
    useEffect(() => {
      if(mic === true){
      if (isActive === id) {
        onChange(value + transcript, validateInput(validators, value));
        setLocalListening(true)
      } else {
        onChange(value + testvalue, validateInput(validators, value));
        resetTranscript();
        setLocalListening(false)
      }
    }
    }, [transcript, isActive])
    useImperativeHandle(ref, () => ({
      errUpdate(e) {
        setError(validateInput(validators, ""));
      },
    }));
    // useEffect(() => {
    //   if (localListening) {
    //     SpeechRecognition.startListening({
    //       continuous: true,
    //       language: "en",
    //     });
    //     if (onStartListening) {
    //       onStartListening();
    //     }
    //   } else {
    //     SpeechRecognition.stopListening();
    //     if (onStopListening) {
    //       onStopListening();
    //     }
    //   }
    // }, [localListening, onStartListening, onStopListening]);
    useEffect(() => {
      if (localListening) {
        SpeechRecognition.startListening({
          continuous: true,
          language: "en",
        });
        if (onStartListening) {
          onStartListening(id);
        }
      } else {
        SpeechRecognition.stopListening();
        if (onStopListening) {
          onStopListening(id);
        }
      }
    }, [localListening, onStartListening, onStopListening, id]);
    return (
      <div className={`form-group ${formGroupClass}`}>
        {label && <label htmlFor="app-input-field">{label}</label>}
        {type === "textarea" ? (
          <>
            <div className="position-relative">
              <textarea
                className={`text_area form-control scrollbar_vert ${styleClass} `}
                // id={isActive}
                placeholder={placeholder}
                value={value}
                // value={isActive===id ?(value + transcript):value}
                onChange={handleChange}
                onBlur={handleChange}
                rows={rows}
                cols={cols}
                disabled={disabled}
                maxLength={maxLength}
              />
              {mic === true &&
                SpeechRecognition.browserSupportsSpeechRecognition() && (
                  <>
                    <div className="microphone_box">
                      <div>
                        <div>
                          {localListening ? (
                            <>
                              <span
                                onClick={() => setLocalListening(false)}
                              >
                                <div className="pulseLoader"></div>
                                <img
                                  src={microphone_red}
                                  className={`c_pointer  ms-3 mr_20 ${isActive ? 'active' : ''
                                    }`}
                                  alt="icon"
                                />
                              </span>
                            </>
                          ) : (
                            <>
                              <span
                                onClick={() => setLocalListening(true)}
                              >
                                <img
                                  src={microphone}
                                  className={`c_pointer ms-3 mr_20 ${isActive ? 'active' : ''
                                    }`}
                                  alt="icon"
                                  style={{ width: "31px" }}
                                />
                              </span>
                            </>
                          )}
                        </div>
                        <div className="pulse-ring"></div>
                      </div>
                    </div>
                  </>
                )}
            </div>
          </>
        ) : (
          <input
            type={type}
            value={value}
            className={`form-control ${styleClass}`}
            placeholder={placeholder}
            disabled={disabled}
            onChange={handleChange}
            onBlur={handleChange}
            readOnly={readOnly}
            maxLength={maxLength}
          />
        )}
        {error && <span className="text-danger">{error.message}</span>}
      </div>
    );
  }
);
InputField.propTypes = {
  value: PropTypes.string,
  label: PropTypes.string,
  placeholder: PropTypes.string,
  validators: PropTypes.array,
  type: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  formGroupClass: PropTypes.string,
  rows: PropTypes.string,
  cols: PropTypes.string,
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  maxLength: PropTypes.number,
  mic: PropTypes.bool,
  id: PropTypes.string,
  read: PropTypes.bool,
};
InputField.defaultProps = {
  value: "",
  label: "",
  placeholder: "",
  type: "text",
  validators: [],
  formGroupClass: "",
  rows: "",
  cols: "",
  disabled: false,
  readOnly: false,
  maxLength: 5000,
  mic: false,
  id: "",
  read: false,
};
export default InputField;
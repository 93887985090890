import React, { useEffect, useState } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import downloadIcon from "../../images/download-icon.svg";
import cross from "../../images/x 1.png";
import refresh from "../../images/refresh.png";
import useAxios from "../../axiosinstance";
import { useSelector } from "react-redux";
import {
  HoursMinutesFormat,
  UserDateFormat,
} from "../../utilities/DateFormate";
import Modal from "react-bootstrap/Modal";
import AuditOtp from "../AuditTool/AuditOtp";
import { s3UrlLink, s3UrlLinkExport, s3UrlLinkFile } from "../../utilities/S3Url";
import axios from "axios";
export default function DataExportTableNew({
  data,
  updatePagination,
  setReff,
  total,
  reff,
  setMessage,
  setMessageType,
  hasMore,
  setExportData
}) {
  const [clickedIndex, setClickedIndex] = useState(null);
  const [rotatingJobId, setRotatingJobId] = useState(null);
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const axiosInstance = useAxios();
  const [table_data, setTable_data] = useState(data);
  // const [activeloading, setactiveLoading] = useState(true);
  useEffect(() => {
    setTable_data(data);
  }, [data.length]);
  const handleClick = (index) => {
    setClickedIndex(index);
  };

  const handleAbort = async (type) => {
    const id = type.jobid;
    try {
      const response = await axiosInstance.current.put(
        `extapp/doctors/update_job_status/${id}`
      );
      if (response.status === 200) {
        // setExportData((prevData) => prevData.filter((item) => item.jobid !== id));
        if (reff === "true") {
        setReff(new Date().getMilliseconds());
        }
        setMessageType("success");
        setMessage("Export is Aborted");
        setTimeout(() => {
          setMessage("");
        }, 3000);
      }
    } catch (err) {}
  };

  const getJobProcessing = async (column) => {
    const id = column.jobid;
    if (id) {
      setRotatingJobId(id);
      try {
        const response = await axiosInstance.current.get(
          `extapp/doctors/get_job_processing?ak_id=${login.ing_ak_id}&jobid=${id}`
        );
        if (response.status === 200) {
          let update_data = data.map((item) => {
            if (item.jobid === id) {
              return { ...item, status: response.data.result[0]?.status,s3_file_path: response.data.result[0]?.s3_file_path,file_name: response.data.result[0]?.file_name};
            }
            return item;
          });
          setTable_data(update_data);
          setMessageType("success");
          setMessage("Export is being Processed");
          setTimeout(() => {
            setMessage("");
          }, 3000);
        }
      } catch (err) {}

      setTimeout(() => {
        setRotatingJobId(null);
      }, 1000);
    }
  };

  const [showModal, setShowModel] = useState(false);
  const handleClose = () => {
    setShowModel(false);
  };

  const [validationerr, setValidationerr] = useState(false);
  const [Otp, setOtp] = useState("");
  const [res_id,setres_id]=useState('')
  const [s3_file_path,sets3_file_path]=useState('')
  const [file_name,setfile_name]=useState('')

  const downloadFile = async (url, fileName) => {
    try {
      const response = await fetch(url);
      const blob = await response.blob();
      const link = document.createElement("a");
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading file:", error);
    }
  };

  const Export_data_Otp = async (res_id,s3_file_path,file_name) => {
    sets3_file_path(s3_file_path)
    setfile_name(file_name)
    // let response = await axios.get(s3UrlLinkExport(s3_file_path))
    // let ff2=s3UrlLinkExport(s3_file_path)

    // console.log(s3_file_path,'s3_file_path',ff2,response);
    try {
      const response = await axiosInstance.current.put(
        `extapp/doctors/update_otp/${login.ing_ak_id}?opt_resource_id=${res_id}`
      );
      if(response){
        setres_id(res_id)
        setShowModel(true)
        setMessage("OTP sent successfully");
        setMessageType("success");
        setTimeout(() => {
          setMessage("");
        }, 3000);
      }
    } catch (err) {}
  };
  const handleExport = async () => {
   
    if (Otp.length < 6) {
      setValidationerr(true);
    } else {
      try {
        const response = await axiosInstance.current.get(
          // `/extapp/doctors/verifyDownloadDataExport/${login.ing_ak_id}?otp=${Otp}&id=${res_id}&res_type=&key`
           `extapp/doctors/verifyOTP?doctor_id=${login.ing_ak_id}&otp=${Otp}&resId=${res_id}&type=41`
        );
    
        setTimeout(async() => {
          // let response = await axios.get(s3UrlLinkExport(s3_file_path))
          let response = await axios.get(s3UrlLinkFile(s3_file_path,file_name))
          // let response = await axios.get(s3UrlLink(s3_file_path))
        
          
          downloadFile(response.config.url,file_name);
      }, 1000);
      setShowModel(false)
      } catch (err) {
        setMessage("Invalid OTP");
        setMessageType("error");
        setTimeout(() => {
          setMessage('')
        }, 3000);
      }
    }
  };
  
  //   const handleDownload = (link) => {
  //     const downloadWithDelay = (url) => {
  //         setTimeout(() => {
  //             downloadFile(url, new URL(url).pathname.split("/").pop());
  //         }, 1000);
  //     };

  //     if (link.score_url) {
  //         downloadWithDelay(link.score_url);
  //     }
  //     if (link.medical_url) {
  //         downloadWithDelay(link.medical_url);
  //     }
  //     if (link.treatment_url) {
  //         downloadWithDelay(link.treatment_url);
  //     }
  // };
  //  useEffect(() => {
  //     if (keys !== "") {
  //         const getFile = async () => {
  //             let response = await axios.get(s3UrlLinkExport(keys))

  //             setLink(response.data)
  //         }
  //         getFile();
  //     };
  // }, [keys]);
  console.log(hasMore,"hasmorehasmore")
  return (
    <>
      <div className="setting_temp scrollbar_verts">
        <div className="tableContainer" id="rpScrollableDiv">
          <InfiniteScroll
            dataLength={table_data.length}
            next={updatePagination}
            hasMore={true}
            scrollableTarget="rpScrollableDiv"
          >
        {table_data.map((column, index) => (
          <div className="box-content text-left" key={index}>
            <div className="request_view1">
              <span
                id="new-task-add"
                className={`btn_nill1 d-flex position-relative justify-content-between minheight_60 ${
                  clickedIndex === index ? "index_click" : "active-span"
                }`}
                onClick={() => handleClick(index)}
              >
                <div className="d-flex align-items-center ms-3">
                  <div className="me-3" style={{ fontWeight: "bold", width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }}>
                    <span>{column.title}</span>
                  </div>

                  <div className="me-3" style={{  width: '200px', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis', }}>
                    <span>
                      {UserDateFormat(
                        column.created_at,
                        login.ing_date_formate,
                        login.ing_timezone
                      )}
                    </span>
                  </div>

                  <div className="me-3">
                    <span>
                      {HoursMinutesFormat(column.created_at)}
                    </span>
                  </div>
                </div>

                <div className="d-flex">
                  <div className={"d-flex me-3"}></div>
                  <div
                    className={`new__note_btn f_600 img_white c_pointer`}
                    style={{ position: "", right: "8px", top: "3px" }}
                  >
                    <div className="d-flex align-items-center">
                      {(column.status === "queue" ||
                        column.status === "inprogress") && (
                        <>
                          <div
                            style={{
                              width: "50px",
                              height: "24px",
                              borderRadius: "90px",
                              backgroundColor: "#F3F5F7",
                              marginLeft: "10px",
                              marginRight: "15px",
                              alignContent: "center",
                              alignItems: "center",
                              border: "1px solid rgb(204 194 232)",
                            }}
                          >
                            <p
                              style={{
                                marginLeft: "8px",
                                marginTop: "2px",
                                alignContent: "center",
                                alignItems: "center",
                              }}
                            >
                              New
                            </p>
                          </div>
                          <span>
                            <p>Processing...</p>
                          </span>
                        </>
                      )}
                      {(column.status === "terminated" ||  column.status=== "aborted") && (
                        <>
                          <span>
                            <p style={{ color: "black" }}>Terminated</p>
                          </span>
                        </>
                      )}
                      
                      {column.status === "success" && (
                        <>
                          <img
                            src={downloadIcon}
                            alt="img"
                            className="mt-1 c_pointer"
                            style={{ marginLeft: "8px" }}
                            onClick={()=>Export_data_Otp(column.opt_resource_id,column.s3_file_path,column.file_name)}
                          />{" "}
                        </>
                      )}
                      {(column.status === "queue" ||
                        column.status === "inprogress") && (
                        <>
                          <img
                            src={refresh}
                            alt="refresh"
                            className={`refresh-icon ${
                              rotatingJobId === column.jobid ? "rotate" : ""
                            }`}
                            onClick={() => getJobProcessing(column)}
                            style={{ cursor: "pointer" }}
                          />

                          <img
                            src={cross}
                            alt="img"
                            className="mt-1 c_pointer"
                            style={{ marginLeft: "8px" }}
                            onClick={() => handleAbort(column)}
                          />
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </span>
            </div>
          </div>
        ))}

        {/* <div className="tableContainer" id="rpScrollableDiv">
  <InfiniteScroll
    dataLength={table_data.length}
    // next={fetchMoreData}
    // hasMore={hasMore}
    scrollableTarget="rpScrollableDiv"
  >
    <table className="custom-tbl">
      <thead style={{ zIndex: "" }}>
        <tr>
          <th>SI No</th>
          <th>Title</th>
          <th>Date</th>
          <th>Time</th>
          <th>Action</th>
        </tr>
      </thead>
      <tbody>
        {activeloading && (
          <tr>
            <td colSpan="5" className="text-center">
              <div className="d-flex justify-content-center text-primary">
                <div className="spinner-border" role="status" style={{ color: "#3f2783" }}>
                  <span className="sr-only"></span>
                </div>
              </div>
            </td>
          </tr>
        )}
        {!activeloading &&  table_data.length > 0 ? (
          table_data.map((column, index) => (
            <tr
              key={index}
              className={`${clickedIndex === index ? "index_click" : "active-row"}`}
              onClick={() => handleClick(index)}
            >
              <td>{index + 1}</td>
              <td>{column.title}</td>
              <td>
                {UserDateFormat(
                  column.created_at,
                  login.ing_date_formate,
                  login.ing_timezone
                )}
              </td>
              <td>
                {HoursMinutesFormatDataExport(
                  column.created_at
                )}
              </td>
              <td>
  {(column.status === "queue" || column.status === "inprogress") ? (
    <div style={{ display: "flex", alignItems: "center" }}>
      <div
        style={{
          width: "50px",
          height: "24px",
          borderRadius: "90px",
          backgroundColor: "#F3F5F7",
          marginRight: "15px",
          border: "1px solid rgb(204 194 232)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <p style={{ margin: "0 8px" }}>New</p>
      </div>

      <p style={{ marginRight: "10px" }}>Processing...</p>

      <img
        src={refresh}
        alt="refresh"
        className={`refresh-icon ${rotatingJobId === column.jobid ? "rotate" : ""}`}
        onClick={() => getJobProcessing(column)}
        style={{ cursor: "pointer", marginRight: "10px" }}
      />

      <img
        src={cross}
        alt="cancel"
        className="mt-1 c_pointer"
        onClick={() => handleAbort(column)}
        style={{ cursor: "pointer" }}
      />
    </div>
  ) : column.status === "success" ? (
    <img
      src={downloadIcon}
      alt="Download"
      className="mt-1 c_pointer"
      style={{ marginLeft: "8px" }}
      // onClick={() => handleDownload(column.resource_id)}
    />
  ) : (
    !activeloading && (
      <tr>
        <td colSpan="5" className="text-center no_data">No data found</td>
      </tr>
    ))}
</td>


            </tr>
          ))
        ) : (
          !activeloading && (
            <tr>
              <td colSpan="5" className="text-center no_data">
                No data found
              </td>
            </tr>
          )
        )}
      </tbody>
    </table>
  </InfiniteScroll>
</div> */}

        </InfiniteScroll>
        </div>
      </div>

      <Modal
        show={showModal}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        dialogClassName="modal-md patient_notes_popup modal-dialog-centered"
      >
        <Modal.Header closeButton>
          <Modal.Title className="text-center">
            Check your email for a code to export your data.
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="pt-0">
          <AuditOtp
            statusmodel={true}
            handleExport={handleExport}
            setOtp={setOtp}
            validationerr={validationerr}
          />
        </Modal.Body>
      </Modal>
    </>
  );
}

import React, {  useRef } from "react";
// import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import { useKeycloak } from "@react-keycloak/web";



import { useSelector } from "react-redux";
import Welcome from "./Welcome";
import AppRouter from "./AppRouter";
import LoadingSpinner from "./components/custom/loader/LoadingSpinner";

function App() {
  const { keycloak, initialized } = useKeycloak();
  // const [isLoading, setIsLoading] = useState(true);
  const myButtonRef = useRef(null);
  console.log("==login working in app js--", keycloak.authenticated);

  const chat = useSelector((state) => state.mainReducer.chatRef.arr);

  // useEffect(() => {
  //   setTimeout(() => {
  //     setIsLoading(false);
  //   }, 2500);
  // }, []);

  if (!initialized) {
    return (
      // <h2 style={{ textAlign: "center", marginTop: "100px" }}>Loading...</h2>
      <LoadingSpinner/>
    );
  }
  return (
    <>
      <button
        className="open-zammad-chat d-none"
        ref={chat === "" ? myButtonRef : chat}
      >
        let's chat
      </button>
      {!keycloak.authenticated && <Welcome />}
      {!!keycloak.authenticated && <AppRouter />}
    </>
  );
}

export default App;

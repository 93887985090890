import {
  UPDATE_ADULT_KNEE_FEEDBACK,
  UPDATE_FOOT_FEEDBACK_FIELD_DATA,
} from "../actions/actionTypes";
const initialState = {
  painScore: [
    {
      painPastFourWeeksR: "0",
      painPastFourWeeksL: "0",
      painHurtTodayR: "0",
      painHurtTodayL: "0",
      twistingR: "0",
      twistingL: "0",
      straighteningR: "0",
      straighteningL: "0",
      fullyBendKneeR: "0",
      fullyBendKneeL: "0",
      walkingDownStairsR: "0",
      walkingDownStairsL: "0",
      walkingUpStairsR: "0",
      walkingUpStairsL: "0",
      sittingKneeBentR: "0",
      sittingKneeBentL: "0",
      sittingKneeBentUprightR: "0",
      sittingKneeBentUprightL: "0",
    },
  ],
  symtomsScore: [
    {
      kneeMakingNoiseR: "0",
      kneeMakingNoiseL: "0",
      kneeGettingStuckR: "0",
      kneeGettingStuckL: "0",
      stillMoveR: "0",
      stillMoveL: "0",
      withoutHurtLotR: "0",
      withoutHurtLotL: "0",
      withoutPuffyR: "0",
      withoutPuffyL: "0",
      puffyKneeR: "0",
      puffyKneeL: "0",
      swollenKneeR: "0",
      swollenKneeL: "0",
      cantHoldR: "0",
      cantHoldL: "0",
      mostOfTimeR: "0",
      mostOfTimeL: "0",
      kneecapR: "0",
      kneecapL: "0"
    },
  ],
  functionScore: [
    {
      goUpStairsR: "0",
      goUpStairsL: "0",
      goDownStairsR: "0",
      goDownStairsL: "0",
      kneelFrontR: "0",
      kneelFrontL: "0",
      straighteningR: "0",
      straighteningL: "0",
      bendingKneeR: "0",
      bendingKneeL: "0",
      TwistDurPlayInMorningtR: "0",
      TwistDurPlayInMorningtL: "0",
      moveDayAfterR: "0",
      moveDayAfterL: "0",
      squatBasketballR: "0",
      squatBasketballL: "0",
      sitOnChairWithKneeBentR: "0",
      sitOnChairWithKneeBentL: "0",
      getUpFromChairR: "0",
      getUpFromChairL: "0",
      bendingToFloorR: "0",
      bendingToFloorL: "0",
      runDurPlayR: "0",
      runDurPlayL: "0",
      jumpDurPlayR: "0",
      jumpDurPlayL: "0",
      startAndStopMoveR: "0",
      startAndStopMoveL: "0",
      twistDurPlayR: "0",
      twistDurPlayL: "0",
      keepBalWalkR: "0",
      keepBalWalkL: "0",
      playSportsR: "0",
      playSportsL: "0",
      getOutCarR: "0",
      getOutCarL: "0",
      getOutBedR: "0",
      getOutBedL: "0",
      changePositionLyingR: "0",
      changePositionLyingL: "0",
      gettingBathR: "0",
      gettingBathL: "0",
      carryHeavyBagsR: "0",
      carryHeavyBagsL: "0",
      doLightChoresR: "0",
      doLightChoresL: "0",
      gettingToSchoolR: "0",
      gettingToSchoolL: "0",
      doThingsFriendsR: "0",
      doThingsFriendsL: "0",
      howKneeWorkR: "10",
      howKneeWorkL: "10",
      howKneeWork_beforeR: "10",
      howKneeWork_beforeL: "10",
      patellarInstability: "0",
      patellarInstabilityLeft: "0"
    },
  ],
  qolScore: [{
    howOftenR: "0",
    howOftenL: "0",
    lifestyleR: "0",
    lifestyleL: "0",
    HowMuchInjuredR: "0",
    HowMuchInjuredL: "0",
    difficultyR: "0",
    difficultyL: "0",
  }],
  satisfactionScore: [{
    overallR: "4",
    overallL: "4",
    improvingPainR: "4",
    improvingPainL: "4",
    improvingHomeR: "4",
    improvingHomeL: "4",
    improvingActivitiesR: "4",
    improvingActivitiesL: "4",
    applicableR: false,
    applicableL: false,
    applicable1R: false,
    applicable1L: false,
    applicable2R: false,
    applicable2L: false,
    applicable3R: false,
    applicable3L: false,
    applicable:false
  }],
  norwichInstability: [
    {
      twistingR: "0",
      twistingL: "0",
      changeDirectionR: "0",
      changeDirectionL: "0",
      runningStraightlineR: "0",
      runningStraightlineL: "0",
      walkingSlipperyR: "0",
      walkingSlipperyL: "0",
      runningSidewaysR: "0",
      runningSidewaysL: "0",
      hoppingR: "0",
      hoppingL: "0",
      jumpingR: "0",
      jumpingL: "0",
      runningOnEvenSurfaceR: "0",
      runningOnEvenSurfaceL: "0",
      goingDownStairsR: "0",
      goingDownStairsL: "0",
      squattingR: "0",
      squattingL: "0",
      kneelingR: "0",
      kneelingL: "0",
      walkingUnevenSurfacesR: "0",
      walkingUnevenSurfacesL: "0",
      climbingStairsR: "0",
      climbingStairsL: "0",
      steppingOverHighR: "0",
      steppingOverHighL: "0",
      crossingWhenSittingR: "0",
      crossingWhenSittingL: "0",
      walkingEvenSurfaceR: "0",
      walkingEvenSurfaceL: "0",
      gettingIntoCarR: "0",
      gettingIntoCarL: "0",
      supermarketAisleR: "0",
      supermarketAisleL: "0",
      turningYourShoulderR: "0",
      turningYourShoulderL: "0",
      twcheck: false,
      directioncheck: false,
      straightcheck: false,
      slipperycheck: false,
      sidewayscheck: false,
      Hoppingcheck: false,
      Jumpingcheck: false,
      scaleLabelcheck: false,
      downcheck: false,
      Squattingcheck: false,
      Kneelingcheck: false,
      Surfacescheck: false,
      Climbingcheck: false,
      Steppingcheck: false,
      Crossingcheck: false,
      walkingEvencheck: false,
      IntoCarcheck: false,
      heavycheck: false,
      turningcheck: false,
    },
  ],
  TotalScores: [
    {
      IKDCTR: 0,
      IKDCTL: 0,
      painSubScaleTR: 0,
      painSubScaleTL: 0,
      symptomsSubscaleTR: 0,
      symptomsSubscaleTL: 0,
      koosAdlTR: 0,
      koosAdlTL: 0,
      koosPlayTR: 0,
      koosPlayTL: 0,
      koosQOLTR: 0,
      koosQOLTL: 0,
      vasR: 0,
      vasL: 0,
      saneR: 0,
      saneL: 0,
      sane_beforeR : 0,
      sane_beforeL : 0,
      norwichScoreR : 0,
      norwichScoreL : 0
    },
  ],
  doctorFeedback: [
    {
      gaitR: "0",
      gaitL: "0",
      anatomicR: null,
      anatomicL: null,
      anat: null,
      anatt: null,
      angleLr: null,
      angleLrr: null,
      langR: null,
      langL: null,
      fixeddR: null,
      fixeddL: null,
      LfixeddR: null,
      LfixedlL: null,
      lagsR: null,
      lagR: null,
      lagLR: null,
      lagLL: null,
      anteriorR: "",
      anteriorL: "",
      anterdropR: "",
      anterdropL: "",
      lachmanR: "",
      lachmanL: "",
      lachmandropR: "",
      lachmandropL: "",
      pivotR: "",
      pivotL: "",
      pivotdropR: "",
      pivotdropL: "",
      sagR: "",
      sagL: "",
      drawerR: "",
      drawerL: "",
      drawdropR: "",
      drawdropL: "",
      activeR: "",
      activeL: "",
      reverseR: "",
      reverseL: "",
      recurvatumR: "",
      recurvatumL: "",
      recurvatumsecondR:"",
      recurvatumsecondL:"",
      postR: "",
      postL: "",
      rotatR: "",
      rotatL: "",
      drawersR: "",
      drawersL: "",
      stressR: "",
      stressL: "",
      stressdropR: "",
      stressdropL: "",
      stressflR: "",
      stressflL: "",
      stressdropflR: "",
      stressdropflL: "",
      valgusR: "",
      valgusL: "",
      valgusdropR: "",
      valgusdropL: "",
      valgusflR: "",
      valgusflL: "",
      valgusdropflR: "",
      valgusdropflL: "",
      murraryR: "",
      murraryL: "",
      egeR: "",
      egeL: "",
      jointR: "",
      tendernessR: "",
      tendernessL: "",
      jointL: "",
      apleyR: "",
      apleyL: "",
      apprR: "",
      apprL: "",
      patellarR: "",
      patellarL: "",
      patellaR: "",
      patellaL: "",
      signR: "",
      signL: "",
      legR: "",
      legL: "",
      strengthR: null,
      strengthL: null,
      KTR: "",
      KTL: "",
      dialR: "",
      dialL: "",
      text1: "",
      text2: "",
      text3: "",
      textL1: "",
      textL2: "",
      textL3: "",
      text30R: "",
      text90R: "",
      text30L: "",
      text90L: "",
      gaitLText: "",
      gaitRText: "",
      radiovalueR: "",
      radiovalueL: "",
      radioAngleR: "",
      radioAngleL: "",
      DegreeFixedR: "",
      DegreeFixedL: "",
      ExtensionR: "",
      ExtensionL: "",
      lFlexionR: null,
      lFlexionL: null,
      rFlexionR: null,
      rFlexionL: null,
      lExtensionL: null,
      lExtensionR: null,
      rExtensionL: null,
      rExtensionR: null,
      murraryRdrop: "1",
      murraryLdrop: "1",
      egeRdrop: "1",
      egeLdrop: "1",
      apleyRdrop: "1",
      apleyLdrop: "1",
      apprRdrop: "1",
      apprLdrop: "1",
      ThessalyRdrop: "1",
      ThessalyLdrop: "1",
      ThessalyR: "",
      ThessalyL: "",
      GaitRadioR: "",
      GaitRadioL: "",
      DeformityRText: "",
      DeformityLText: "",
    },
  ],


  evlPediaPain: "1",
};

const PediaKneeReducer = (state = initialState, action) => {
  switch (action.type) {
    case "UPDATE_PEDIA_DATA":
      return { ...state, evlPediaPain: action.data };
    case "UPDATE_PEDIA_PAIN_FIELD_DATA":
      const painFieldVal = action.data.value;
      const painFieldKey = action.data.key;
      const painNewArr = state.painScore.map((object) => {
        return { ...object, [painFieldKey]: painFieldVal };
      });
      return { ...state, painScore: painNewArr };

    case "UPDATE_PEDIA_PAIN_DATA":
      return { ...state, painScore: action.data };

    case "UPDATE_PEDIA_SYMTOMS_FIELD_DATA":
      const symptomsFieldVal = action.data.value;
      const symptomsFieldKey = action.data.key;
      const symptomsNewArr = state.symtomsScore.map((object) => {
        return { ...object, [symptomsFieldKey]: symptomsFieldVal };
      });
      return { ...state, symtomsScore: symptomsNewArr };

    case "UPDATE_PEDIA_SYMTOMS_DATA":
      return { ...state, symtomsScore: action.data };

    case "UPDATE_PEDIA_FUNCTION_FIELD_DATA":
      const functionFieldVal = action.data.value;
      const functionFieldKey = action.data.key;
      const functionNewArr = state.functionScore.map((object) => {
        return { ...object, [functionFieldKey]: functionFieldVal };
      });
      return { ...state, functionScore: functionNewArr };

    case "UPDATE_PEDIA_FUNCTION_DATA":
      return { ...state, functionScore: action.data };

    case "UPDATE_PEDIA_QOL_FIELD_DATA":
      const qolFieldVal = action.data.value;
      const qolFieldKey = action.data.key;
      const qolNewArr = state.qolScore.map((object) => {
        return { ...object, [qolFieldKey]: qolFieldVal };
      });
      return { ...state, qolScore: qolNewArr };

    case "UPDATE_PEDIA_QOL_DATA":
      return { ...state, qolScore: action.data };

    case "UPDATE_PEDIA_SATISFACTION_FIELD_DATA":
      const satisfactionFieldVal = action.data.value;
      const satisfactionFieldKey = action.data.key;
      const satisfactionNewArr = state.satisfactionScore.map((object) => {
        return { ...object, [satisfactionFieldKey]: satisfactionFieldVal };
      });
      return { ...state, satisfactionScore: satisfactionNewArr };

    case "UPDATE_PEDIA_SATISFACTION_DATA":
      return { ...state, satisfactionScore: action.data };

    case "UPDATE_PEDIA_KNEE_NORWICHINSTA_FIELD_DATA":
      const satValInst = action.data.value;
      const satKeyInst = action.data.key;
      const satNewArrInst = state.norwichInstability.map((object) => {
        return { ...object, [satKeyInst]: satValInst };
      });
      return { ...state, norwichInstability: satNewArrInst };

    case "UPDATE_PEDIA_KNEE_NORWICHINSTA_DATA":
      return { ...state, norwichInstability: action.data };


    case "UPDATE_PEDIA_TOTAL_DATA":
      return { ...state, TotalScores: action.data };

    case UPDATE_FOOT_FEEDBACK_FIELD_DATA:
      const satf = action.data.value;
      const satK = action.data.key;
      const satNewarr = state.doctorFeedback.map((object) => {
        return { ...object, [satK]: satf };
      });
      return { ...state, doctorFeedback: satNewarr };
    case UPDATE_ADULT_KNEE_FEEDBACK:
      return { ...state, doctorFeedback: action.data };
    default:
      return state;
  }
};

export default PediaKneeReducer;

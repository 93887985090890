export function convertToDays(value, varient) {
  if (varient === "Days") {
    return value;
  } else if (varient === "Weeks") {
    return value * 7;
  } else if (varient === "Months") {
    return value * 30;
  } else if (varient === "Years") {
    return value * 365;
  } else {
    return 0;
  }
}

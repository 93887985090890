/* eslint-disable import/no-anonymous-default-export */
export default {
  home: "F-Home",
  patients: "F-Patient List",
  First_Name: "First Name",
  Last_Name: "Last Name",
  Sex:"sex",
  patient_profile: "f-Patient Profile",
  addPatient: "Add a Patient",
  Radiology: "F-Radiology",
  OperativeNotes: "F-Operative Notes",
  REFLECTComplex: "F-REFLECT Complex",
  settings: "F-Settings",
  DataExport: "F-Data Export",
  AppVersion: "F-App Version",
  notifications: "F-Notifications",
  language: "F-Language & Region",
  account: "F-Account",
  myClinics: "F-My Clinics",
  select: "F-Select the language you’d like to use",
  timezone: "F-Timezone",
  country: "F-Country",
  dateFormet: "F-Date format",
  Timeformat: "F-Time format",
  PatientData: "F-Patient Data",
  checkthis: "F-Check this to hide patients identities",
  scores: "F-Scores",
  Welcome: "F-Welcome to akunah,",
  let: "F-Let’s have a great day",
  searchPatients: "F-Search Patients",
  searchNextPatients: "F-Search for your next patient",
  startcase: "F-Start new case",
  startsegmenetation: "F-Start your new segmenetation case now",
  contactus: "F-Contact us",
  contactfeedback: "F-Contact us if you have any feedback or questions",
  contact: "F-Contact",
  tasks: "F-Tasks",
  addtask: "F-Add task",
  char: "F-characters remaining",
  recentPatients: "F-Recent Patients",
  profile: "F-Profile",
  Edit: "F-Edit Profile",
  name: "F-Name",
  akunah: "F-Akunah ID",
  phone: "F-Phone",
  email: "F-Email",
  localTime: "F-Local time",
  logOut: "F-Log out",
  chat: "F-Chat with Akunah",
  uploadimg: "F-Upload an Image",
  removephoto: "F-Remove photo",
  advancedSearch: "F-Advanced Search",
  dob: "F-Date of Birth",
  listofPatient: "F-List of Patients",
  operativeNotes: "F-Operative Notes",
  listofOPnotes: "F-List of Operative Notes",
  newNote: "F-New Note",
  forms: "F-PROMs",
  addNotes: "F-Add Notes",
  viewForm_precp: "F-Generate a Prescription",
  viewForm_insurance: "F-Generate work cover",
  viewForm_akunah: "F-Connect with Us",

  viewForm: "F-View More Forms",
  viewForm_TEMP: "F-Generate a Template",
  viewForm_radio: "F-Request a Radiology",
  viewForm_patho: "F-Request a Pathology",
  view_doctor: "F-View Doctor Scoring List",
  viewMore: "F-View More",
  viewConnect: "F-Connect",
  viewGenerate: "F-Generate",
  viewRequest: "F-Request",

  managementPlan: "F-Management Plan",
  viewManagement: "F-View More Management plan",
  notes: "F-Notes",
  viewNotes: "F-View More Notes",
  viewOPnotes: "F-View more operative notes",
  document: "F-Documents",
  viewDocument: "F-View More Documents",
  dob1: "F-DOB",
  sex: "F-Sex",
  address: "F-Address",
  mobile: "F-Mobile",
  lastVisit: "F-Last Visit",
  medicareCardNumber: "F-Medicare Card Number",
  dVAConcessionCard: "F-DVA Concession Card",
  privateHealthFund: "F-Private Health Fund",
  // lastVisit: "F-Last Visit",
  AKPTID: "F-AKPT ID",
  accountStatus: "F-Account Status",
  nextofKin: "F-Next of Kin",
  relationship: "F-Relationship",
  GPDetails: "F-GP Details",
  GPName: "F-GP Name",
  practice: "F-Practice",
  medicare: "F-Medicare",
  ref: "F-Ref",
  expiry: "F-Expiry",
  private: "F-Private",
  healthFund: "F-Health Fund",
  membership: "F-Membership Number",
  other: "F-Other",
  claimType: "F-Claim Type",
  workCover: "F-Work Cover",
  employer: "F-Employer",
  thirdPartyClaimNumber: "F-Third Party Claim Number",
  thirdPartyName: "F-Third Party Name",
  DVA: "F-DVA",
  DVANumber: "F-DVA Number",
  DefencePersonnel: "F-Defence Personnel",
  viewall: "F-View All",
  managementPlans: "F-Management Plans",
  rangeofMotion: "F-Range of Motion",
  initial: "F-Initial",
  recent: "F-Recent",
  activeForwardFlexion: "F-Active Forward Flexion",
  none: "F-None",
  lateralElevation: "F-Lateral Elevation",
  eR1Scores: "F-ER1 Scores",
  eR2Scores: "F-ER2 Scores",
  iR1Scores: "F-IR1 Scores",
  iR2Scores: "F-IR2 Scores",
  VAS: "F-VAS",
  WOSI: "F-WOSI",
  pleaseSelectOrganization: "F-Please Select Organization",
  fromDate: "F-From Date",
  toDate: "F-To Date",
  listofNotes: "F-List of Notes",
  result: "F-Result",
  addNote: "F-Add Note",
  editNote: "F-Edit Note",
  operativeNotesList: "F-Operative Notes List",
  newnote: "F-New Note",
  listofDocuments: "F-List of Documents",
  documents: "F-Documents",
  addFile: "F-Add File",
  listofManagementPlans: "F-List of Management Plans",
  calender: "F-My Schedular",
  patientorg: "F-Patient Organization",
  // listofPatient: "F-List Of Patients",
  viewOrg: "F-View more Organization",
  patientAssesment: "F-Clinical Exam",
  doctorScoreList: "F-Doctor Scoring List",
  template: "F-Template",
  No_data_found: "F- No Record Found",
  Pathology: "F-Pathology",
  akmed: "F-Akunah Med",
  prescription: "F-Prescription",
  pending_form: "F-Overdue Forms",
  akunah_connect: "F-Akunah Connect",
  insurance: "F-Insurance",
  document_templates: "F-Document Templates",

  ///
  MyDocument: "F-My Document",
  List_Documents:"F_List of Documents"
};

import React, { useEffect, useState } from "react";
import settingsStyle from "../Settings.module.css";
import useTranslation from "../../customHooks/translations";
// import Button from "../../custom/button/Button";
import arrow from "../../../images/right_side.svg";
import arrow_white from "../../../images/right_side_white.svg";
import plusIcon from "../../../images/add-temp-icon.svg";
import thredot from "../../../images/threedot.svg";
import edit from "../../../images/edit-icon.svg";
import delte from "../../../images/delete-icon.svg";
import Dropdown from "react-bootstrap/Dropdown";
import headerImg from "../../../images/headerakunah1.png";
import footerIMG from "../../../images/footerakunah.png";
import sortDropStyles from "./SortDropdown.module.css";
import useAxios from "../../../axiosinstance";
import { useSelector } from "react-redux";
import { UserDateFormat } from "../../../utilities/DateFormate";
import { useNavigate } from "react-router-dom";
import { WordCutter } from "../../../utilities/DocumentType";
import DeleteConfirmation from "../../custom/delete-confirmation/DeleteConfirmation";
import { s3UrlLink } from "../../../utilities/S3Url";
export default function Temp() {
  const translation = useTranslation();
  const navigate = useNavigate();
  const login = useSelector((state) => state.mainReducer.loginData.arr);
  const axiosInstance = useAxios();
  const [data, setData] = useState([]);
  const [deleteId, setDeleteId] = useState("");
  const [dataRef, setDataRef] = useState(false);
  const getDatas = async () => {
    const response = await axiosInstance.current.get(
      `extapp/forms/get_all_templates_of_specific_doctor?ak_id=${login.ing_ak_id}&limit=8&offset=0`
    );
    if (response.status === 200) {
      setData(response.data.data);
    }
  };
  useEffect(() => {

    getDatas();
  }, [dataRef]);
  const handleDocoment = () => {
    // handleClose();
    navigate(`/gallery`);
  };
  const handleDocomentCreate = () => {
    // handleClose();
    navigate(`/template/${login.ing_ak_id}`);
  };
  // const handleClick = (e, t, n) => {
  //   // alert(e);
   
  //   navigate(`/templete-create/${t}/${e}?n=${n}`);
  //   // setEditId(e);
  // };

  const handleClick = (e, t, n,nt) => {
    // handleClose();
    if (nt === undefined){
      navigate(`/templete-create/${t}/${e}?n=${n}`);
    }else{
      navigate(`/templete-creates/${t}/${e}?n=${n}`);
     
    }
    // setEditId(e);
  };

  const [displayConfirmationModalDelete, setDisplayConfirmationModalDelete] =
    useState(false);
  const hideConfirmationModal = () => {
    setDisplayConfirmationModalDelete(false);
  };
  const handleDelete = (t, i) => {
    axiosInstance.current
      .delete(`extapp/forms/delete_template?_id=${i}`)
      .then((res) => {
        setDataRef(!dataRef);
        hideConfirmationModal();
      });
  };
  const handleDeleteId = (e) => {
    setDeleteId(e);
    setDisplayConfirmationModalDelete(true);
    //  console.log(e, "deleteId")
  };
  //   console.log(data)
  return (
    <div className="setting_temp scrollbar_vert">
      <div className="row">
        <div className="d-flex justify-content-between">
          <h4 class={`${settingsStyle.content_main_title}`}>
            {" "}
            {translation.document_templates}
          </h4>
          <div className="text-end">
            <div className="position-relative" onClick={() => handleDocoment()}>
              <button
                className={`btn_outline new__note_btn img_white `}
                style={{ marginRight: "35px" }}
              >
                Template Gallery
                <img src={arrow} alt="img" className="ms-2" />{" "}
                <img src={arrow_white} alt="img" className="ms-2 img_hover2" />{" "}
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-3">
        <div className="col-xxl-4 col-lg-6 col-md-12 col-sm-12 mt-2">
          <div className="temp_box" onClick={() => handleDocomentCreate()}>
            <img src={plusIcon} alt="" />
            <div className="temp_bottom_txt">
              <div className="card-footer temp_white">
                <p className="f_600" style={{ fontSize: "12px" }}>
                  {" "}
                  Create new template
                </p>
                {/* <p className="f_400" style={{ fontSize: "10px" }}>
                  {" "}
                  New blank template
                </p> */}
              </div>
            </div>
          </div>
        </div>

        {data.map((row) => (
          <div className="col-xxl-4 col-lg-6 col-md-12 col-sm-12 mt-2">
            <div
              className="temp_box"
            //   onClick={() => handleClick(row._id, 0)}
            >
              <div className="temp_btn_top">
                <span>{row.template_name||"Treatment"}</span>
              </div>
              <div className="temp_btn_top2 ">
                {/* <img src={thredot} alt='' /> */}
                <Dropdown
                  className={`custom_dropdown ${sortDropStyles.drop_down}`}
                >
                  <Dropdown.Toggle
                    className={sortDropStyles.dropdown_button}
                    variant="default"
                    id="dropdown-basic"
                  >
                    <img className={`arrow_img`} src={thredot} alt="arrow" />
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="mt-1 temp_drop">
                    <Dropdown.Item
                      onClick={() =>
                        handleClick(row._id, row.type_id, row.title, row.image)
                      }
                    >
                      <img src={edit} alt="" className="me-2" />
                      Edit
                    </Dropdown.Item>
                    <Dropdown.Item onClick={() => handleDeleteId(row._id)}>
                      <img src={delte} alt="" className="me-2" />
                      Delete
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </div>
              <div className="temp_middle_txt">
                <div className="page-small">
                  <img
                    className="header-images"
                    src={row.header ? s3UrlLink(row.header)  : headerImg}
                    alt="header"
                  />
                  <img
                    className="footer-images"
                    src={row.footer ? s3UrlLink(row.footer) : footerIMG}
                    alt="footer"
                  />

                  <div className="contents">
                    <span className="content_text">Content</span>
                  </div>
                </div>
              </div>
              <div className="temp_bottom_txt">
                <div className="card-footer temp_white">
                  <p
                    className="f_600 text-capitalize"
                    style={{ fontSize: "12px" }}
                    data-bs-toggle="tooltip"
                    title={row.title}
                  >
                    {WordCutter(row.title, 2)}
                  </p>
                  <p className="f_400" style={{ fontSize: "10px" }}>
                    {" "}
                    {UserDateFormat(row.created_at, login.ing_date_formate,login.ing_timezone)}
                  </p>
                </div>
              </div>
            </div>
          </div>
        ))}
        {/* <div className='col-xxl-4 col-lg-6 col-md-12 col-sm-12 mt-2'>
                    <div className='temp_box'>
                        <div className='temp_btn_top'>
                            <span>Letter</span>
                        </div>
                        <div className='temp_btn_top2'>
                         
                            <Dropdown className={`custom_dropdown ${sortDropStyles.drop_down}`}>
                                <Dropdown.Toggle
                                    className={sortDropStyles.dropdown_button}
                                    variant="default"
                                    id="dropdown-basic"
                                >
                                    <img className={`arrow_img`} src={thredot} alt="arrow" />

                                </Dropdown.Toggle>

                                <Dropdown.Menu className="mt-1 temp_drop" >
                                    <Dropdown.Item href=""  >
                                        <img src={edit} alt='' className='me-2' />Rename</Dropdown.Item>
                                    <Dropdown.Item href=""  >
                                        <img src={delte} alt='' className='me-2' />
                                        Delete</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                        </div>
                        <div className='temp_bottom_txt'>
                            <div className='card-footer temp_white'>
                                <p className='f_600' style={{ fontSize: "12px" }}> New blank template</p>
                                <p className='f_400' style={{ fontSize: "10px" }}> 12 May 2023</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className='col-xxl-4 col-lg-6 col-md-12 col-sm-12 mt-2'>
                    <div className='temp_box'>
                        <div className='temp_btn_top'>
                            <span>Letter</span>
                        </div>
                        <div className='temp_btn_top2'>
                      
                            <Dropdown className={`custom_dropdown ${sortDropStyles.drop_down}`}>
                                <Dropdown.Toggle
                                    className={sortDropStyles.dropdown_button}
                                    variant="default"
                                    id="dropdown-basic"
                                >
                                    <img className={`arrow_img`} src={thredot} alt="arrow" />

                                </Dropdown.Toggle>

                                <Dropdown.Menu className="mt-1 temp_drop" >
                                    <Dropdown.Item href=""  >
                                        <img src={edit} alt='' className='me-2' />Rename</Dropdown.Item>
                                    <Dropdown.Item href=""  >
                                        <img src={delte} className='me-2' alt='' />
                                        Delete</Dropdown.Item>
                                </Dropdown.Menu>
                            </Dropdown>

                        </div>
                        <div className='temp_bottom_txt'>
                            <div className='card-footer temp_white'>
                                <p className='f_600' style={{ fontSize: "12px" }}> New blank template</p>
                                <p className='f_400' style={{ fontSize: "10px" }}> 12 May 2023</p>
                            </div>
                        </div>
                    </div>
                </div> */}
      </div>

      <DeleteConfirmation
        showModal={displayConfirmationModalDelete}
        confirmModal={handleDelete}
        hideModal={hideConfirmationModal}
        message={""}
        type="task_id"
        id={deleteId}
      />
    </div>
  );
}

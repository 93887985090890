export const s3UrlLink = (url) => {

    return process.env.REACT_APP_S3_URL + "akunah_files_2?path=" + url
};
export const s3UrlLinkFile = (url, name) => {

    // eslint-disable-next-line no-useless-concat
    return process.env.REACT_APP_S3_URL + "akunah_files_4?key=" + `${url}&file_name=${name}`
};

export const s3UrlLinkExport = (url) => {

  return process.env.REACT_APP_S3_URL + "akunah_files_5?path=" + url
};